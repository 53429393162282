.scripts_admin_training_trainingresources_matrix {
	//--- tijdelijk even alleen voor de Reserveringsmatrix op /scripts/admin/training/trainingresources/matrix.aspx
	.MatrixWrapper {
		.fc-event {
			background-color: #3a87ad;
			border-color: #3a87ad;
			color: #fff;

			&.event_ {
				&location {
					//--- Locaties
					background-color: #707699;
					border-color: #707699;
				}
				&trainer {
					//--- Docenten
					background-color: #ff6f7e;
					border-color: #ff6f7e;
				}
				&material {
					//--- Materialen
					background-color: #b2ae33;
					border-color: #b2ae33;
				}
			}
		}
		span.fc-icon.fc-icon-down-triangle:after,
		span.fc-icon.fc-icon-up-triangle:after,
		span.fc-icon.fc-icon-right-triangle:after {
			display: block;
		}
	}
}

.matrix_header_left {
	.switched:not(.active) {
		background-color: var(--dark-gray);
		border-radius: 10rem;
		color: #fff;
		padding: 0.1em 0.6em;
		transition: background-color .15s ease-in-out;

		&:hover {
			background-color: #000;
		}

		&.active, &.active:hover {
			background-color: transparent;
		}
		

	}
}

// --- .fc = fullcalender, applies to /js/plugins/jQuery/fullcalendar/lib/fullcalendar.min.css
.fc-event-container {
	color: white;
}
.fc-icon {
	&:after {
		display: inline-block;
	}
}
th.fc-day-today,
.fc .fc-daygrid-day.fc-day-today {
	background-color: rgba(0, 155, 245, 0.08);
}

th.fc-day-today {
	box-shadow: 1px 0 0 0 #163e59 inset,
		-1px 0 0 0 #163e59 inset,
		0 1px 0 0 #163e59 inset;
}
.fc-SchedulerDay-view th.fc-day-today {
	box-shadow: none;
}
td.fc-day-today {
	box-shadow: 0 -1px 0 0 #163e59 inset,
		-1px 0 0 0 #163e59 inset,
		1px 0 0 0 #163e59 inset;
}
.fc-MonthAgenda-view td.fc-daygrid-day.fc-day-today {
	box-shadow: 0 1px 0 0 #163e59 inset,
		0 -1px 0 0 #163e59 inset,
		-1px 0 0 0 #163e59 inset,
		1px 0 0 0 #163e59 inset;
}
.fc-slot-today,
td.fc-day-today {
	background-color: rgba(0, 155, 245, 0.08);
}
.fc-slot-past+.fc-slot-today{
	box-shadow: 1px 0 0 0 #163e59 inset;
}
.fc-resourceTimelineWeek-view .fc-slot-today+.fc-slot-today:nth-of-type(9n) {
    box-shadow: -1px 0 0 0 #163e59 inset;
}
.fc-daygrid-dot-event.canceled .fc-daygrid-event-dot {
	border-color: var(--red-normal);
}