@use 'sass:math';

@mixin margin($margin) {
	margin-left: $margin;
}

@mixin circle($size) {
	float: left;
	width: $size;
	height: $size;
	margin: 1rem;
	padding: math.div(1rem, 2);
	color: white;
	border-radius: 50%;
	font-family: 'plusport';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
}

@mixin circle-border($size) {
	text-align: center;
	border-radius: 50%;
	width: $size;
	height: $size;
	display: block;
}

@mixin circle-icon($size) {
	width: $size;
	height: $size;
	background: var(--catalogue-filter-icon-bg-color);
	display: inline-block;
	border-radius: 50%;
	color: var(--catalogue-filter-icon-text-color);
	text-align: center;
	font-size: 1.25rem;
	line-height: $size;
	transition: background 0.2s ease-in-out;
	z-index: 2;
	box-sizing: content-box;
	box-shadow: var(--shadow);
}

@mixin catalog-filter-icon($size) {
	width: $size;
	height: $size;
	background-color: var(--catalogue-filter-icon-bg-color);
	display: inline-block;
	border-radius: 50%;
	color: var(--catalogue-filter-icon-text-color);
	text-align: center;
	font-size: 1.25rem;
	line-height: $size;
	transition: all 0.2s ease-in-out;
	z-index: 2;
	box-sizing: content-box;
	box-shadow: var(--shadow);
	margin-right: 6px;

	&:hover {
		background-color: var(--catalogue-filter-icon-active-bg-color);
		color: var(--catalogue-filter-icon-active-text-color);
	}
}

// Extend classes
.tile_pseudo {
	content: '';
	width: 1rem;
	height: 1rem;
	border-radius: 50%;
	display: inline-block;
}

.logo_pseudo {
	font-family: 'plusport';
	font-size: 1rem * 2;
	margin: 18px 0px;
	display: block;
	text-align: center;
}

legend {
	border-bottom: 0;
	padding-bottom: 0;
	margin-bottom: 0;
	margin-top: 1rem;
}

.chart {
	width: 80px;
	height: 80px;
}

.tile_chartcircle {
	@include circle-border(80px);
}

//--- _grid.scss

/// Grid system
//
// Generate semantic grid columns with these mixins.

@mixin make-container($gutter: $grid-gutter-width) {
	width: 100%;
	padding-right: math.div($gutter, 2);
	padding-left: math.div($gutter, 2);
	margin-right: auto;
	margin-left: auto;
}

// For each breakpoint, define the maximum width of the container in a media query
@mixin make-container-max-widths(
	$max-widths: $container-max-widths,
	$breakpoints: $grid-breakpoints
) {
	@each $breakpoint, $container-max-width in $max-widths {
		@include media-breakpoint-up($breakpoint, $breakpoints) {
			max-width: $container-max-width;
		}
	}
}

@mixin make-row($gutter: $grid-gutter-width) {
	display: flex;
	flex-wrap: wrap;
	margin-right: -#{math.div($gutter, 2)};
	margin-left: -#{math.div($gutter, 2)};
}

@mixin make-col-ready($gutter: $grid-gutter-width) {
	position: relative;
	// Prevent columns from becoming too narrow when at smaller grid tiers by
	// always setting `width: 100%;`. This works because we use `flex` values
	// later on to override this initial width.
	width: 100%;
	padding-right: math.div($gutter, 2);
	padding-left: math.div($gutter, 2);
}

@mixin make-col($size, $columns: $grid-columns) {
	flex: 0 0 percentage(math.div($size, $columns));
	// Add a `max-width` to ensure content within each column does not blow out
	// the width of the column. Applies to IE10+ and Firefox. Chrome and Safari
	// do not appear to require this.
	max-width: percentage(math.div($size, $columns));
}

@mixin make-col-offset($size, $columns: $grid-columns) {
	$num: math.div($size, $columns);
	margin-left: if($num==0, 0, percentage($num));
}

// Media of at least the minimum breakpoint width. No query for the smallest breakpoint.
// Makes the @content apply to the given breakpoint and wider.
@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
	$min: breakpoint-min($name, $breakpoints);
	@if $min {
		@media (min-width: $min) {
			@content;
		}
	} @else {
		@content;
	}
}

// Media of at most the maximum breakpoint width. No query for the largest breakpoint.
// Makes the @content apply to the given breakpoint and narrower.
@mixin media-breakpoint-down($name, $breakpoints: $grid-breakpoints) {
	$max: breakpoint-max($name, $breakpoints);

	@if $max {
		@media (max-width: $max) {
			@content;
		}
	} @else {
		@content;
	}
}

// Media that spans multiple breakpoint widths.
// Makes the @content apply between the min and max breakpoints
@mixin media-breakpoint-between(
	$lower,
	$upper,
	$breakpoints: $grid-breakpoints
) {
	$min: breakpoint-min($lower, $breakpoints);
	$max: breakpoint-max($upper, $breakpoints);

	@if $min !=null and $max !=null {
		@media (min-width: $min) and (max-width: $max) {
			@content;
		}
	} @else if $max==null {
		@include media-breakpoint-up($lower, $breakpoints) {
			@content;
		}
	} @else if $min==null {
		@include media-breakpoint-down($upper, $breakpoints) {
			@content;
		}
	}
}

// Media between the breakpoint's minimum and maximum widths.
// No minimum for the smallest breakpoint, and no maximum for the largest one.
// Makes the @content apply only to the given breakpoint, not viewports any wider or narrower.
@mixin media-breakpoint-only($name, $breakpoints: $grid-breakpoints) {
	$min: breakpoint-min($name, $breakpoints);
	$max: breakpoint-max($name, $breakpoints);

	@if $min !=null and $max !=null {
		@media (min-width: $min) and (max-width: $max) {
			@content;
		}
	} @else if $max==null {
		@include media-breakpoint-up($name, $breakpoints) {
			@content;
		}
	} @else if $min==null {
		@include media-breakpoint-down($name, $breakpoints) {
			@content;
		}
	}
}

//--- /_breakpoints.scss

//
// Base styles
//

// Single side border-radius

@mixin button-size(
	$padding-y,
	$padding-x,
	$font-size,
	$line-height,
	$border-radius
) {
	padding: $padding-y $padding-x;
	font-size: $font-size;
	line-height: $line-height;

	// Manually declare to provide an override to the browser default
	@if $enable-rounded {
		border-radius: $border-radius;
	} @else {
		border-radius: 0;
	}
}

// Pagination

@mixin pagination-size(
	$padding-y,
	$padding-x,
	$font-size,
	$line-height,
	$border-radius
) {
	.page-link {
		padding: $padding-y $padding-x;
		font-size: $font-size;
		line-height: $line-height;
	}

	.page-item {
		&:first-child {
			.page-link {
				@include border-left-radius($border-radius);
			}
		}

		&:last-child {
			.page-link {
				@include border-right-radius($border-radius);
			}
		}
	}
}

// _list-group.scss
// List Groups

@mixin list-group-item-variant($state, $background, $color) {
	.list-group-item-#{$state} {
		color: $color;
		background-color: $background;

		&.list-group-item-action {
			@include hover-focus {
				color: $color;
				background-color: darken($background, 5%);
			}

			&.active {
				color: #fff;
				background-color: $color;
				border-color: $color;
			}
		}
	}
}

// _hover.scss
// Hover mixin and `$enable-hover-media-query` are deprecated.
//
// Originally added during our alphas and maintained during betas, this mixin was
// designed to prevent `:hover` stickiness on iOS-an issue where hover styles
// would persist after initial touch.
//
// For backward compatibility, we've kept these mixins and updated them to
// always return their regular pseudo-classes instead of a shimmed media query.
//
// Issue: https://github.com/twbs/bootstrap/issues/25195

@mixin hover {
	&:hover {
		@content;
	}
}

@mixin hover-focus {
	&:hover,
	&:focus {
		@content;
	}
}

@mixin plain-hover-focus {
	&,
	&:hover,
	&:focus {
		@content;
	}
}

@mixin hover-focus-active {
	&:hover,
	&:focus,
	&:active {
		@content;
	}
}

// _border-radius.scss
// stylelint-disable property-blacklist
// Single side border-radius

@mixin border-radius($radius: $border-radius, $fallback-border-radius: false) {
	@if $enable-rounded {
		border-radius: $radius;
	} @else if $fallback-border-radius !=false {
		border-radius: $fallback-border-radius;
	}
}

@mixin border-top-radius($radius) {
	@if $enable-rounded {
		border-top-left-radius: $radius;
		border-top-right-radius: $radius;
	}
}

@mixin border-right-radius($radius) {
	@if $enable-rounded {
		border-top-right-radius: $radius;
		border-bottom-right-radius: $radius;
	}
}

@mixin border-bottom-radius($radius) {
	@if $enable-rounded {
		border-bottom-right-radius: $radius;
		border-bottom-left-radius: $radius;
	}
}

@mixin border-left-radius($radius) {
	@if $enable-rounded {
		border-top-left-radius: $radius;
		border-bottom-left-radius: $radius;
	}
}

@mixin border-top-left-radius($radius) {
	@if $enable-rounded {
		border-top-left-radius: $radius;
	}
}

@mixin border-top-right-radius($radius) {
	@if $enable-rounded {
		border-top-right-radius: $radius;
	}
}

@mixin border-bottom-right-radius($radius) {
	@if $enable-rounded {
		border-bottom-right-radius: $radius;
	}
}

@mixin border-bottom-left-radius($radius) {
	@if $enable-rounded {
		border-bottom-left-radius: $radius;
	}
}

//--- extends
// .row>.BlockDetails.tile,
.scripts_admin_user_search #frmSearch > .row > div {
	@include media-breakpoint-up(xl) {
		@include make-col(3);
	}
}

.MenuVertical {
	.pageTitle {
		@include media-breakpoint-up(xl) {
			min-height: 7rem;
		}
	}
}

//--- extends

// custom mixins
@mixin rgba-background($hexcolor, $opacity) {
	background-color: $hexcolor;
	background-color: rgba($hexcolor, $opacity) !important;
}

@mixin input-hover-effect($force-border-color: false, $force-shadow: false) {
	@if $force-border-color {
		border-color: #000 !important;
	} @else {
		border-color: #000;
	}

	@if $force-shadow {
		box-shadow: shadow(20) rgba(0,0,0, 0.2) !important;
	} @else {
		box-shadow: shadow(20) rgba(0,0,0, 0.2);
	}
}

@mixin input-invalid-effect($force: false) {
	@if $force {
		border-color: var(--input-has-error-border-color) !important;
	} @else {
		border-color: var(--input-has-error-border-color);
	}
}

@mixin input-invalid-focus-effect($force: false) {
	@if $force {
		box-shadow: inset 0 0 0 1px var(--input-has-error-border-color) !important;
	} @else {
		box-shadow: inset 0 0 0 1px var(--input-has-error-border-color);
	}
}

@mixin ie11 {
	@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
		@content;
	}
}

@mixin arrow-down {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
	background-repeat: no-repeat;
}

@mixin arrow-down-white {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
	background-repeat: no-repeat;
}

@mixin focus-outline($offset: 4px) {
	outline: var(--focus-outline-width) solid var(--focus-outline-color) !important;
	outline-offset: $offset !important;
}

@mixin focus-border {
	border: 1px solid #000 !important;
	box-shadow: var(--shadow) !important;
}