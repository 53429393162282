$verticalMenuWidth: 110px;

/***HEADER MENU GENERIC ***/

#Header {
	background: var(--mega-menu-background-color);
	overflow: visible !important;
}

.MenuHorizontal {
	#page-wrapper {
		padding-top: 60px;
	}
}

#Header {
	position: fixed;
	width: 100%;
	z-index: 1000;
	box-shadow: var(--shadow);
}

#payoff {
	position: fixed;
	top: 28px;
	right: 270px;
	color: #fff;
	z-index: 1001;
	font-weight: bold;
	font-size: 1.6rem;
	text-transform: uppercase;
}

@media screen and (max-width: 991px) {
	#Header {
		width: 100%;
		height: 60px;
		margin-bottom: 5px;
		top: 0;
	}
}

@include media-breakpoint-up(lg) {
	.MenuHorizontal {
		#page-wrapper {
			padding-left: 0;
			padding-right: 0;
			padding-top: 80px;
			min-height: 100vh;
			// background niet aanpassen vanwege themes (zoals aviko)
			// background: #fafafa;
			#Content {
				background-color: inherit;
			}
		}

		#Header {
			height: 80px;
			margin-top: -80px;
			z-index: 1000;
		}
	}
}

@include media-breakpoint-up(md) {
	.MenuVertical {
		#page-wrapper {
			padding-top: 1px;
			margin-top: 0px;
		}
		#Header {
			width: $verticalMenuWidth;
			z-index: 1000;
		}
	}
}

@media screen and (max-height: 954px) and (min-width: 768px) {
	.MenuVertical {
		#Header {
			width: $verticalMenuWidth;

			.mega-menu-link {
				padding-left: 0;
			}
		}

		ul#mm {
			width: $verticalMenuWidth;
		}

		#mm > li.mega-menu-item > a.mega-menu-link {
			padding-left: 0;
			padding-right: 0;
		}
	}
}

#Logo_top {
	height: 60px;
	width: 250px;
	margin: 10px 0;
	background-repeat: no-repeat;
	background-size: contain;
	background-position-x: right;
	background-position-y: center;

	@include media-breakpoint-only(lg) {
		max-width: 140px;
	}

	@include media-breakpoint-up(lg) {
		position: absolute;
		right: 1rem;
	}

	@include media-breakpoint-down(md) {
		position: fixed;
		display: block;
		z-index: 1001;
		height: 40px;
		background-position: 100% 50%;
		right: 15px;
		top: 0;
	}
}

.MenuHorizontal {
	#Logo_top {
		@include media-breakpoint-up(lg) {
			position: fixed;
			right: 1rem;
			top: 0;
			z-index: 1001;
		}
	}
}

.MenuVertical {
	#Logo_top {
		@include media-breakpoint-up(lg) {
			right: 30px;
			top: 0;
		}
	}
	.pageTitle {
		@include media-breakpoint-up(lg) {
			padding-right: 250px;
			min-height: 7rem;
		}
	}
}
