.tooltip {
	.tooltip-inner {
		white-space: pre-wrap !important;
		font-size: var(--font-size-base);
		background: #444;
		padding: 0.5rem;
		max-width: 500px !important;
		color: #Fff !important;
		@include border-radius;

		table tr td {
			color: #Fff !important;
		}
	}

	.arrow {
		&:before {
			border-top-color: #444;
		}
	}
}
