.filters .mode {
	opacity: 0;
	transition: opacity ease-in-out 0.2s;
}

.ViewToggler {
	font-weight: bold;

	button {
		font-weight: normal;
		text-decoration: none;
		display: inline-flex;
		align-items: center;
		padding: 0;
		font-size: 1rem;

		&:not(.active) {
			color: var(--font-color-base);
		}

		&.active {
			font-weight: bold;
		}

		&:hover {
			span {
				text-decoration: underline;
			}

			.icon {
				text-decoration: none;
			}
		}

		.icon {
			font-size: 1.2rem;
			margin-right: 0.3rem !important;
		}
	}

	legend {
		font-size: 1rem;
	}

	& > div,
	& > button {
		display: flex;
		margin-top: 0.3rem;
	}

	&.viewport {
		justify-content: flex-end;
		display: flex;
		margin-top: 10px;
	}
	&.viewport + h1 {
		margin-top: 15px;
	}

	&.viewport > div {
		margin-top: 0;
		margin-left: 0.5em;
		display: flex;
	}
	&.viewport .desktop:before {
		content: '|';
		margin-right: 0.5em;
	}
}

#Login {
	.ViewToggler {
		display: none;
	}
}