.rw-datetime-picker {
	.rw-widget-container {
		min-height: 0;
		border-radius: $border-radius !important;

		input {
			border:0;
			box-shadow: none !important;

			&::placeholder {
				color: #6c757d !important;
			}
		}
	}
}