//# Custom checkbox

// Customize the label / the container

input[type='checkbox'] {
	accent-color: var(--radio-checkbox-checked-bg-color);
}

.checkbox,
.radio {
	display: block;
	content: '';
	cursor: default;
	position: relative;
	min-height: 20px;
	margin: 4px 6px 4px 0;
	padding: 2px 0 2px 12px;
	max-width: fit-content;

	color: var(--font-color-base);
	// font-size: 14px;
	line-height: 18px;
	text-indent: 6px;

	box-sizing: border-box;
	user-select: none;

	// Create a custom checkbox/radio button
	input {
		&[type='checkbox'],
		&[type='radio'] {
			&,
			&.form-control {
				position: absolute;
				opacity: 0;
				cursor: default;
				width: 0;
				height: 0;
			}

			+ .checkmark,
			+ label {
				padding: 0;
				margin-left: 8px;

				&::after,
				&::before {
					display: block;
					position: absolute;
					content: '';
					cursor: pointer;
				}

				&::before {
					width: var(--radio-checkbox-size);
					height: var(--radio-checkbox-size);
					top: 3px;
					left: 1px;
				}

				&::before {
					background-color: var(--input-background-color);
					box-shadow: 0 0 0 2px var(--radio-checkbox-border-color);
					border-radius: var(--radio-checkbox-border-radius);
				}

				&::after {
					display: none;
				}
			}

			&:checked {
				// When the checkbox is checked, add a blue background
				+ .checkmark,
				+ label {
					&:before {
						background-color: var(--radio-checkbox-checked-bg-color);
						box-shadow: 0 0 0 2px var(--radio-checkbox-checked-bg-color);
					}

					// Show the checkmark/indicator (dot/circle) when checked
					&::after {
						display: block;
					}
				}
			}
		}

		&[type='radio'] {
			+ .checkmark,
			+ label {
				&:before {
					border-radius: 20px;
				}
			}
		}
	}

	// On focus
	&:focus-within,
	&.focus {
		@include focus-outline;
	}

	// On error
	.has-error &,
	&.has-error,
	&.is-invalid {
		outline: 1px solid var(--input-has-error-border-color) !important;
		outline-offset: 3px;

		// On Error and focused
		&:focus-within,
		&.focus {
			outline: 2px solid var(--input-has-error-border-color) !important;
			outline-offset: 2px;
		}
	}

	// Disabled state
	.disabled &,
	:disabled &,
	&.disabled,
	&:disabled {
		color: var(--input-disabled-text-color);
	}

	.disabled &,
	:disabled &,
	&.disaled,
	&:disabled,
	input.disabled,
	input:disabled {
		&[type='checkbox'],
		&[type='radio'] {
			+ .checkmark,
			+ label {
				&:before {
					cursor: default;
					box-shadow: 0 0 0 1px var(--input-disabled-border-color);
				}
			}
		}

		// checked state for input type checkbox
		&[type='checkbox'] {
			&:checked {
				+ .checkmark,
				+ label {
					&:before {
						background-color: #96d2fa;
						box-shadow: 0 0 0 1px #96d2fa;
					}
				}
			}
		}

		// checked state for input type radio
		&[type='radio'] {
			&:checked {
				+ .checkmark,
				+ label {
					&:before {
						background-color: var(--white);
					}

					&::after {
						background-color: var(--medium-gray);
					}
				}
			}
		}
	}

	&.radio-inline,
	&.checkbox-inline {
		display: inline;
		padding-left: 25px;
	}
}

.checkbox label,
.radio label {
	text-indent: 0px;
}

//  Style the checkmark/indicator
.checkbox {
	label,
	.checkmark {
		border-radius: 3px;

		&::after {
			width: 5px;
			height: 10px;
			top: 5px;
			left: 7px;

			border: solid var(--white);
			border-width: 0 2px 2px 0;

			transform: rotate(45deg);
		}
	}
}

// Style the indicator (dot/circle)
.radio {
	label,
	.checkmark {
		border-radius: 50%;

		&::after {
			width: 6px;
			height: 6px;
			top: 8px;
			left: 6px;

			border-radius: 50%;
			background-color: var(--white);
		}
	}
}

.table td.gridcheckbox .checkbox input[type='checkbox'],
.table th.gridcheckbox .checkbox input[type='checkbox'] {
	display: block;
}

.table th.gridcheckbox {
	padding: 0.5rem;
}
