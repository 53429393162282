.scripts_user_general_text iframe {
	min-height: 800px;
}

html.scripts_user_general_text #Dashboard {
	width: 1130px;
	max-width: 100%;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-right: auto;
	margin-left: auto;

	.bx-wrapper .bx-loading {
		display: none;
	}

	.bx-viewport {
		height: 270px;

		.bxslider li {
			width: 100% !important;
		}
	}
}

html.scripts_user_general_text {
	//--- homepage
	body,
	#page-wrapper {
		background: transparent;
	}

	#Content {
		main > div.row > .col-12 > .card {
			//--- echt ongelofelijk lelijke manier om de wrapper van de startpagina aan te roepen, wanneer mogelijk aanpassen.
			border: 0;
			background-color: transparent;
		}

		main > div.row > .col-12 .card-body {
			padding: 0;
		}

		main > div.row > .col-12 .card-header + .card-collapse .card-body {
			padding: 1rem;
		}
	}
}

html.scripts_user_general_text body {
	#Dashboard {
		@extend .clearfix;
		padding: 15px;

		.ImageSlider {
			.bx-caption {
				display: none;
			}

			.bx-prev,
			.bx-next {
				display: none;
			}
		}
	}
}

body.dashboard-fluid {
	#Dashboard {
		display: grid !important;
		width: auto;
		font-size: 1.1rem;
		padding: 0;

		&::before {
			display: none;
		}

		.Block {
			max-width: 100% !important;
			height: auto !important;
			margin: 0px !important;

			.Content {
				width: 100%;
				height: 100%;
				overflow-y: auto;
				padding: 10px;
				display: block;

				.Header {
					font-size: 1.6rem;
					font-weight: 500;
					margin-bottom: 10px;
				}

				.Pointer,
				.Pointer a {
					text-decoration: underline;
					margin-bottom: 1rem;

					@include media-breakpoint-only(xl) {
						margin-bottom: 0.8rem;
					}
				}
			}

			&.ImageSlider {
				.Header {
					display: none;
				}

				.Content {
					padding: 0;
					overflow: hidden;
				}

				.bx-caption {
					display: none;
				}

				.bxslider {
					height: 100%;
				}

				.bxslider li {
					left: 0;
					width: 100%;
					height: 100%;
				}

				.bxslider li img {
					width: 100%;
					height: 100%;
					display: block;
					background-position: 50% 50%;
					background-size: cover;
				}

				@include media-breakpoint-down(sm) {
					display: none;
				}
			}

			&.Image {
				.Content {
					padding: 0 !important;

					.Header {
						display: none;
					}

					.Item {
						width: 100%;
						height: 100%;
						background-size: cover;
						background-position: 50%;
					}
				}
			}

			&.Button {
				.Item {
					width: 100%;
					height: 100%;
					position: relative;
					font-size: 1.3rem;
					text-align: center;

					.PageLink {
						display: block;
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						text-indent: -9999px;
						z-index: 3;
						margin-bottom: 0;
					}
				}

				.Header {
					display: none;
				}

				.Content {
					transition: background 0.2s;
					padding: 0;
				}

				.ContentShort {
					width: 100%;
					height: 100%;
					position: relative;

					a {
						position: relative;
						text-align: center;
						display: block;
						width: 100%;
						height: 100%;
						text-decoration: none;
						font-size: 1.3rem;
						z-index: 1;
						padding: 10px;
					}

					.icon {
						font-size: 6rem;
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 70%;
						color: white;
						text-align: center;
						top: 30%;

						@include media-breakpoint-only(xl) {
							font-size: 4rem;
							height: 55%;
							top: 45%;
						}

						@include media-breakpoint-down(xs) {
							font-size: 4rem;
							height: 55%;
							top: 45%;
						}
					}
				}
			}

			&.Training {
				.Items {
					.Header {
						display: none;
					}
				}

				.Row.Clickable {
					padding: 0 0 10px 0;
					cursor: pointer;
					width: 100%;
					transition: background 0.2s;

					&:hover {
						background-color: rgba(255, 255, 255, 0.2);
					}

					@include media-breakpoint-only(xl) {
						padding-bottom: 2px;
					}
				}

				.Cell.Name {
					width: 100%;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					text-decoration: underline;
				}

				.Cell.Companyname,
				.Cell.Course_category,
				.Cell.Location {
					display: none;
				}

				.Cell.Startdate,
				.Cell.Enddate {
					width: 44%;
					font-size: 0.9rem;
					display: inline-block;
				}

				.Search {
					background: #a6c939;
					padding: 0.375rem 0.75rem;
					display: inline-block;
					border-radius: 0.4rem;
					cursor: pointer;
				}
			}

			&.custom_db {
				display: none;
			}
		}
	}
}

// PPDEV-1415 - Company Startpagina
.icon {
	&.icon-arrow-left,
	&.icon-arrow-right {
		&::before {
			text-shadow: 0px 0px 0px #fff, 0px 0px 0px #fff, -1px 0px 0 #fff,
				-1px 0px 0 #fff;
			// text-shadow: 0 0 0 2px #f00;
		}
	}
}

.carousel__dot {
	width: 7px !important;
	height: 7px !important;
	box-shadow: 0 0 0 1px #fff;
}

.carousel__dot .dropdown.d-inline-block {
	// hier moet echt d-inline-block weg
	display: flex !important;
	align-items: center;
}

.btn.carousel__dot:focus {
	box-shadow: 0 0 0 1px #fff !important;
}

// .PagList,
// .SiteList {
// 	.icon {
// 		line-height: 1.5; // komt van body{line-height:1.5} uit default.css
// 	}
// 	.icon-pp_home {
// 		margin-right: 0;
// 	}
// 	.dropdown > .btn {

// 		&:before {
// 			align-self: center;
// 		}
// 		&:hover,
// 		&:focus,
// 		&:active {
// 			cursor: pointer;
// 			background: transparent !important;
// 		}
// 	}
// }

.WidgetLink {
	.btn {
		margin-right: 21px;
	}
}

.dropdown-menu.show {
	// voor more-menu in company startpagina - PPDEV-2803
	li {
		padding: 2px 0;
	}
	.dropdown-item {
		cursor: pointer;
		white-space: normal;

		&.disabled,
		&:disabled {
			cursor: not-allowed;
			color: $gray-400;
		}
	}
}

.scripts_external_startpages {
	#root {
		min-height: calc(100vh - 125px); // 80+45
	}
}

.scripts_user_general_text.MenuVertical #startpage_content {
	margin-top: 60px;
}


#startpage_content {
	font-size: 12px;
	font-family: Helvetica,Arial,sans-serif;

	.card {
		box-shadow: none;
	}
}