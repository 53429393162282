.QuestionLink.active > a {
	background-color: orange;
	border: 1px solid orange;
}
.QuestionLink.Answered > a {
	color: #fff;
	font-weight: 300;
	background-color: #4986e7;
}
.Answer label {
	font-weight: normal;
}

#popup nav {
	text-align: center;
}

.AnswerFalse {
	.icon {
		color: var(--red-normal);
	}
}

.AnswerCorrect {
	.icon {
		color: var(--green-normal);
	}
}

td.Answer {
	line-height: 1.5rem;
	vertical-align: top;
	padding-top: 0.2rem;

	label {
		cursor: pointer;
	}
}

.scripts_user_training_details,
.scripts_user_trainingregistration_catalogueV2,
.scripts_user_elearning {
	@media (min-width: 992px) {
		.modal-lg {
			max-width: 1000px;
		}
	}
}
