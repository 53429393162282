@use 'sass:math';

$circleHeight: 40px;
$lineHeight: $circleHeight - 10;
$fontLevel: math.div($circleHeight, 3);

/*----------------Card----------------*/
.tile,
.Tile {
	.card-header {
		padding: var(--spacing) var(--spacing) 0 var(--spacing);
	}

	.card-body {
		padding: var(--spacing);
	}

	.card-footer {
		padding: 0 var(--spacing) calc(var(--spacing) * .66) var(--spacing) !important;
	}

	&.evidence_expired {
		.card {
			border-left: 5px solid var(--red-normal);

			.card-header {
				border-bottom: 1px solid var(--red-normal);
			}

			.card-body {
				min-height: 90px;
			}

			.status > span {
				border: 6px solid var(--red-normal) !important;
				background: transparent !important;

				&:before {
					color: var(--red-normal) !important;
				}
			}

			
		}
	}

	.btn .icon {
		margin-right: 0;
	}

	&.evidence_expiring {
		.card {
			border-left: 5px solid var(--orange-normal);

			.card-header {
				border-bottom: 1px solid var(--orange-normal);
			}

			.status > span {
				border: 6px solid var(--orange-normal) !important;
				background: transparent !important;

				&:before {
					color: var(--orange-normal) !important;
				}
			}
		}
	}

	&.evidence_obtained {
		.card {
			border-left: 5px solid var(--green-normal);

			.card-header {
				border-bottom: 1px solid var(--green-normal);
			}

			.status > span {
				border: 6px solid var(--green-normal) !important;
				background: transparent !important;

				&:before {
					color: var(--green-normal) !important;
				}
			}
		}
	}

	&.evidence_exempted {
		.card {
			border-left: 5px solid #484848;

			.card-header {
				border-bottom: 1px solid #484848;
			}

			.status > span {
				border: 6px solid #484848 !important;
				background: transparent !important;

				&:before {
					color: #484848 !important;
				}
			}
		}
	}

	&.evidence_approval_required {
		.card {
			border-left: 5px solid var(--orange-normal);

			.card-header {
				border-bottom: 1px solid var(--orange-normal);
			}

			.status > span {
				border: 6px solid var(--orange-normal) !important;
				background: transparent !important;

				&:before {
					color: var(--orange-normal) !important;
				}
			}
		}
	}
	
	&.usercertificates {
		.row_wrapper {
			height: auto;
			min-height: 20px;
		}
	}

	.card,
	&.card {
		min-height: 190px;

		&[onclick^='window'] {
			cursor: pointer;
		}

		&.active {
			cursor: default;
		}

		.header {
			margin: 1rem;
		}

		.card-header {
			.nav {
				margin-top: 1rem;
			}
		}

		.card-body {

			// min-height:101px;
			// padding-top:13px;
			.status {
				width: 60px;
				height: 60px;
				float: left;

				span[class^='icon'] {
					@extend .tile_chartcircle;
					background: var(--competence-status-bg-color);
					border: 6px solid var(--competence-status-bg-color);
					width: 60px;
					height: 60px;

					&:before {
						color: white;
						line-height: 48px;
						font-size: 2rem;
					}
				}

				&.details {
					.row_wrapper {
						min-height: 20px;
					}
				}
			}

			.status.details > .row_wrapper {
				.row_wrapper {
					min-height: 20px;
				}
			}

			.details {
				.row_wrapper {
					.Filteritem {
						img {
							vertical-align: middle !important;
						}
					}

					.FilterIcon {
						float: left;
					}

					.evidences {
						.badge {
							margin-right: 5px;
							background: var(--green-normal);
						}
					}

					.evidencesExpiring,
					.evidencesexpiring {
						// margin: 0 10px;

						.badge {
							margin-right: 5px;
							background: var(--orange-normal);
							color: #000;
						}
					}

					.evidencesExpired,
					.evidencesexpired {
						// margin: 0 10px;

						.badge {
							margin-right: 5px;
							background: var(--red-normal);
						}
					}
				}
			}

			.compliance {
				font-size: 0px;
				width: 80px;
				height: 80px;
				float: left;

				& .compliance_percentage,
				.percentage {
					font-size: 1.3rem;
					position: absolute;
					line-height: 79px;
					text-align: center;
					width: 80px;
				}
			}

			.details {
				padding-left: 1rem;
				overflow: hidden;
				line-height: 20px;

				.label,
				.value {
					color: var(--font-color-base);
					// font-size: $font-size;
					font-weight: normal;
					padding: 0.2em 0.6em 0.3em;
					line-height: 1;
					min-width: 85px;
					text-align: left;
					display: inline-block;
				}

				.Competenties {
					.category_compliance {
						float: left;
						margin-right: 10px;
					}
				}
			}

			div[id^='chart'] {
				position: absolute;
				top: 15px;
			}

			.Level {
				@include circle($circleHeight);
				background: var(--competence-level-grade-bg-color);

				span {
					line-height: 24px;
					display: block;
					margin: 0 auto;
					text-align: center;
					font-size: 18px;
					font-family: var(--font-family-base);
				}
			}

			td[class^='level_'] {
				padding: 0px !important;
				background-repeat: no-repeat;
				border: 1px solid #ccc;
			}

			td[class$='normal'] {
				background: #f0f0f0;
			}

			td[class$='highlighted'] {
				background: var(--green-normal);
				position: relative;

				&:before,
				&:after {
					top: 90%;
					left: 50%;
					border-style: solid;
					border-top-color: transparent;
					border-left-color: transparent;
					border-right-color: transparent;
					content: ' ';
					height: 0;
					width: 0;
					position: absolute;
					pointer-events: none;
					border-bottom-color: #666;
					border-width: 5px;
					margin-left: -5px;
				}
			}

			.LevelBar {
				display: inline-block;
				width: 80%;

				table.table_level {
					width: 100%;

					td {
						text-align: center;
					}
				}
			}
		}

		.card-footer {
			background-color: #fff;
			border-top: 0px solid #ddd;
		}
	}
}

.chart {
	.progress_ok path {
		fill: #999;
	}

	.progress_nok path {
		fill: #ddd;
	}
}

.category .chart {
	display: none;
}

.usercompliancycertificates,
.usercompliancy_knowledge_skills,
.projectcompliancycertificates {
	.progress_ok path {
		fill: var(--green-normal);
	}

	.progress_exp path {
		fill: var(--orange-normal);
	}

	.progress_nok path {
		fill: var(--red-normal);
	}
}

.col-12:has(.projectcompliancycertificates) {
	display: flex;

	.card {
		flex-grow: 1;
	}

	.card-body {
		flex-grow: 1;
		display: flex;
		align-items: flex-end;

	}
}