.responsive-video {
	.modal-dialog {
		max-width: 1000px;
		margin: 1.75rem auto;
	}

	.modal-body {
		padding: 0 !important;
	}

	.tablet {
		padding: 0px !important;
	}
}
