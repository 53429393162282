label {
	margin-bottom: 5px;
	font-weight: bold;
	line-height: 24px;
}

.control-label {
	width: 100%;
	display: flex;
}

.form-check-label {
	font-weight: normal;
	line-height: 18px;
}