.dropdown {
	&-item {
		text-decoration: none;
		padding: 2px 1.5rem;

		&:focus,
		&:active,
		&.active {
			box-shadow: inset 0px 0px 1px 1px #000;
			background-color: transparent;
			color: var(--font-color-base);
		}

		&:hover {
			background-color: var(--select-hover-background-color) !important;
		}

		&.disabled,
		&:disabled {
			cursor: not-allowed;
			pointer-events: auto;
		}
	}

	&-menu {
		> li > a {
			padding: 2px 10px;
			font-size: var(--font-size-base);

			span {
				margin-right: 8px;
			}
		}

		&-right {
			left: auto;
			right: 0;
		}

		.bs-actionsbox,
		.bs-donebutton {
			min-width: 250px;

			.btn {
				justify-content: center;
			}
		}

		.inner li a {
			font-size: var(--font-size-base);

			span.check-mark {
				right: 5px !important;
			}
		}
	}

	&-toggle:not(.btn-primary) {
		background-color: var(--dropdown-toggle-bg-color) !important;
		border-color: var(--dropdown-toggle-border-color) !important;
		color: var(--dropdown-toggle-text-color) !important;
		padding: 9px 10px;
		border-radius: 8px;
		display: flex;
		height: 41px;
		justify-content: center;
		align-items: center;
	

		.filter-option {
			color: var(--font-color-base);
		}

		&:focus {
			outline: none !important;
		}

		&:after {
			@include arrow-down;
			border: 0;
			width: 14px;
			height: 12px;
			margin: 0;
			padding: 0;
		}
	}
}

.gridComponent {
	.dropdown {
		&-toggle {
			border: 1px solid transparent;

			&:hover {
				border: 1px solid #000 !important;
				background-color: var(--select-hover-background-color) !important;
				box-shadow: shadow(20) rgba(0,0,0, 0.15) !important;
			}

			&:focus,
			&[aria-expanded='true'] {
				outline: 0;
				border: 1px solid #000 !important;
				box-shadow: inset 0 0 0 1px #000,
					shadow(20) rgba(0,0,0, 0.15) !important;
			}
		}

		select {
			left: 0;
		}
	}
}

.dropdown-menu {
	z-index: 1002;
}
