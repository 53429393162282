.Friend {
	@include make-col-ready();
	margin-bottom: 1rem;
	margin-top: 1rem;

	@include media-breakpoint-only(sm) {
		@include make-col(6);
	}

	@include media-breakpoint-up(md) {
		@include make-col(6);
	}

	@include media-breakpoint-up(lg) {
		@include make-col(4);
	}

	@include media-breakpoint-up(xl) {
		@include make-col(3);
	}

	@include media-breakpoint-up(xxxl) {
		@include make-col(2);
	}

	.card {
		height: 100%;
		margin: 0;

		&-title {
			font-weight: bold;
		}

		&-text {
			overflow: hidden;
			margin-bottom: 0;
		}
	}
}
