// select {
// 	cursor: pointer;

// 	&:active,
// 	&:hover {
// 		outline: none;
// 	}
// 	&:active, &:focus {
// 		background: transparent;
// 		outline: none;
// 		border: 1px solid $form-field-border-color;
// 	}

// 	&::-ms-expand {
// 		display: none;
// 	}
// }

.select2 {
	outline: none !important;

	&-selection {
		&__placeholder {
			color: var(--input-placeholder-color) !important;
			opacity: 1;
			font-style: italic;
		}

		&__rendered {
			padding-left: 0 !important;
			line-height: 1.8 !important;
		}

		&__choice {
			gap: 10px;

			&__display {
				white-space: normal;
				line-height: 1.3;
			}
		}
	}

	&-container {
		max-width: 100%;
		width: 100% !important;
		display: block !important;

		&--open {
			width: auto !important;
		}

		&--bootstrap-5 {
			.select2-container--open {
				width: auto !important;
			}

			.select2-selection--single,
			.select2-selection--multiple {
				min-height: var(--select-height);
				height: auto;
				background-color: var(--select-bg-color);
				border-color: var(--select-border-color) !important;
				border-radius: var(--select-border-radius);
				background-position: right 0.5rem center;
			}

			.select2-selection--multiple {
				padding: 0 24px 0 0;

				.select2-selection__clear {
					top: 1.3rem;
					right: 2rem;
				}
			}
		}
	}

	&-search--inline {
		margin: 0.375rem;
	}

	&-search__field,
	&-search__field:focus {
		padding: 4px 12px 5px;
		box-shadow: none !important;
		outline: none;
	}

	&-selection {
		border-color: var(--input-border-color) !important;

		&__arrow {
			display: none;
		}

		&__choice {
			margin: 0.375rem !important;
		}
	}

	&-dropdown {
		z-index: 10010;
	}
}

select,
.select2-selection {

	&,
	&.form-control {
		cursor: pointer;
		// min-width: 160px;
		max-width: 100%;
		height: var(--select-height);
		padding: 3px 18px 3px 12px;

		color: var(--font-color-base);

		font: {
			size: unset !important;
			weight: normal;
		}

		line-height: 18px;

		text: {
			align: left;
			overflow: ellipsis;
			shadow: none;
		}

		vertical-align: middle;
		white-space: nowrap;

		border: 1px solid var(--input-border-color);
		border-radius: var(--input-border-radius);

		background: {
			color: var(--input-background-color);
			repeat: no-repeat;
			size: 16px 12px;
			position: calc(100% - 8px) 14px;
		}

		@include arrow-down;

		appearance: none;

		// hide arrow in IE/Edge
		&::-ms-expand {
			display: none;
		}

		//### cta button hover state
		&:hover {
			@include input-hover-effect(true, true);
			background-color: var(--select-hover-background-color);
		}

		&:focus:not(:active) {
			@include focus-border;
		}

		//### cta button active/pressed state
		&:active {
			outline: 0;
			border-color: var(--light-gray) !important;
			background-color: var(--light-gray);
			box-shadow: none;
		}

		//### cta button disabled state
		&:disabled,
		&.disabled,
		:disabled &,
		.disabled & {
			pointer-events: none;
			color: var(--input-disabled-text-color);

			border-color: var(--input-disabled-border-color);
			background-color: var(--select-disabled-bg-color);
			background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSIxMCIgdmlld0JveD0iMCAwIDEyIDEwIj48ZGVmcz48c3R5bGU+LmF7ZmlsbDojZmZmO29wYWNpdHk6MDt9LmJ7ZmlsbDojOTU5Nzk5O30uY3tmaWx0ZXI6dXJsKCNhKTt9PC9zdHlsZT48ZmlsdGVyIGlkPSJhIiB4PSIwIiB5PSIxLjQ5NyIgd2lkdGg9IjEyIiBoZWlnaHQ9IjcuOTYiIGZpbHRlclVuaXRzPSJ1c2VyU3BhY2VPblVzZSI+PGZlT2Zmc2V0IGR5PSIxIiBpbnB1dD0iU291cmNlQWxwaGEiLz48ZmVHYXVzc2lhbkJsdXIgc3RkRGV2aWF0aW9uPSIwLjUiIHJlc3VsdD0iYiIvPjxmZUZsb29kIGZsb29kLWNvbG9yPSIjMDAxZjMzIiBmbG9vZC1vcGFjaXR5PSIwLjIiLz48ZmVDb21wb3NpdGUgb3BlcmF0b3I9ImluIiBpbjI9ImIiLz48ZmVDb21wb3NpdGUgaW49IlNvdXJjZUdyYXBoaWMiLz48L2ZpbHRlcj48L2RlZnM+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMS40OTQpIj48cmVjdCBjbGFzcz0iYSIgd2lkdGg9IjkiIGhlaWdodD0iOSIvPjxnIGNsYXNzPSJjIiB0cmFuc2Zvcm09Im1hdHJpeCgxLCAwLCAwLCAxLCAtMS40OSwgMCkiPjxwYXRoIGNsYXNzPSJiIiBkPSJNNC41LDQuOTU4YS41LjUsMCwwLDEtLjM5NC0uMTQ1TC4xNDYuODUzQS41LjUsMCwwLDEsLjg1My4xNDZMNC41LDMuNzkyLDguMTQ2LjE0N2EuNS41LDAsMCwxLC43MDcuNzA3bC0zLjk2LDMuOTZhLjUuNSwwLDAsMS0uMzU0LjE0NloiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDEuNSAyKSIvPjwvZz48L2c+PC9zdmc+');
			box-shadow: none;
		}

		&__clear {
			color: var(--font-color-base) !important;
			font-size: 1.2rem;
			line-height: 2.2;
		}

		>option {
			background-color: var(--select-bg-color);

			&:hover {
				background-color: var(--select-hover-background-color);
			}
		}

		// large inputs
		&.input-lg {
			height: 48px;
			background-position: right 6px top 18px;
		}

		.has-error &,
		&.has-error {
			@include input-invalid-effect;
			box-shadow: none;

			&:focus:not(:active) {
				@include input-invalid-effect;
				@include input-invalid-focus-effect;
			}
		}
	}
}

.select2-results__option {
	font-size: unset !important;
}

.select2-dropdown {
	border-color: var(--select-border-color) !important;
}

.select2-container .select2-selection--single {
	min-height: var(--select-height) !important;
	background-color: var(--select-bg-color);
	border-color: var(--select-border-color) !important;
	color: var(--select-text-color);
	border-radius: var(--input-border-radius);
	position: relative;
}