@use 'sass:math';

.sr-only {
	position: absolute !important;
}

.rotate {
	transform: rotate(-90deg);
}

#trigger {
	font-size: 3rem;
}

.responsive-video,
#ToWatchFrame {
	max-width: 100%;
	width: 1000px;
}

#ToWatchFrame {
	height: 410px;
}

.HideElement {
	display: none;
}

.disabled {
	color: #ccc;
}

/* Image cropper */
.form-control-static {
	img#preview {
		max-width: none;
	}
}

.clearfix:before,
.clearfix:after {
	content: ' ';
	display: table;
}

.clearfix:after {
	clear: both;
}

.arrow_down {
	//border:none;
	top: auto;
	position: relative;
	right: 0px;
	//bottom: 10px;
}

.hide {
	display: none !important;
}

.aspect-ratio-box {
	height: 0;
	overflow: hidden;
	padding-top: math.div(149px, 275px) * 100%;
	background: white;
	position: relative;
}

.aspect-ratio-box-inside {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
}

.help-block {
	font-style: italic;
}

.percentage,
td.percentage {
	text-align: right;
	&.matrix_header_left_first {
		text-align: left;
	}
}

.ScormModalContent,
.ScormModalContent body {
	background-color: transparent;
}

.ui-sortable-handle {
	cursor: move;
}