.icon {
	text-decoration: none;
	font-size: var(--icon-font-size);

	&:focus-visible {
		outline: 0;
		border-color: #000;
		box-shadow: inset 0 0 0 1px #000, shadow(20) rgba(0, 0, 0, 0.15);
	}

	&-warning {
		color: var(--red-normal);
	}

	&-custom-check {
		display: inline-block;
		width: 13px;
		height: 13px;
		color: var(--green-normal);
		background-size: contain;
		background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyNi40LjEsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMl8wMDAwMDE2ODA5OTgwMDM5MDAyNzEyNjQ2MDAwMDAxNjgwNjg5MjE2MDA3MDI4NTQ1N18iDQoJIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgNTEyIDUxMiINCgkgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNTEyIDUxMjsiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4NCgkuc3Qwe2ZpbGw6IzREODQwMDt9DQo8L3N0eWxlPg0KPGc+DQoJPHBhdGggY2xhc3M9InN0MCIgZD0iTTEyOCw0NS4xdjM3LjhIMTZ2ODguNmg0NzkuOVY4Mi45aC04MHYzNC44aC0zMlY0NS4xaC0zMnYzNy44SDE5MnYzNC44aC0zMlY0NS4xTDEyOCw0NS4xeiIvPg0KCTxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik0zOTYuOCwyNjguN2MtNTQuNiwwLTk5LDQ0LjUtOTksOTljMCw1NC43LDQ0LjUsOTkuMiw5OSw5OS4yYzU0LjcsMCw5OS4yLTQ0LjUsOTkuMi05OS4yDQoJCUM0OTYsMzEzLjEsNDUxLjUsMjY4LjcsMzk2LjgsMjY4Ljd6IE0zODMuOSw0MTEuNGwtMzguNy0zNy45bDIyLjQtMjIuOWwxNiwxNS44bDQyLjQtNDIuNGwyMi43LDIyLjZMMzgzLjksNDExLjR6Ii8+DQoJPHBhdGggY2xhc3M9InN0MCIgZD0iTTE2LjEsNDY2LjloMzA3LjhjLTExLjgtOC44LTIyLjEtMTkuNS0zMC4xLTMyYy0xMi42LTE5LjQtMjAtNDIuNC0yMC02Ny4yYzAtNjcuOCw1NS4yLTEyMywxMjMtMTIzDQoJCWMyNC44LDAsNDcuOCw3LjQsNjcuMiwyMGMxMi41LDgsMjMuMiwxOC4yLDMyLDMwLjF2LTkxLjJIMTYuMUwxNi4xLDQ2Ni45eiBNMjUzLjUsMjQ5LjVoMzJ2MzJoLTMyVjI0OS41eiBNMTY5LjIsMjQ5LjVoMzJ2MzJoLTMyDQoJCVYyNDkuNXogTTE2OS4yLDMxOS4yaDMydjMyaC0zMlYzMTkuMnogTTE2OS4yLDM4OWgzMnYzMmgtMzJWMzg5eiBNODQuOSwyNDkuNWgzMnYzMmgtMzJWMjQ5LjV6IE04NC45LDMxOS4yaDMydjMyaC0zMlYzMTkuMnoNCgkJIE04NC45LDM4OWgzMnYzMmgtMzJWMzg5eiIvPg0KPC9nPg0KPC9zdmc+DQo=");

		&.isCritical {
			background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyNi40LjEsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMl8wMDAwMDE2ODA5OTgwMDM5MDAyNzEyNjQ2MDAwMDAxNjgwNjg5MjE2MDA3MDI4NTQ1N18iDQoJIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgNTEyIDUxMiINCgkgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNTEyIDUxMjsiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4NCgkuc3Qwe2ZpbGw6I0YyOEQwMDt9DQo8L3N0eWxlPg0KPGc+DQoJPHBhdGggY2xhc3M9InN0MCIgZD0iTTEyOCw0NS4xdjM3LjhIMTZ2ODguNmg0NzkuOVY4Mi45aC04MHYzNC44aC0zMlY0NS4xaC0zMnYzNy44SDE5MnYzNC44aC0zMlY0NS4xSDEyOHoiLz4NCgk8cGF0aCBjbGFzcz0ic3QwIiBkPSJNMzk2LjgsMjY4LjdjLTU0LjYsMC05OSw0NC41LTk5LDk5YzAsNTQuNyw0NC41LDk5LjIsOTksOTkuMmM1NC43LDAsOTkuMi00NC41LDk5LjItOTkuMg0KCQlDNDk2LDMxMy4xLDQ1MS41LDI2OC43LDM5Ni44LDI2OC43eiBNMzgzLjksNDExLjRsLTM4LjctMzcuOWwyMi40LTIyLjlsMTYsMTUuOEw0MjYsMzI0bDIyLjcsMjIuNkwzODMuOSw0MTEuNHoiLz4NCgk8cGF0aCBjbGFzcz0ic3QwIiBkPSJNMTYuMSw0NjYuOWgzMDcuOGMtMTEuOC04LjgtMjIuMS0xOS41LTMwLjEtMzJjLTEyLjYtMTkuNC0yMC00Mi40LTIwLTY3LjJjMC02Ny44LDU1LjItMTIzLDEyMy0xMjMNCgkJYzI0LjgsMCw0Ny44LDcuNCw2Ny4yLDIwYzEyLjUsOCwyMy4yLDE4LjIsMzIsMzAuMXYtOTEuMkgxNi4xVjQ2Ni45eiBNMjUzLjUsMjQ5LjVoMzJ2MzJoLTMyVjI0OS41eiBNMTY5LjIsMjQ5LjVoMzJ2MzJoLTMyVjI0OS41DQoJCXogTTE2OS4yLDMxOS4yaDMydjMyaC0zMlYzMTkuMnogTTE2OS4yLDM4OWgzMnYzMmgtMzJWMzg5eiBNODQuOSwyNDkuNWgzMnYzMmgtMzJWMjQ5LjV6IE04NC45LDMxOS4yaDMydjMyaC0zMlYzMTkuMnogTTg0LjksMzg5DQoJCWgzMnYzMmgtMzJWMzg5eiIvPg0KPC9nPg0KPC9zdmc+DQo=");
		}
	}

	&-custom-cross {
		display: inline-block;
		width: 13px;
		height: 13px;
		color: var(--red-normal);
		background-size: contain;
		background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyNi40LjEsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMl8wMDAwMDEwOTAxMTkwMTg4OTkxNDk2ODMwMDAwMDAwNDY1ODUwMTMzNTQ3ODAwNDExMF8iDQoJIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgNTEyIDUxMiINCgkgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNTEyIDUxMjsiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4NCgkuc3Qwe2ZpbGw6I0Q5MzY0NDt9DQo8L3N0eWxlPg0KPGc+DQoJPHBhdGggY2xhc3M9InN0MCIgZD0iTTEyOCw0NS4xdjM3LjhIMTZ2ODguNmg0ODBWODIuOWgtODB2MzQuOGgtMzJWNDUuMWgtMzJ2MzcuOEgxOTJ2MzQuOGgtMzJWNDUuMUwxMjgsNDUuMXoiLz4NCgk8cGF0aCBjbGFzcz0ic3QwIiBkPSJNMzk2LjgsMjY4LjZjLTU0LjYsMC05OSw0NC41LTk5LDk5YzAsNTQuNyw0NC41LDk5LjIsOTksOTkuMmM1NC43LDAsOTkuMi00NC41LDk5LjItOTkuMg0KCQlDNDk2LDMxMy4xLDQ1MS41LDI2OC42LDM5Ni44LDI2OC42eiBNNDUzLDM5NmwtMjcuOCwyNy43TDM5NywzOTUuNWwtMjguMywyOC4yTDM0MSwzOTZsMjguMi0yOC4zTDM0MSwzMzkuNWwyNy43LTI3LjhMMzk3LDM0MA0KCQlsMjguMi0yOC4zbDI3LjgsMjcuOGwtMjguMywyOC4yTDQ1MywzOTZ6Ii8+DQoJPHBhdGggY2xhc3M9InN0MCIgZD0iTTE2LDQ2Ni45aDMwNy44Yy0xMS44LTguOC0yMi4xLTE5LjUtMzAuMS0zMmMtMTIuNi0xOS40LTIwLTQyLjQtMjAtNjcuMmMwLTY3LjgsNTUuMi0xMjMsMTIzLTEyMw0KCQljMjQuOCwwLDQ3LjgsNy40LDY3LjIsMjBjMTIuNSw4LDIzLjIsMTguMiwzMiwzMC4xdi05MS4ySDE2TDE2LDQ2Ni45eiBNMjUzLjQsMjQ5LjRoMzJ2MzJoLTMyTDI1My40LDI0OS40eiBNMTY5LjEsMjQ5LjRoMzJ2MzINCgkJaC0zMlYyNDkuNHogTTE2OS4xLDMxOS4yaDMydjMyaC0zMlYzMTkuMnogTTE2OS4xLDM4OWgzMnYzMmgtMzJWMzg5eiBNODQuOCwyNDkuNGgzMnYzMmgtMzJWMjQ5LjR6IE04NC44LDMxOS4yaDMydjMyaC0zMlYzMTkuMnoNCgkJIE04NC44LDM4OWgzMnYzMmgtMzJWMzg5eiIvPg0KPC9nPg0KPC9zdmc+DQo=")
	}

	&.rounded-circle {
		width: 80px;
		height: 80px;
		display: inline-flex;
		align-items: center;
		justify-content: center;
	}

	&.envelopeInActive {
		color: var(--red-normal);
	}

	&.envelopeActive {
		color: var(--green-normal);
	}

	&.favorite {
		vertical-align: -5px;
		font-size: 32px;
		cursor: pointer;
		margin-left: .2em;
	}

	&.float-right {
		margin-left: 10px;
	}

	&.evidence_obtained {
		color: var(--green-normal);
	}

	&.evidence_expiring {
		color: var(--orange-normal);
	}

	&.evidence_expired {
		color: var(--red-normal);
	}
}

.matrix_cell {
	span.icon {
		&.icon-check {
			color: var(--green-normal);
		}

		.icon-alarm2,
		&.icon-check {

			&.expiring,
			&.isCritical,
			&.isCriticalcertificateStatus {
				color: var(--orange-normal);
			}
		}

		&.icon-cancel-square {
			color: var(--red-normal);
		}

		&.icon-clear {
			color: var(--red-normal);
		}
	}

	.input-group .icon,
	.modal-body .icon {
		color: inherit !important;
	}
}

.scripts_admin_training_registration_registration_training_edit {
	.icon-cross {
		color: var(--red-normal);
	}

	.icon-check {
		color: var(--green-normal);
	}
}

.fv-plugins-icon-container label {
	width: 100%;
}