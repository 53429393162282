.progress {
	height: 1.4rem;
}

.progress-bar {
	float: left;
	width: 0%;
	height: 100%;
	font-size: 12px;
	line-height: 20px;
	color: #fff;
	text-align: center;
	background-color: #337ab7;
	box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
	transition: width 0.6s ease;
	overflow: visible;
    text-shadow: 1px 1px 2px #000;
}

.progress-striped .progress-bar,
.progress-bar-striped {
	background-image: linear-gradient(
		45deg,
		rgba(255, 255, 255, 0.15) 25%,
		transparent 25%,
		transparent 50%,
		rgba(255, 255, 255, 0.15) 50%,
		rgba(255, 255, 255, 0.15) 75%,
		transparent 75%,
		transparent
	);
	background-size: 40px 40px;
}

.progress.active .progress-bar,
.progress-bar.active {
	animation: progress-bar-stripes 2s linear infinite;
}

.progress-bar-success {
	background-color: var(--green-normal);
}

.progress-striped .progress-bar-success {
	background-image: linear-gradient(
		45deg,
		rgba(255, 255, 255, 0.15) 25%,
		transparent 25%,
		transparent 50%,
		rgba(255, 255, 255, 0.15) 50%,
		rgba(255, 255, 255, 0.15) 75%,
		transparent 75%,
		transparent
	);
}

.progress-bar-info {
	background-color: #5bc0de;
}

.progress-striped .progress-bar-info {
	background-image: linear-gradient(
		45deg,
		rgba(255, 255, 255, 0.15) 25%,
		transparent 25%,
		transparent 50%,
		rgba(255, 255, 255, 0.15) 50%,
		rgba(255, 255, 255, 0.15) 75%,
		transparent 75%,
		transparent
	);
}

.progress-bar-warning {
	background-color: var(--orange-normal);
}

.progress-striped .progress-bar-warning {
	background-image: linear-gradient(
		45deg,
		rgba(255, 255, 255, 0.15) 25%,
		transparent 25%,
		transparent 50%,
		rgba(255, 255, 255, 0.15) 50%,
		rgba(255, 255, 255, 0.15) 75%,
		transparent 75%,
		transparent
	);
}

.progress-bar-danger {
	background-color: var(--red-normal);
}

.progress-striped .progress-bar-danger {
	background-image: linear-gradient(
		45deg,
		rgba(255, 255, 255, 0.15) 25%,
		transparent 25%,
		transparent 50%,
		rgba(255, 255, 255, 0.15) 50%,
		rgba(255, 255, 255, 0.15) 75%,
		transparent 75%,
		transparent
	);
}

.containsPasswordchecker {
	.progress {
		background: rgba(255, 255, 255, 0.1);
		background-image: linear-gradient(
			135deg,
			rgba(255, 255, 255, 0.1) 25%,
			rgba(0, 0, 0, 0.05) 25%,
			rgba(0, 0, 0, 0.05) 50%,
			rgba(255, 255, 255, 0.1) 50%,
			rgba(255, 255, 255, 0.1) 75%,
			rgba(0, 0, 0, 0.05) 75%,
			rgba(0, 0, 0, 0.05)
		);
		background-size: 20px 20px;
	}
}
