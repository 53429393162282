.badge {
    margin-left: unset !important;
    font-size: calc(var(--base-size)/ 1.33);
    text-decoration: none;
    border-radius: 23px 23px;
    padding: 6px 4px;
    width: auto;
    min-height: 23px;
    min-width: 23px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: calc(var(--spacing) / 4) calc(var(--spacing) / 3);

	&:focus {
		@include focus-outline;
		box-shadow: none !important;
	}

	&-primary {
		background-color: var(--catalogue-badge-bg-color);
	}

	&-danger {
		background-color: var(--red-normal);
	}

	&-success {
		background-color: var(--green-normal);
	}

	&-warning {
		background-color: var(--orange-normal);
	}

	&+.badge {
		margin-left: calc(var(--spacing) / 3) !important;
	}

	.icon.right {
		font-size: 18px;
	}
}

.badges > .form-group > .form-control-static {
	display: flex;
	gap: 10px;
    flex-wrap: wrap;

	.badge {
		margin-left: 0 !important;
	}
}