// Input placeholder style
::placeholder,
::-webkit-input-placeholder,
:-moz-placeholder,
::-moz-placeholder,
:-ms-input-placeholder {
	color: var(--input-placeholder-color) !important;
	font-style: italic;
}

.form-control {
	display: block;
	width: 100%;
	font-size: unset !important;
}

// Base 'input' tag style
input,
.bootstrap-tagsinput {

	&,
	&.form-control {
		color: var(--font-color-base);

		border-radius: var(--input-border-radius);
		border: 1px solid var(--input-border-color);
		background-color: var(--input-background-color);

		box-sizing: border-box;
		// margin-right: 6px;

		line-height: 24px;

		//### input base, focus state
		&.focus,
		&:focus {
			@include focus-border;
		}

		//### input base, disabled state
		.disabled &,
		:disabled &,
		&.disabled,
		&:disabled {
			cursor: default;
			pointer-events: none;
		}

		//### 'default' input style
		&[type='text'],
		&[type='password'],
		&[type='datetime'],
		&[type='datetime-local'],
		&[type='date'],
		&[type='month'],
		&[type='time'],
		&[type='week'],
		&[type='number'],
		&[type='email'],
		&[type='url'],
		&[type='search'],
		&[type='tel'],
		&[type='color'] {
			height: var(--input-height);

			padding: 4px 12px 5px;

			-webkit-appearance: none;
			-moz-appearance: textfield;

			.disabled &,
			:disabled &,
			&.disabled,
			&:disabled {
				color: var(--input-disabled-text-color);

				border-color: var(--input-disabled-border-color);
				box-shadow: none;

				&::placeholder,
				&::-webkit-input-placeholder,
				&:-moz-placeholder,
				&::-moz-placeholder,
				&:-ms-input-placeholder {
					color: var(--input-disabled-placeholder-text-color);
				}
			}
		}

		// the clear "X" button for text inputs on IE/Edge.
		&:-ms-clear {
			display: none;
		}

		// validation state
		.has-error &,
		&.has-error,
		&.is-invalid {

			&,
			&.hover,
			&:hover,
			&.focus,
			&:focus {
				@include input-invalid-effect(true);
				box-shadow: none;
			}

			&.focus,
			&:focus {
				@include input-invalid-focus-effect(true);
			}
		}

		// read only state for input
		&[readonly] {
			cursor: default;

			&,
			&.hover,
			&:hover,
			&.focus,
			&:focus,
			.disabled &,
			:disabled &,
			&.disabled,
			&:disabled {
				cursor: default;
				outline: 0;
				border: 0;
				box-shadow: none;
			}
		}

		// large inputs
		&.input-lg {
			height: 48px;
		}

		// small inputs
		&.input-sm {
			height: 24px;
			padding-bottom: 2px;
			padding-top: 2px;
		}

		//
		&[type='number'] {
			&.right-direction {

				&,
				.form-group & {
					padding: 7px 10px 5px 0;
				}
			}

			&.spinner {
				-moz-appearance: number-input;
			}
		}

		//
		&[type='number'],
		&[type='datetime'],
		&[type='datetime-local'],
		&[type='date'],
		&[type='month'],
		&[type='week'],
		&[type='time'] {
			&.spinner {

				&::-webkit-inner-spin-button,
				&::-webkit-outer-spin-button {
					-webkit-appearance: inner-spin-button;
				}
			}
		}

		// upload input type / file type
		&[type='file'] {
			padding-top: 0px;
			padding-left: 3px;
		}
	}
}

.bootstrap-tagsinput {
	display: flex;
	min-height: var(--input-height);
	gap: 8px;
	flex-wrap: wrap;

	.badge {
		height: calc(var(--input-height) - 9px);
		border: 0;
		margin: 0;
		display: block;
		font-size: var(--font-size-base);
		font-weight: normal;
		display: flex;
		align-items: center;
		background-color: var(--button-primary-bg-color);

		[data-role="remove"] {
			top: -6px;

			&::before {
				background-color: transparent;
			}
		}
	}

	input {
		height: calc(var(--input-height) - 9px);
	}
}