/* Tegelweergave*/
//--- eigenlijk moeten de Tiles allemaal pleite, liefst vervangen worden door cards, voor nu gedupliceerd

.Content {
	display: inline-block;
	width: 100%
}

#main_wrapper {
	margin-left: -10px;
	margin-right: -10px;

	>a {
		&:hover {
			text-decoration: none;
		}
	}
}

.Tile {
	.Profilephoto {
		float: left;
		max-width: 25%;

		width: 70px;
		height: 70px;

		&+.WrapperRight {
			float: left;
			max-width: calc(100% - 85px);

			@include media-breakpoint-down(md) {
				max-width: none;
			}

			margin-right:5px;
		}
	}
}

.scripts_manager_group_list #main_wrapper a,
.scripts_hrm_group_list #main_wrapper a {
	@include media-breakpoint-down(sm) {
		width: 100%;
	}

	@include media-breakpoint-between(md, xl) {
		width: 50%;
	}

	@include media-breakpoint-only(xxl) {
		width: 33.3333%;
	}

	@include media-breakpoint-up(xxxl) {
		width: 25%;
	}

	.Compliance {
		text-align: right;
	}

	display: inline-block;
	padding: 10px;
	vertical-align: top;

	.Tile,
	.card {
		// float: left;
		// flex-direction: unset;
		// flex-wrap: unset;
		display: block;
		width: 100%;
		min-height: 95px;
		background-color: #FFF;
		border: 1px solid #ccc;
		-webkit-box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.1);
		box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.1);
		box-sizing: border-box;
		-moz-box-sizing: border-box;
		-webkit-box-sizing: border-box;

		&:hover {
			color: #CCC;
			background-color: #F0F0F0;
		}

		.Profilephoto {
			// -webkit-box-flex: 0;
			// -ms-flex: 0 0 25%;
			// flex: 0 0 25%;
			// max-width: 25%;

			// float: left;
			// width: 70px;
			// height: 70px;
			margin: 10px;
			// border-radius:50%;
			overflow: hidden;

			.ImgCircle {
				width: 70px;
				/*height: 70px;*/
			}

			.ImgCircleNoPic {
				border-radius: 50%;
				width: 70px;
				height: 70px;
				background: #ccc;
				color: white;
				text-align: center;
				font-size: 35px;
				line-height: 70px;
				vertical-align: middle;
			}

		}

		.WrapperRight {
			// float: left;
			width: calc(100% - 100px);

			.Name {
				padding: 5px;
				padding-bottom: 1px;
				margin-right: 5px;
				border-bottom: solid #ccc;
				border-bottom-width: 1px;
				color: black;
			}

			.Function {
				padding: 5px;
				color: darkgrey;
			}

			.WrapperForTotals {
				width: 100%;
				padding: 5px;

				.Evidences,
				.EvidencesExpiring,
				.EvidencesExpired {
					float: left;
					width: 23px;
					height: 23px;
					padding: 3px 4px 6px 4px;
					margin: 0px 7px 2px 0px;
					border-radius: 50%;
					color: #fff;
					text-align: center;
					font-size: 12px;
				}

				.Evidences {
					background: var(--green-normal);
				}

				.EvidencesExpiring {
					background: var(--orange-normal);
					color: #000;
				}

				.EvidencesExpired {
					background: var(--red-normal);
				}

				.Compliance {
					float: left;
					width: 145px;
					font-size: 13px;
					margin-top: 4px;
					margin-left: 10px;
					color: darkgrey;
					float: right;
				}
			}
		}
	}
}

/* Lijstweergave */
.scripts_manager_group_list,
.scripts_hrm_group_list {
	.ListImgCircle {
		border-radius: 50%;
		width: 30px;
		height: 30px;
	}

	.ListImgCircleNoPic {
		border-radius: 50%;
		width: 30px;
		height: 30px;
		background: lightgray;
		color: white;
		text-align: center;
		font-size: 15px;
		line-height: 30px;
	}

	.ListEvidences,
	.ListEvidencesExpiring,
	.ListEvidencesExpired {
		float: left;
		width: 23px;
		height: 23px;
		padding: 4px 4px 6px 4px;
		margin: 0px 7px 2px 0px;
		border-radius: 50%;
		color: #fff;
		text-align: center;
		font-size: 12px;
	}

	.ListEvidences {
		background: var(--green-normal);
	}

	.ListEvidencesExpiring {
		background: var(--orange-normal);
	}

	.ListEvidencesExpired {
		background: var(--red-normal);
	}
}

/*** Teamoverzicht competenties ***/

.scripts_manager_group_achievements_matrix,
.scripts_report_user_group_achievements_matrix,
.scripts_admin_mastertable_project {

	.percentage.matrix_header_left_first,
	td.percentage.matrix_header_left_first {
		text-align: center;
	}

	.thOdd,
	.thOddChild {
		background-color: rgba(102, 102, 102, .06);
	}

	table .icon.isExempted {
		color: var(--font-color-base);
	}

	table .icon.isValid,
	table .isValidcertificateStatus {
		color: var(--green-normal);
	}

	table .icon.evidence_obtained {
		color: var(--green-normal);
	}

	table .icon.evidence_expiring {
		color: var(--orange-normal);
	}

	table .icon.isCritical {
		color: var(--orange-normal);
	}

	table .icon.isInvalid,
	table .isInvalidcertificateStatus {
		color: var(--red-normal);
	}

	table .icon.evidence_expired {
		color: var(--red-normal);
	}

	.flipcontainer {
		// width:1em!important;
		width: 20px !important;
		margin: 0 auto;
	}

	/*--- tijdelijk enkel voor Teamoverzicht */

	.table-striped tr.Odd {
		background-color: #fafafa;
	}
}

/*** Teamoverzicht matrix ***/
.scripts_admin_mastertable_project {
	.flipcontainer {
		margin: 0 auto;
	}
}

.Equal {
	background-color: #218AEB !important;
	color: white;
}

.Less {
	background-color: var(--red-normal) !important;
	color: white;
}

.More {
	background-color: var(--green-normal) !important;
	color: white;
}

.notApplicable {
	background-color: #424242 !important;
	color: white;
}

table.table {

	.matrix_header_left_first {
		padding-left: calc(var(--spacing) / 2);
	}

	td.function_required,
	td.function_additional_required,
	td.personal_required,
	td.project_required,
	td.project_additional_required,
	td.user_required {
		border-width: 0 0 1px 0;

		&.matrix_cell {

			text-align: center;

			a {
				text-decoration: none;
				line-height: .8;
				display: inline-block;
				transform: translateY(3px);
			}
		}
	}

	td.project_additional_required {
		border-color: #ddd;
		background-color: #fde9c5;
	}

	td.function_additional_required {
		border-color: #ddd;
		background-color: #a5c4e3;
	}

	td.function_required {
		border-color: #ddd;
		background-color: #d0e5fb;
		color: #000;
	}

	td.personal_required {
		border-color: #ddd;
		background-color: #ccc;
	}

	td.project_required {
		border-color: #ddd;
		background-color: #fabf8f;
	}

	td.matrix_header_left_first {
		span.icon-info {
			padding-left: .5em;
		}
	}
}