.checkbox,
.radio {
	input {
		+ label {
			&:before {
				outline: 0 !important;
			}
		}
	}

	label {
		position: static;
		vertical-align: baseline;

		&:before {
			margin-left: 0;
			border: none;
		}

		&:after {
			margin-left: 0;
			content: '' !important;
		}
	}
}

.form-control {
	transition: none;
}

.form-group {
	.radio,
	.checkbox {
		label {
			span {
				bottom: auto;
			}
		}
	}
}

.page-item.active .page-link {
	background: unset;
	border-color: unset;
}
.page-item.active .page-link {
	background-color: unset;
	border-color: unset;
}
span.select2-dropdown.select2-dropdown--below,
.select2-container--bootstrap-5 .select2-dropdown,
.select2-container--bootstrap-5.select2-container--focus .select2-selection,
.select2-container--bootstrap-5.select2-container--open .select2-selection {
	border-color: unset;
	box-shadow: none;
}

#mm > li.mega-menu-item > a.mega-menu-link {
	//--- li en a specificeren is zwaar en onnodig, beter zou zijn #mm>.mega-menu-item>.mega-menu-link , dat geldt over bijna alles.
	font-size: calc(var(--font-size-base) / 1.3);
	line-height: 1em !important;
}
#mm li.mega-menu-item-has-children.mega-disable-link > a.mega-menu-link {
	cursor: unset;
}

#mm
	> li.mega-menu-megamenu
	> ul.mega-sub-menu
	> li.mega-menu-item
	> a.mega-menu-link,
#mm
	> li.mega-menu-megamenu
	> ul.mega-sub-menu
	li.mega-menu-column
	> ul.mega-sub-menu
	> li.mega-menu-item
	> a.mega-menu-link {
	font-weight: normal;
}

#mm
	> li.mega-menu-megamenu
	> ul.mega-sub-menu
	> li.mega-menu-item
	> a.mega-menu-link:hover,
#mm
	> li.mega-menu-megamenu
	> ul.mega-sub-menu
	li.mega-menu-column
	> ul.mega-sub-menu
	> li.mega-menu-item
	> a.mega-menu-link:hover,
#mm
	> li.mega-menu-megamenu
	> ul.mega-sub-menu
	> li.mega-menu-item
	> a.mega-menu-link:focus,
#mm
	> li.mega-menu-megamenu
	> ul.mega-sub-menu
	li.mega-menu-column
	> ul.mega-sub-menu
	> li.mega-menu-item
	> a.mega-menu-link:focus {
	font-weight: unset;
}

.ui-widget button {
	font-family: var(--font-family-base) !important;
	font-size: var(--font-size-base) !important;
}
