.framed #Logo_top,
.framed #Header,
.framed .pageTitle {
	display: none;
}

.framed #page-wrapper {
	padding-top: 0px;
	min-height: 0px;
}

.framed #page-wrapper #Content,
.framed #page-wrapper #Content .container-fluid {
	padding: 0px;
}

.framed main h1 + .row .card {
	margin-top: 0px;
}
