// Input group text

.input-group-text {
	border: 1px solid var(--input-border-color);
	border-radius: $border-radius;
	border-color: var(--input-border-color);
	color: var(--font-color-base);
	background-color: var(--light-gray);

	&:focus-within {
		border-color: #000;
		box-shadow: inset 0 0 0 1px #000,
		shadow(20) rgba(0,0,0, 0.15) !important;
		z-index: 10;
	}

	.checkbox {
		margin: 0;
		padding-top: 0;
		padding-bottom: 0;
		line-height: 20px;

		&:focus-within {
			outline: 0;
		}

		input[type='checkbox'] + label::before {
			top: 2px;
		}

		input[type='checkbox'] + label::after {
			top: 4px;
		}
	}
}

// Input group append

.input-group-append {
	.btn {
		padding: 4px 9px;
		background-color: transparent !important;
		color: var(--font-color-base) !important;

		border-top-right-radius: var(--input-border-radius);
		border-bottom-right-radius: var(--input-border-radius);

		border-top: 1px solid var(--input-border-color);
		border-bottom: 1px solid var(--input-border-color);
		border-right: 1px solid var(--input-border-color);
		border-color: var(--input-border-color) !important;

		&:hover {
			background-color: transparent !important;
			color: var(--font-color-base);

			border-top: 1px solid var(--input-border-color);
			border-bottom: 1px solid var(--input-border-color);
			border-right: 1px solid var(--input-border-color);
		}

		&:focus, &:not(:disabled):not(.disabled):active {
			background-color: transparent;
			color: var(--font-color-base);
			border: 1px solid #000;
			box-shadow: var(--shadow);
		}

		&:before {
			display: none;
		}

		&:after {
			display: none;
		}

		& + .btn {
			border-left: 1px solid rgba(255, 255, 255, 0.2) !important;
		}
	}
}

.input-group-prepend {
	.btn {
		border-top-left-radius: var(--input-border-radius);
		border-bottom-left-radius: var(--input-border-radius);
	}
}

.input-group {
    .select2-container {
        width: auto!important;
    }
}