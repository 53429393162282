
.grid {
	&Wrapper {
		display: grid;
		grid-template-columns: var(--content-item-grid);
		grid-gap: 30px;
		margin: 0;
	}

	&Item {
		box-shadow: var(--shadow);
		border-radius: 8px;
		border: 0;

		&:hover {
			cursor: pointer;
			box-shadow: var(--shadow-hover);
			transition: .2s ease-in-out;
		}
	
		&.active {
			background-color: #eee;
			box-shadow: var(--shadow-hover);
		}
	}
}

/*------------Table--------------------*/

th,
.rt-th > div {
	font-weight: bold;
	height: 70px;
}

th.currency,
th.integer,
th.float,
td.right,
td.Right {
	text-align: right;
}

td[onclick] {
	cursor: pointer;
	&:hover,
	&:focus {
		background-color: var(--select-hover-background-color);
	}
}

th,
.rt-th {
	color: #000;
	-webkit-font-smoothing: unset !important;
	-moz-osx-font-smoothing: grayscale;
}

.table-striped > thead > tr > th,
.table-striped > tbody > tr > th,
.table-striped > tfoot > tr > th,
.table-striped > thead > tr > td,
.table-striped > tbody > tr > td,
.table-striped > tfoot > tr > td,
.table-bordered > thead > tr > th,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > tbody > tr > td,
.table-bordered > tfoot > tr > td,
.rt-th {
	border-left: none;
	border-right: none;
	border-bottom: 1px solid #ddd;
	vertical-align: middle;
}

.table-striped > thead > tr > th,
.table-striped > tbody > tr > th,
.table-striped > tfoot > tr > th {
	// min-width: 90px;
}

#table-responsive-tra th {
	vertical-align: top !important;
	white-space: nowrap;
}

#table-responsive-tra {
	select {
		width: max-content;
		max-width: 10vw;
		min-width: 5vw;
	}

	.select2-selection__choice {
		text-wrap: wrap;
		min-width: 20vw;
	}

	.select2-selection--single {
		padding-right: 3.5rem;
		max-width: 15vw;
	}

	.select2-selection--multiple {
		padding-right: 54px;
		max-width: 30vw;
		width: auto!important;
	}
}

.floatThead-container {
	background: white;
	z-index: 99 !important;
	border-bottom: 1px solid var(--dark-gray);

	& > .table-bordered {
		& > thead > tr > th {
			border: 0 !important;
		}
	}
}

.table-striped {
	a:hover {
		text-decoration: none;
	}
}

.table-bordered {
	.date {
		width: 165px;

		&.thEven,
		&.thOdd {
			width: auto;
		}
	}

	.number {
		width: calc(1rem * 10);
	}

	.validity {
		width: 115px;
	}

	.compliancy {
		width: 45px;
	}

	.session_occupation {
		min-width: 110px;
    	width: 110px;

		span {
			float: right;
			padding: 0px !important;

			.icon {
				margin-left: 5px;
			}
		}
	}
}

.table > caption + thead > tr:first-child > th,
.table > colgroup + thead > tr:first-child > th,
.table > thead:first-child > tr:first-child > th,
.table > caption + thead > tr:first-child > td,
.table > colgroup + thead > tr:first-child > td,
.table > thead:first-child > tr:first-child > td {
	border-top: 0;
	border-right: 0;
}

//--- answer overview table
// .table{
// 	.AnswerTable{
// 		width:100%;
// 	}
// 	.Question{
// 		.Description{
// 			@extend .h4;
// 			margin-bottom:8px;
// 		}
// 	}
// 	.Answers{
// 		.FormElement{
// 			padding-right:8px;
// 		}
// 	}
// }
.AnswerTable {
	.radio {
		margin-right: 0;
		padding-left: 12px;
	}

	.Answer {
		vertical-align: middle;
		padding-left: 0.5rem;
	}
}

.datarow {
	&.info {
		background-color: #ddd !important;
	}

	.icon {
		&.icon-check {
			color: var(--green-normal);
		}

		&.icon-alarm,
		&.icon-check.expiring,
		&.icon-check.isCritical,
		&.icon-check.isCriticalcertificateStatus {
			color: var(--orange-normal);
		}

		&.icon-cancel-square, &.icon-cross, &.icon-cross2, &.icon-clear  {
			color: var(--red-normal);
		}

		a:hover {
			text-decoration: none;
		}

		.input-group .icon,
		.modal-body .icon {
			color: inherit !important;
		}
	}
}

//--- /answer overview table
.containsBootstrapGrid {
	.table-responsive {
		table {
			tbody {
				tr {
					td {
						&.text {
							span.icon {
								margin-left: 1rem;
							}
						}
					}
				}
			}
		}
	}
}

/*--- CK: page specific for Inschrijving training (/training/training_signup_edit.aspx) */
html.scripts_admin_training_training_signup_edit
	table.table-bordered.table-striped
	input.radio {
	display: inline-block;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	/* IE10+ CSS styles go here */
	html.scripts_admin_training_training_signup_edit
		table.table-bordered.table-striped
		input.radio {
		margin-top: 30px;
	}
}

.table-striped > tbody > tr:nth-of-type(Even) {
	background-color: white;
}

.table th.button,
table th.gridcheckbox,
.table td.icononly,
.table td.Icon {
	// min-width: 38px;
	padding: 3px;
	text-align: center;
}

td.icononly {
	.btn-primary {
		padding: 5px;
	}
}

td.Center {
	input[type='radio'],
	input[type='checkbox'] {
		margin: 0 auto;
		vertical-align: middle;
		display: inline-block;
	}
}

table.datelist {
	td {
		width: 10rem;

		&.city {
			width: 12rem;
		}

		&.location {
			width: 20rem;
		}
	}
}

.StickyTableWrapper {
	position: relative;
}

.StickyTableScroller {
	margin-left: 221px;
	width: calc(100% - 221px);
	overflow-x: auto;
	overflow-y: visible;
	padding-bottom: 5px;
}

.Grid.StickyTable .Even {
	background-color: #fff;
}

.Grid.StickyTable .Odd {
	background-color: #f5f5f5;
}

.Grid.StickyTable {
	border: none;
	// border-right: solid 1px #DDEFEF;
	border-collapse: separate;
	border-spacing: 0;
	font: normal 13px Arial, sans-serif;

	td {
		height: 51px;
		padding-left: 5px;
		padding-right: 5px;
	}

	th {
		padding-left: 5px;
		padding-right: 5px;
		height: 40px;
	}

	td.StickyColumn1,
	th.StickyColumn1 {
		height: 51px;
		padding: 5px;
		// border-left: solid 1px #DDEFEF;
		// border-right: solid 1px #DDEFEF;
		left: 0;
		position: absolute;
		top: auto;
		width: 220px;
		vertical-align: middle;
		white-space: pre-wrap;
	}

	.Odd {
		.StickyColumn1 {
			background-color: #f5f5f5;
		}
	}

	.Even {
		.StickyColumn1 {
			background-color: white;
		}
	}

	.input-group-md {
		min-width: 105px;
	}

	& > tbody > tr:first-child + tr {
		display: none;
	}
}

.sortable .icon.sort {
	display: none;
}

.sortable.asc .icon,
.sortable.desc .icon {
	display: initial;
}

.table th.sortable span.icon {
	float: none !important;
	position: absolute;

	&:before {
		top: -6px !important;
	}
}

/** Extra voor 5 kolommen **/
.col-xs-5ths,
.col-sm-5ths,
.col-md-5ths,
.col-lg-5ths {
	position: relative;
	min-height: 1px;
	padding-right: 15px;
	padding-left: 15px;
}

.col-xs-5ths {
	width: 20%;
	float: left;
}

@media (min-width: 768px) {
	.col-sm-5ths {
		width: 20%;
		float: left;
	}
}

@media (min-width: 992px) {
	.col-md-5ths {
		width: 20%;
		float: left;
	}

	.modal-lg {
		width: 1032px;
	}
}

@media (min-width: 1200px) {
	.col-lg-5ths {
		width: 20%;
		float: left;
	}
}

.gridComponent {
	margin-left: 0;

	&.gridExport .dropdown-toggle::after {
		// overwrite _default.min.css, redundant to r.22 of this file, same styling
		float: none;
		margin-top: 0;
	}
	.dropdown.disabled,
	[disabled] {
		display: none; // PPDEV-5666 & PPDEV-5657 tijdelijke hack, WB haalt via LCMS-1180 onnodige drid-listboxes weg
	}
}

@media (max-width: 767.98px) {
	.row.gridComponents {
		// display:flow-root;

		.buttons {
			float: none;
			max-width: 100%;
		}
	}
}

@media (min-width: 768px) {
	.row.gridComponents {
		display: flow-root;
		justify-content: flex-end;

		.buttons {
			float: right;
		}
	}
}
