/*--- used on pages like:
/scripts/admin/training/trainingcost_overview.aspx?entry_params=&rul_id=46847&tra_id=31082&exa_id=1072250
*/
.selected_department_odd {
	background-color: #91C9F2 !important;
}
.selected_department_even {
	background-color: #89C3ED !important;
}
.selected_user_odd {
	background-color: #F9F45C !important;
}
.selected_user_even {
	background-color: #F4EF55 !important;
}
.selected_error_odd {
	background-color: #F00000 !important;
}
.selected_error_even {
	background-color: #D00000 !important;
}
.number_positive {
	font-weight: bold;
	color:#00AA00;
}
.number_negative {
	font-weight: bold;
	color:#AA0000;
}