#Content {
	.results {
		h3 {
			border-bottom: 1px solid #ccc;
			padding: 15px 0px;
			width: 100%;
			margin: 0 15px 15px 15px;
		}

		>.tile,
		.row>.tile {
			@include media-breakpoint-only(xl) {
				@include make-col(4);
			}
		}
	}

	.card {
		.card-body {

			>.left,
			>.right {
				float: left;
				width: 50%;
			}

			.profilepicture,
			.profile_picture {
				max-width: 150px;
				overflow: hidden;
				background-size: cover;
				border-radius: 100%;
				aspect-ratio: 1 / 1;
			}

			.profilepicture {
				margin: 0 auto;
			}

			.picture {
				float: left;
				border-radius: 100%;

				img {
					border-radius: 100%;
				}
			}

			.row_wrapper {
				clear: both;

				.name {
					width: 35%;
					font-weight: bold;
					display: block;
					float: left;

					&+span.value {
						float: left;
						max-width: 64.9%;
						display: block;
						padding-bottom: 0.5em;
					}
				}
			}

			.filters {
				.mode {
					.radio {
						margin-bottom: 7px;

						&:first-child {
							margin-top: 8px;
						}
					}
				}
			}

			.details {
				.FilterIcon {
					margin-right: 0.5rem;
				}

				span {
					float: left;

					&.category {
						width: 80%;
						text-align: left;
						line-height: 2;
					}

					&.category_compliance {
						width: 20%;
						text-align: right;
						margin: 0px;
						display: inline-block;

						.badge {
							margin-top: 2px;
						}
					}

					&.compliant {
						// @include media-breakpoint-down(xxl) {
						display: none;
						// }
					}

					&.label {
						font-weight: bold;
						min-width: 90px;
					}
				}
			}
		}

		&.usercertificates {

			.logo,
			.Logo {
				&:before {
					@extend .logo_pseudo;
					content: '\e925';
				}
			}
		}

		&.usercompliancy_knowledge_skills {

			.logo,
			.Logo {
				&:before {
					@extend .logo_pseudo;
					content: '\e925';
				}
			}
		}

		&.user_knowledge_and_skills {

			.logo,
			.Logo {
				&:before {
					@extend .logo_pseudo;
					content: '\e91f';
				}
			}
		}
	}
}

.scripts_hrm_user_edit {
	.profile_picture {
		top: 0;
		display: none;
	}
}

@media screen and (max-width: 1199px) {
	.profile_picture {
		position: relative;
	}
}

#usr_image,
div.profilepicture.initials>div {
	background-color: white;
	width: 100%;
	max-width: 100%;
	height: auto;
}

div.profilepicture.image {
	max-height: 150px;
	background-repeat: no-repeat;
	background-position-x: center;
}

div.profilepicture.initials,
div.profilepicture.initials>div {
	max-height: 150px;
	width: 150px;
	line-height: 150px;
	border-radius: 50%;
	font-size: 5rem;
	text-align: center;
}

#mm>li.mega-menu-item.picture>.mega-menu-link .profilepicture {
	background-size: cover !important;
}

div.profilepicture.image>div {
	height: 150px;
	background: rgba(0, 0, 0, 0);

	>img {
		display: none;
	}
}

#usr_image {
	max-height: 150px;
	width: auto;
	margin: 0 auto;
}

.scripts_hrm_user_edit {
	.profile_picture {
		text-align: right;

		#usr_image {
			min-height: 0;
			height: auto;
		}
	}
}

.scripts_admin_user_edit .accordion-content .form-control-static .profilepicture {
	@include media-breakpoint-up(lg) {
		position: absolute;
		width: 100%;
		text-align: center;
	}

	&>div {
		margin-left: 0 !important;
	}
}

.outdated {
	color: #ccc;
}

.BlockDetails {
    display: flex;
    width: 100%;
}

/**** competentieniveau's ****/

.table_level {
	width: 99%;
	max-width: 99%;
	height: 26px;
}

.level_adequate_normal {
	padding: 0px !important;
	background-color: var(--green-light) !important;
	border: 1px solid #b1b1b1;
}

.level_adequate_highlighted {
	padding: 0px !important;
	background-color: var(--green-normal) !important;
	color: var(--green-normal) !important;
	border: 1px solid var(--green-normal);
	text-align: center;
}

.level_inadequate_normal {
	padding: 0px !important;
	background-color: #ccc !important;
	border: 1px solid #b1b1b1;
}

.level_inadequate_highlighted {
	padding: 0px !important;
	background-color: var(--red-normal) !important;
	color: var(--red-normal) !important;
	border: 1px solid var(--red-normal) !important;
	text-align: center;
}

.level_neutral_normal {
	padding: 0px !important;
	background-color: var(--green-light) !important;
	color: var(--green-light) !important;
	border: 1px solid var(--green-light);
}

.level_neutral_highlighted {
	padding: 0px !important;
	background-color: var(--green-normal) !important;
	color: var(--green-normal) !important;
	border: 1px solid var(--green-normal);
	text-align: center;
}

.level_adequate_highlighted:before,
.level_adequate_highlighted:after,
.level_neutral_highlighted:before,
.level_neutral_highlighted:after,
.level_inadequate_highlighted:before,
.level_inadequate_highlighted:after {
	top: 80%;
	left: 50%;
	border: solid transparent;
	content: ' ';
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}

.level_adequate_highlighted:before,
.level_inadequate_highlighted:before,
.level_neutral_highlighted:before {
	/* border-color: transparent; */
	border-bottom-color: #666;
	border-width: 5px;
	margin-left: -5px;
}

.level_adequate_highlighted:after,
.level_inadequate_highlighted:after,
.level_neutral_highlighted:after {
	border-color: rgba(255, 255, 255, 0);
	border-bottom-color: #666;
	border-width: 5px;
	margin-left: -5px;
}

.level_adequate_highlighted,
.level_neutral_highlighted,
.level_inadequate_highlighted {
	position: relative;
}

form#info>div:last-child>div>div {
	border-top: 1px solid #ccc;
}

.scripts_user_profile.cpl .header .btn-group {
	display: block;
}

.scripts_user_profile.cpl,
.scripts_manager_user_details.cpl,
.scripts_hrm_user_details.cpl,
.scripts_user_profile.fnc,
.scripts_manager_user_details.fnc,
.scripts_hrm_user_details.fnc {

	td.text.name,
	th.text.name {
		min-width: 30%;
		width: 30%;
	}
}

.scripts_user_profile.com,
.scripts_manager_user_details.com,
.scripts_hrm_user_details.com {
	.table {
		td:nth-last-child(3) {
			width: 240px;
		}

		td:nth-last-child(2) {
			width: 140px;
		}

		td:nth-last-child(1) {
			width: 140px;
		}
	}
}

.scripts_user_training.req,
.scripts_manager_user_details.req,
.scripts_hrm_user_details.req,
.scripts_user_profile.req {

	#tablereq,
	#tablertr,
	#tablewtr {
		th:nth-last-child(1) {
			width: 270px !important;
		}

		tbody>tr>td:nth-last-child(1) {
			//text-align: right;

			&.text {
				text-align: left;
			}
		}
	}

	tbody>tr>td:only-child {
		text-align: left !important;
	}
}

.scripts_manager_user_details.tra,
.scripts_hrm_user_details.tra {
	td:nth-last-child(1) {
		width: 140px;
	}
}

.scripts_user_profile.frm,
.scripts_manager_user_details.frm,
.scripts_hrm_user_details.frm {
	table.table-striped {
		// td:nth-last-child(1) {
		// 	width: 40px;
		// }

		td:nth-last-child(2) {
			width: 180px;
		}

		td:nth-child(5) {
			width: 350px;
		}
	}

	#frmGridfrs_4 {
		td:nth-child(5) {
			width: 550px;
		}
	}
}

/** Gebruiker bewerken **/
.scripts_admin_user_edit {
	.accordion-content {
		.form-control-static {
			div {
				margin-left: 25% !important;

				img {
					height: 180px;
					border-radius: 50%;
				}
			}
		}
	}
}

.scripts_user_training_details.cmd {
	td:nth-last-child(1) {
		width: 50%;
	}
}

#usr_info {
	height: 65px;
}

.scripts_user_answer_overview {
	.table-striped>tbody>tr {
		background-color: transparent;

		&>td {
			padding: 2rem 0.5rem;
		}
	}
}