@use 'sass:math';

$circleHeight: 40px;
$lineHeight: $circleHeight - 10;
$fontLevel: math.div($circleHeight, 3);

/*----------------card----------------*/
// .usercompliancy_knowledge_skills>.card-collapse,
// .usercompliancycertificates>.card-collapse,
// .projectcompliancycertificates>.card-collapse {
// 		position:relative;
// }

// .usercompliancy_knowledge_skills .card-collapse{
// 	position: relative
// }

.usercompliancy_knowledge_skills .card-body,
.usercompliancycertificates .card-body,
.projectcompliancycertificates .card-body {
	position: relative;
}

.card-collapse {
	//--- maakt bovenstaande hopelijk helemaal overbodig
	position: relative;
}

span[class^='evidence']>.badge {
	color: #fff;
}

// .card.containsPanel {
// 	border: 0;
// 	background: transparent;

// 	& > .card-header {
// 		margin-top: 1rem;
// 		margin-bottom: 1rem;
// 		padding-left: 0;
// 		padding-right: 0;
// 		background-color: transparent;
// 		border: 0;

// 		h4 {
// 			font-size: 2rem;
// 		}

// 		& > .card-collapse > .card-body {
// 			padding: 0;
// 		}
// 	}
// }

.card.containsPanel>.card-collapse>.card-body {
	padding: 0;
}

.CategoryItemWrapper .Header {
	border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
}

a.card {
	text-decoration: none;
	color: var(--font-color-base);

	&:hover {
		color: inherit;
	}
}

.card {
	border-radius: var(--card-border-radius);
	border: 0;
	word-wrap: initial;

	box-shadow: var(--shadow);

	h2.card-title {
		@extend .h2;
		margin: 0;

		.badge {
			position: relative;
			top: -2px;
		}
	}

	&-body {
		padding: var(--spacing);
	}

	&-header {
		border-color: #e5e9f2;
		border-bottom: 0; //--- in kleinere cards altijd border-bottom 1
		background: none;
		padding: var(--spacing) var(--spacing) 0 var(--spacing);
		text-decoration: none;
		color: var(--font-color-base);

		&:hover {
			color: inherit;
		}

		&[data-toggle='collapse'] {
			cursor: pointer;
		}

		.card-title {
			margin-bottom: 0;
		}

		.btn-toolbar {
			margin-top: 0;
		}
	}

	&-secondary {
		margin-top: 1rem;
		margin-bottom: 1rem;
	}

	&-profilepicture {
		box-shadow: none;
		background: transparent;

		.card-body {
			padding: 0;
		}
	}

	&-footer {
		min-height: 41px;
		padding: $card-spacer-x;
		border-top: 0;
		background-color: transparent;

		&:last-child {
			@include border-bottom-radius($border-radius);
		}
	}

	&.collapsable {
		.card-header {
			padding-bottom: var(--spacing);

			.icon {
				position: absolute;
				right: $card-spacer-x;
				float: none;
				top: 26px;
			}
		}

		.table {
			.icon {
				font-size: var(--icon-font-size);
			}
		}
	}

	&.CategoryItemWrapper {
		border-style: solid;
		border-width: 1px;

		.card-header,
		.Header {
			border-bottom: 1px solid rgba(0, 0, 0, 0.125);
		}
	}

	// &.containsButtonToolbar{
	// 	background:none;
	// 	border:0;
	// 	.card-body{
	// 		padding-left:0;
	// 		padding-right:0;
	// 	}
	// }
	&.containsButtonToolbar,
	&.containsBootstrapGrid,
	&.containsBootstrapForm {
		.card-header {
			border-bottom: 0;
		}

		&.collapsable {
			.card-header {
				padding-bottom: var(--spacing);
			}
		}
	}

	div[class^='header col-'] {
		margin-left: 0px;
	}

	&.card-default {
		.card-header {
			padding: 0;
			margin: 0px;
			background: white;
		}

		.card-body {
			padding: 15px 0;

			fieldset {
				&>legend {
					@extend .h3;
					float: left;
					border: unset;
					padding-bottom: unset;
					margin-bottom: unset;
					margin-top: unset;
				}
			}
		}
	}

	&.filters {
		.header {
			margin-left: 0px;
			margin-right: 0px;
		}
	}

	.logo,
	.Logo {
		@include circle(80px);
		margin: 0px;
		background: var(--dark-gray);

		&+.details {
			.row.wrapper {
				height: 27px;
				line-height: 27px;
				min-height: 27px;
			}
		}
	}

	.total_evidences {
		padding-bottom: 15px;
		display: none;
	}

	.total_competences {
		display: none;
	}

	.details {
		.row_wrapper {
			.Filteritem {
				img {
					vertical-align: middle !important;
				}
			}

			.FilterIcon {
				float: left;
				line-height: 27px;

				.icon {
					line-height: 27px;
				}
			}

			[class^='evidences'] {
				padding-top: 4px;
			}

			.evidences {
				// margin: 0 3px 0 10px ;
				margin-right: 3px;
				min-width: 30px;
				text-align: left;

				&:before {
					background: var(--green-normal);
				}
			}

			.evidencesExpiring,
			.evidencesexpiring {
				// margin: 0 3px 0 10px ;
				margin-right: 3px;
				min-width: 30px;
				text-align: left;

				&:before {
					background: #ffc107;
					;
				}
			}

			.evidencesExpired,
			.evidencesexpired {
				// margin: 0 3px 0 10px ;
				margin-right: 3px;
				min-width: 30px;
				text-align: left;

				&:before {
					background: var(--red-normal);
				}
			}
		}
	}

	&.usercompliancycertificates,
	&.usercompliancy_knowledge_skills,
	&.userdata,
	&.usercertificates,
	&.user_knowledge_and_skills,
	&.projectcompliancycertificates {
		min-height: 180px;

		&.tile {
			&:hover {
				cursor: pointer;
				box-shadow: var(--shadow-hover);
				transition: .2s ease-in-out;
			}
		}

		&.containsButtonToolbar {
			border: none;
			padding: 0px;
			box-shadow: none;
			margin: 0px -15px;
			min-height: 0;
		}

		.card-header {
			padding: 15px 0;
			border-bottom: 1px solid #ccc;
			margin: 0px 15px;

			.icons {
				float: right;

				.icon {
					margin-left: 15px;
				}
			}
		}

		.card-body {
			padding: 15px;

			.compliance {
				font-size: 0px;
				width: 80px;
				height: 80px;
				float: left;

				&>div {
					font-size: 1.3rem;
					position: absolute;
					line-height: 80px;
					text-align: center;
					width: 80px;
				}

				&+.details {
					&>div {
						&[class=''] {
							//--- no category, so don't show
							display: none;
						}
					}
				}
			}

			.details {
				.label {
					color: var(--font-color-base);
					font-size: $font-size-base;
					font-weight: normal;
				}

				// .Competenties{
				.category_compliance {
					float: left;
					margin-right: 0;
				}

				// }
			}

			.status {
				width: 80px;
				height: 80px;
				float: left;

				span[class^='icon'] {
					@extend .tile_chartcircle;

					&:before {
						color: white;
						line-height: 60px;
						font-size: 2.5rem;
					}
				}
			}

			div[id^='chart'] {
				position: absolute;
				top: 70px;

				&+.details {
					@include media-breakpoint-up(lg) {
						padding-left: 95px; //--- TEMP HACK: hotfix wanneer er geen "compliancy"-div wordt meegegeven, waardoor er geen percentage wordt getoond
					}
				}
			}

			.Level {
				@include circle($circleHeight);
				background: var(--competence-level-grade-bg-color);

				span {
					line-height: $lineHeight;
					display: block;
					margin: 0 auto;
					text-align: center;
					font-size: $fontLevel;
					// font-family: Verdana, Geneva, sans-serif;
				}
			}

			td[class^='level_'] {
				padding: 0px !important;
				background-repeat: no-repeat;
				border: 1px solid #ccc;
			}

			td[class$='normal'] {
				background: #f0f0f0;
			}

			td[class$='highlighted'] {
				background: #a6c939;
				position: relative;

				&:before,
				&:after {
					top: 90%;
					left: 50%;
					border-style: solid;
					border-top-color: transparent;
					border-left-color: transparent;
					border-right-color: transparent;
					content: ' ';
					height: 0;
					width: 0;
					position: absolute;
					pointer-events: none;
					border-bottom-color: #666;
					border-width: 5px;
					margin-left: -5px;
				}
			}

			.LevelBar {
				display: inline-block;
				width: 80%;

				table.table_level {
					width: 100%;

					td {
						text-align: center;
					}
				}
			}
		}
	}

	&[onclick^='window'] {
		cursor: pointer;

		&:not(.CategoryItem):hover {
			background-color: #f5f5f5
		}

		&:hover>div {

			.card-header,
			.card-footer,
			.Training {
				background-color: #f5f5f5 !important; //--- overrule inline styling
			}

			.Training {
				border-bottom-left-radius: $card-border-radius;
				border-bottom-right-radius: $card-border-radius;
			}
		}

		&.active {
			background-color: #f5f5f5 !important; //--- overrule inline styling

			.card-header {
				background-color: #f5f5f5 !important; //--- overrule inline styling
			}
		}
	}

	&.tile.Folder {
		cursor: pointer;

		&:hover {
			background-color: #f5f5f5;

			.card-header,
			.card-footer {
				background-color: #f5f5f5 !important; //--- overrule inline styling
			}
		}

		&.active {
			background-color: #f5f5f5 !important; //--- overrule inline styling

			.card-header {
				background-color: #f5f5f5 !important; //--- overrule inline styling
			}
		}
	}

	&.BlockDetails {
		display: flex;
		width: 100%;
	}

	&.BlockDetails.Tile {
		border: 1px solid #ccc;
		min-height: 0px;
		width: auto;
		float: none;

		&:hover {
			color: var(--font-color-base);
		}

		&.evidence_expired {
			.status>span>span {
				border: 15px solid var(--red-normal);
				background: var(--red-normal);
			}

			.card-footer {
				display: none;
			}
		}

		&.evidence_expiring {
			.status>span>span {
				border: 15px solid #ffc107;
				;
				background: #ffc107;
				;
			}

			.card-footer {
				display: none;
			}
		}

		&.evidence_obtained {
			.status>span>span {
				border: 15px solid var(--green-normal);
				background: var(--green-normal);
			}

			.card-footer {
				display: none;
			}
		}

		&.evidence_approval_required {
			.status>span>span {
				border: 15px solid var(--orange-normal);
				background: var(--orange-normal);
			}

			.card-footer {
				display: none;
			}
		}

		.pull-left {
			width: 100%;

			h4 {
				.icons {
					float: right;
				}
			}
		}
	}

	&[class$='_required'] {
		min-height: 160px;
	}

	&#training_details {
		fieldset {
			margin-bottom: 30px;
		}
	}
}

.card.Disabled {
	//--- duplicaat van definities in E-learning.scss, die op zijn beurt ook kan ruimen voor Catalogue.scss, wanneer alles gelijk wordt getrokken.
	opacity: 0.5;
	cursor: not-allowed;

	div {
		cursor: not-allowed;
	}
}

//--- toegevoegd sinds bs4
.card-body,
#TrainingWrapper {
	@extend .clearfix;
}

//--- /toegevoegd sinds bs4