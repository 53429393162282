#CategoryWrapper {
  @include make-row();

  .CategoryItem {
    //--- moet gelijkgetrokken worden met gelijkwaardige "cards" die geen card zijn...
    @extend .card;

    @include media-breakpoint-down(xs) {
      @include make-col(12);
    }

    @include media-breakpoint-only(sm) {
      @include make-col(6);
    }

    @include media-breakpoint-only(md) {
      @include make-col(4);
    }

    @include media-breakpoint-only(lg) {
      @include make-col(4);
    }

    @include media-breakpoint-only(xl) {
      @include make-col(3);
    }

    @include media-breakpoint-up(xxl) {
      @include make-col(2);
    }

    border: 0;

    .CategoryItemWrapper {
      border: 1px solid rgba(0, 0, 0, 0.125);
      margin: 15px;
      cursor: pointer;
      position: relative;
      border-radius: 0.4rem;
      overflow: hidden;
    }

    .Header {
      @extend .card-header;
      border-bottom: 1px solid rgba(0, 0, 0, 0.125);
      border-top-left-radius: 0.3rem;
      border-top-right-radius: 0.3rem;
      background: rgba(255, 255, 255, 0.85);
      color: #484848;
      padding: 1rem;
      z-index: 2;
      max-height: 6rem;

      .icon {
        position: absolute;
        right: 10px;
        top: 19px;
      }
    }

    .Image,
    .NoImage {
      @extend .card-body;
      background-size: cover;
      background-position: 50%;
      min-height: 15rem;
    }

    .Training {
      ul {
        padding-left: 0;
        margin-bottom: 0;
        list-style-type: none;
        min-height: 10.5rem;
        padding: 1rem;

        li {
          padding-bottom: 5px;

          &.Populair {
            font-weight: bold;
            border-bottom: 1px solid #ccc;
            margin-bottom: .5rem;
            color: #484848;
          }
        }
      }
    }
  }
}

.scripts_admin_friends_manage_friend #CategoryWrapper .CategoryItem .Image,
.scripts_admin_friends_manage_friend #CategoryWrapper .CategoryItem .NoImage {
  height: initial;
  min-height: 150px;
}

#CatalogueCategory {
  #SearchboxWrapper {
    width: 50%;

    .searchbox {
      float: left;
      width: 50%;
      box-sizing: border-box;

      input {
        display: block;
        width: 100%;
        height: 34px;
        padding: 6px 12px;
        font-size: 14px;
        line-height: 1.42857143;
        color: #555;
        background-color: #fff;
        background-image: none;
        border: 1px solid #ccc;
      }
    }

    .searchbutton.ButtonStrip {
      width: 50%;
      float: left;
      padding-left: 10px;
      box-sizing: border-box;
    }
  }

  .InstructionText {
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-left: 0px;
    padding-right: 0px;

    h1 {
      font-size: 1.45rem;
      font-weight: bold;
      width: 100%;
    }
  }

  .BackButton.ButtonStrip {
    margin-bottom: 1rem;
    width: 100%;
    margin-top: 1rem;
  }
}

#CategoryWrapper {
  .Header {
    .Text {
      max-width: 90%;
      // text-overflow: ellipsis;
      // word-wrap: break-word;
      // white-space: nowrap;
      // overflow: hidden;
      min-height: 3rem;
      font-size: 1.1rem;
    }
  }

  .Training li {
    font-size: 1.1rem;
    text-overflow: ellipsis;
    word-wrap: break-word;
    white-space: nowrap;
    overflow: hidden;
    min-height: 1.45rem;
  }
}

.scripts_user_trainingregistration_catalogue {
  #CatalogueCategory .button_text a {
    cursor: pointer;
    color: white;
    padding: 10px;
    background-color: #444;
    border-radius: 0.4rem;

    &:hover {
      text-decoration: none;
    }
  }
}

.scripts_user_trainingregistration_training_signup_edit {
  .SignUpButton {
    margin-top: 10px;
  }

  .btn-group {
    .btn {
      margin-right: 10px;
    }
  }

  .module {
    font-weight: bold;
    padding-top: 10px;
  }
}

.CatalogueFilterlistWrapper {
  overflow: hidden;

  .SearchOption {
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 10px;

    .SearchOptionValue {
      input[type="checkbox"] {
        float: left;
      }
    }
  }
}

/** Hoogte binnen een categorie **/
#CatalogueCategory .NoImage,
#CatalogueCategory .Training {
  display: none;
}

/* Marge inschrijven button binnen card */
#training_signup .card-body>.btn {
  margin-right: 10px;
  margin-top: 10px;
}

.CourseProgram {
  display: none !important;
}