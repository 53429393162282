.GlobalAlert {
	margin-top: 2rem;
	& + #startpage_content .Logger {
		position: relative;
	}
}

.MenuVertical .GlobalAlert {
	margin-top: 6rem;
}

.scripts_user_general_text .GlobalAlert {
	margin-top: 0;
}
