#Catalogue {
	display: flex;

	&Filter {
		max-width: 420px;
		width: auto;

		// @include make-col-ready();

		@include media-breakpoint-down(md) {
			max-width: 300px;
		}

		@include media-breakpoint-down(sm) {
			width: 100%;
			max-width: none;
		}

		// @include media-breakpoint-up(lg) {
		// 	@include make-col(4);
		// }

		// @include media-breakpoint-up(xxl) {
		// 	@include make-col(3);
		// }

		&Category {
			.form-group {
				margin-bottom: 0;
			}

			.icon {
				@include circle-icon(3rem);
			}
		}

		#objFormFilterSearch {
			.containsText {
				padding-top: 0;
			}

			.containsInputGroup {
				margin-bottom: 0;
			}
		}

		#ResetButton {
			display: flex;
			margin-top: 12px;
			background: transparent;
			color: var(--font-color-base);
			font-size: 1rem;
			position: absolute;
			top: 25px;
			left: 15px;
			padding: 0;
			border-radius: 0;

			&:before,
			&:after {
				display: none;
			}

			button {
				margin-right: 0;
			}

			.icon {
				order: 1;
				margin-left: calc(var(--spacing) / 2);
			}
		}

		.accordion {
			&-header {
				color: var(--font-color-base);
				padding: 0.7rem var(--spacing);
				background-color: transparent !important;
				width: calc(100% + var(--spacing) * 2);
				max-width: none;
				margin-left: calc(-1 * var(--spacing));
				border-bottom: 1px solid #ABABAB;
				font-weight: 600;

				&:hover {
					background-color: #e1e1e1;
				}

				.badge {
					float: right;
					margin-right: 2.4rem;
				}
			}

			&-item {
				margin-top: 0;
				margin-bottom: 0;

				&:last-child {
					legend {
						margin-bottom: 0;
					}
				}

				&.active {
					.accordion-header {

						// background-color: #e1e1e1;
						&:hover {
							background-color: #eee;
						}
					}
				}
			}

			&-content {
				padding-top: 0.8rem;
			}

			.form-group.containsCheckboxGroup legend {
				position: relative;
			}

			.checkbox {
				margin: 8px 0 8px 5px;

				input {
					&:checked+label {
						font-weight: bold;
					}
				}

				label {
					&:before {
						position: absolute;
					}

					span {
						display: inline-block;
					}
				}
			}

			.morelesslink {
				font-weight: bold;
				margin-left: 1rem;
				margin-top: 0.6rem;

				.link {
					padding-left: 1rem;
				}
			}
		}

		.irs--flat .irs-from,
		.irs--flat .irs-to {
			background-color: var(--font-color-base);
		}

		.irs--flat .irs-from:before,
		.irs--flat .irs-single:before,
		.irs--flat .irs-to:before {
			border-top: 0;
		}
	}

	&Back {
		@include make-col-ready();

		.btn-toolbar {
			margin-top: 0;
		}
	}

	&Results {
		flex-grow: 1;
		flex-basis: 0;
		margin-top: 15px;

		&Filter {
			margin: -0.3rem 0;
			grid-column: 1/-1;
			padding: 0;

			.badge {
				margin: 0.3rem 0.6rem;
				background-color: var(--catalogue-badge-bg-color);
				border-radius: 4px;
				padding: 0 4px 0 8px;

				display: inline-flex;
				align-items: center;
			}
		}

		&>.row {
			display: grid;
			grid-template-columns: var(--content-item-grid);
			grid-gap: 30px;
			margin: 0px;

			&>div {
				padding: 0;
			}

			&>div:not(.CatalogueItem, .CategoryItem) {
				grid-column: 1/-1;
			}
		}
	}

	&Details {
		@include make-col-ready();

		&Info {
			@include make-col-ready();

			@include media-breakpoint-up(md) {
				@include make-col(6);
			}

			@include media-breakpoint-up(xxl) {
				@include make-col(5);
			}

			img {
				max-width: 100%;
			}
		}

		&Dates {
			@include make-col-ready();

			@include media-breakpoint-up(md) {
				@include make-col(6);
			}

			@include media-breakpoint-up(xxl) {
				@include make-col(7);
			}

			.row:not(.OpenBlock) {
				display: grid;
				grid-template-columns: var(--content-item-grid);
				grid-gap: 30px;
				margin: 15px 0 0 0;

				&>div:not(.CatalogueItem, .ContentItem, .col-md-6) {
					grid-column: 1/-1;

					&:first-child {
						margin-top: -15px;
					}
				}
			}

			form {
				.row {
					display: flex !important;
					grid-gap: 0 !important;
				}
			}

			.Subscribed h1 {
				@extend .h3;
			}

			.card-header {
				.icon {
					top: 23px;
				}
			}

			.card-title {
				font-size: 18px;

			}

			.SessionBlock {
				.Subscribed h1 {
					@extend .h2;
					margin-bottom: 0;
				}

				h2 .icon,
				h4 .icon {
					margin-right: 0.5rem;
				}

				.datelist {
					th {
						height: auto;
					}
				}

				.module {
					@extend .h4;
					padding: 0.5rem 0 0 0.5rem;
				}

				button {
					margin-right: 0;
				}
			}

			.ContentHeader {
				.card-title {
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding-right: 32px;

					@include media-breakpoint-down(sm) {
						flex-direction: column;
						align-items: flex-start;
					}
				}
			}

			.title_percentage_wrapper {
				background-color: #e9ecef;
				border-radius: 2.25rem;
				overflow: hidden;
				height: 18px;
				position: relative;
				min-width: 40%;
				margin-left: 1rem;

				@include media-breakpoint-down(sm) {
					width: 100%;
					margin-top: 1rem;
				}

				.percentage {
					background-color: var(--green-normal);
					display: inline-block;
					width: 20%;
					color: #fff;
					text-align: center;
					font-size: 12px;
					position: absolute;
					top: 0;
					padding: 2px 0;
				}
			}
		}
	}

	.CatalogueItem {
		padding: 0;
		// @include make-col-ready();
		// margin-bottom: 1rem;
		// margin-top: 1rem;

		// @include media-breakpoint-only(sm) {
		// 	@include make-col(12);
		// }

		// @include media-breakpoint-up(md) {
		// 	@include make-col(12);
		// }

		// @include media-breakpoint-up(lg) {
		// 	@include make-col(6);
		// }

		// @include media-breakpoint-up(xl) {
		// 	@include make-col(4);
		// }

		// @include media-breakpoint-up(xxl) {
		// 	@include make-col(3);
		// }

		&Dl {
			margin-top: 10px;

			dt {
				display: inline-block;

				&::after {
					content: ': ';
					display: inline;
				}
			}

			dd {
				display: inline;

				&::after {
					content: ' ';
					display: block;
				}
			}
		}

		.status-obtained[data-toggle="popover"],
		.status-missing[data-toggle="popover"],
		.status-expired[data-toggle="popover"],
		.status-expiring[data-toggle="popover"] {
			display: flex;
			justify-content: right;
			align-items: center;

			.icon {
				padding: 6px;
				border-radius: 50%
			}
		}

		.status-obtained[data-toggle="popover"] {
			.icon {
				background: var(--catalogue-item-obtained-bg-color);
				color: var(--catalogue-item-obtained-text-color);

			}
		}

		.status-missing[data-toggle="popover"] {
			.icon {
				background: var(--catalogue-item-missing-bg-color);
				color: var(--catalogue-item-missing-text-color);
			}
		}

		.status-expired[data-toggle="popover"] {
			.icon {
				background: var(--catalogue-item-expired-bg-color);
				color: var(--catalogue-item-expired-text-color);
			}
		}

		.status-expiring[data-toggle="popover"] {
			.icon {
				background: var(--catalogue-item-expiring-bg-color);
				color: var(--catalogue-item-expiring-text-color);
			}
		}
	}
}

#objFormFilterFilters {
	margin-top: var(--spacing);
}

#ContentItems,
.ContentItems {
	display: grid;
	grid-template-columns: var(--content-item-grid);
	grid-gap: 30px;
	margin: 0px;
}

.ContentItem {
	padding: 0;
	max-width: none;
	// @include make-col-ready();
	// margin-bottom: 1rem;
	// margin-top: 1rem;

	// @include media-breakpoint-up(sm) {
	// 	@include make-col(12);
	// }

	// @include media-breakpoint-up(lg) {
	// 	@include make-col(6);
	// }

	// @include media-breakpoint-up(xxl) {
	// 	@include make-col(4);
	// }

	// @include media-breakpoint-up(xxxl) {
	// 	@include make-col(3);
	// }

	// @include media-breakpoint-down(sm) {
	// 	margin-bottom: 0;
	// 	margin-top: 15px;

	// 	.card {
	// 		flex-direction: row;
	// 		align-items: flex-start; //  Prevent image stretching in Safari

	// 		&-title {
	// 			padding-right: 0 !important;
	// 			max-height: 2.3rem;
	// 			overflow: hidden;
	// 		}

	// 		&-body {
	// 			padding: 1rem;
	// 		}

	// 		&-img-top {
	// 			width: 132px;
	// 			height: auto;
	// 		}

	// 		&-img-overlay {
	// 			display: none;
	// 		}
	// 	}
	// }
}

#SegmentCourse {

	&Type,
	&SubType {

		.form-check-label {
			margin-left: 0;
		}

		.checkbox.form-check {
			display: inline-block;
			padding-left: 0;
			max-width: none;
			text-indent: 0;

			input:checked+label .icon {
				color: var(--catalogue-filter-icon-active-text-color);
				background-color: var(--catalogue-filter-icon-active-bg-color);
			}

			label {

				&:before,
				&:after {
					display: none;
				}

				span {
					padding-left: 0;
					display: flex;
					align-items: center;
					justify-content: center;
					flex-shrink: 0;
				}
			}

			span {
				padding-left: 0;
			}
		}

		.CourseSubTypeIDs,
		.CourseTypeIDs {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-gap: 0 30px;

			@include media-breakpoint-down(md) {
				grid-template-columns: 1fr;
			}

			.icon {
				@include catalog-filter-icon(2.5rem);
			}
		}
	}

	&Category {
		.ui-fancytree {
			.fancytree-expander {
				display: none;
			}
		}
	}
}

#showRecommendedTrainingsButton {
	background: transparent;
	color: black;
	padding: 0;
	display: flex;
	overflow: visible;
	border-radius: 0;

	&:before,
	&:after {
		display: none;
	}

	&.Active {
		font-weight: bold;

		.icon {
			background-color: var(--catalogue-filter-icon-active-bg-color);
			color: var(--catalogue-filter-icon-active-text-color);
		}
	}

	.icon {
		@include catalog-filter-icon(2.5rem);
		margin-right: calc(var(--spacing) / 2);
		order: -1;
	}
}

.CategoryItem .card,
.CatalogueItem .card,
.ContentItem .card {
	height: 100%;
	margin: 0;
	text-decoration: none;
	color: var(--font-color-base);

	&-body {
		flex-direction: column;

		&+.card-footer {
			padding-top: 0;
		}
	}

	&-img-top {
		border-top-left-radius: var(--catalogue-card-radius);
		border-top-right-radius: var(--catalogue-card-radius);
		aspect-ratio: var(--catalogue-image-ratio);
		object-fit: cover;
	}

	&-img-overlay {
		width: 100%;
		padding: 0;
		bottom: auto;
		aspect-ratio: var(--catalogue-image-ratio);

		.icon_wrapper {
			position: absolute;
			display: inline-block;
			margin-right: 10px;
			bottom: var(--spacing);
			left: var(--spacing);

			&.recommended {
				left: calc(var(--spacing) + var(--catalogue-filter-icon-size) + var(--spacing));
			}

			.icon {
				@include circle-icon(var(--catalogue-filter-icon-size));
			}

			&.information {
				bottom: 8px;
				left: 14px;

				.icon {
					width: 25px;
					height: 24px;
					font-size: 1.5rem;
					line-height: 1;
				}
			}
		}

		.button_wrapper {
			display: none;
		}

		.text_wrapper {
			position: absolute;
			bottom: 0;
			right: var(--spacing);
			background: var(--catalogue-item-state-bg-color);
			color: var(--catalogue-item-state-text-color);
			padding: calc(var(--spacing) / 3);
			font-size: calc(var(--base-size)/ 1.33);
			line-height: 1.4;
			box-shadow: var(--shadow);
			border-radius: var(--radius);
			max-width: calc(100% - var(--catalogue-filter-icon-size) - (var(--spacing) * 3));
			transform: translateY(50%);
			bottom: 40px;

			&.optional {
				top: 0;
				left: 0;
				bottom: unset !important;
				right: unset !important;
				border-bottom-right-radius: 15px;
			}
		}
	}

	&-footer {
		.icon {
			margin-right: 4px;
		}

		.requiredEvidence {
			font-weight: bold;
		}

		.status-missing,
		.status-expired {
			.icon {
				color: var(--red-normal);
			}
		}

		.status-obtained {
			.icon {
				color: var(--green-normal);
			}
		}

		.status-expiring {
			.icon {
				color: var(--orange-normal);
			}
		}
	}

	&-text {
		overflow: hidden;
		margin-bottom: 0;
	}

	&:hover {
		cursor: pointer;
		box-shadow: var(--shadow-hover);
		transition: 0.2s ease-in-out;
	}

	&-title {
		font-weight: bold;
		@extend h5;
		word-break: break-word;
		hyphens: auto;
	}
}

.ContentItem {
	.card {
		&-body {
			display: flex;

			@include media-breakpoint-down(sm) {
				min-height: auto;
			}
		}

		&-title {
			margin-bottom: 0;
			justify-content: center;
			align-self: center;
			padding-right: 3.6rem;
			position: relative;
			width: 100%;
		}

		.percentage_wrapper {
			margin-top: var(--spacing);
			height: 14px;
			display: flex;
			flex-direction: row;
			position: relative;

			.percentageBar {
				display: block;
				content: '';
				background: var(--catalogue-item-progress-bar-bg-color);
				height: 14px;
				width: 100%;
				position: relative;
				border-radius: 10px 10px;
				margin-right: .5rem;

				div {
					background: var(--catalogue-item-progress-bar-color);
					position: absolute;
					height: 100%;
					border-radius: 10px 10px;
				}
			}

			canvas {
				display: inline-block;
			}

			.percentage {
				font-size: 12px;
				display: inline-block;
				vertical-align: top;
				font-weight: bold;
				margin-left: 0.4rem;
				line-height: 1.3em;
			}

			.icon {
				color: var(--green-normal);
				line-height: 22px;
				vertical-align: top;
				margin-right: 0.4rem;

				&.icon-checkmark {
					float: right;
					line-height: 1rem;
				}
			}
		}
	}
}

.CategoryItem {
	@extend .CatalogueItem;

	.card-img-overlay {
		.icon_wrapper {
			display: none;
		}
	}
}

#ContentItems {
	.ContentItem {
		// @include make-col-ready();
		// margin-bottom: 1rem;
		// margin-top: 1rem;

		// @include media-breakpoint-only(xs) {
		// 	@include make-col(12);
		// }

		// @include media-breakpoint-only(sm) {
		// 	@include make-col(6);
		// }

		// @include media-breakpoint-only(md) {
		// 	@include make-col(4);
		// }

		// @include media-breakpoint-up(lg) {
		// 	@include make-col(4);
		// }

		// @include media-breakpoint-up(xl) {
		// 	@include make-col(3);
		// }

		// @include media-breakpoint-up(xxl) {
		// 	@include make-col(3);
		// }

		// @include media-breakpoint-up(xxxl) {
		// 	@include make-col(2);
		// }

		// @include media-breakpoint-down(sm) {
		// 	margin-bottom: 0;
		// 	margin-top: 15px;
		// }
	}
}

.scripts_user_trainingregistration_catalogueV2 {
	.modal.dialog-tablet {
		right: auto;
		margin-top: 3rem;

		.bootstrap-dialog-body {
			padding-top: 1rem;
		}

		.bootstrap-dialog-message {
			&>div {
				padding-left: 0;
				padding-right: 0;
			}
		}
	}
}