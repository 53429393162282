.hidden-xs {
	@include media-breakpoint-down(sm) {
		display: none;
	}
}

.hidden-sm {
	@include media-breakpoint-down(md) {
		display: none;
	}
}

.hidden-md {
	@include media-breakpoint-down(lg) {
		display: none;
	}
}

.visible-xs {
	@include media-breakpoint-up(md) {
		display: none;
	}
}

.col-xs-12 {
	@include media-breakpoint-down(sm) {
		max-width: 100%;
	}
}

.col-xs-6 {
	@include media-breakpoint-down(sm) {
		max-width: 50%;
		width: 100%;
		padding-left: 10px;
		padding-right: 10px;
	}
}
