@use 'sass:math';

/*-----------   TABS NAVS --------------------*/
.nav-tabs {
	>li {
		>a {
			color: var(--font-color-base);

			&:hover {
				color: var(--font-color-base);
			}
		}

		&.active {

			>a,
			>a:hover,
			>a:focus {
				color: var(--font-color-base);
			}
		}
	}
}

.nav-pills {
	display: flex;

	.nav-item {

		.nav-link {
			background-color: var(--nav-pills-background-color);
			box-shadow: var(--shadow);
			color: var(--nav-pills-text-color);
			text-decoration: none;
			transition: all .2s ease-in-out;
			margin-right: 10px;
			margin-bottom: 20px;
			padding: 8px 10px;
			height: 50px;
			display: flex;
			align-items: center;
			font-weight: 600;
			border-radius: 8px;
			border: 1px solid rgba(0, 0, 0, .1);

			&:disabled, &.disabled {
				color: var(--nav-pills-disabled-color);
			}

			&:hover:not(.disabled, :disabled), &.active:not(.disabled, :disabled) {
				color: var(--nav-pills-hover-text-color);
				background: var(--nav-pills-hover-background-color);

			}

			&:focus {
				outline: 0 !important;
				color: var(--nav-pills-hover-text-color);
				background: var(--nav-pills-hover-background-color);
				box-shadow: var(--shadow-inset);
			}
		}
	}
}

/*------------- TABS PILLS ----------------*/
// ul .nav-pills>li+li {
// 	margin-left: 0px;
// }

// .nav-pills {
// 	.nav-link {
// 		.badge {
// 			margin-left: 7px !important;
// 		}

// 		&.disabled {
// 			color: var(--nav-pills-disabled-color);
// 		}
// 	}
// }

// .nav-pills .nav-link.active,
// .nav-pills .show>.nav-link {
// 	background-color: var(--nav-pills-background-color);
// }

// .nav-pills .nav-link {
// 	color: var(--nav-pills-text-color);
// 	min-height: 44px;
// 	display: flex;
// 	justify-content: center;
// 	align-items: center;
// 	box-shadow: var(--shadow);
// }

ul.nav-tabs {
	/*border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;*/
	box-shadow: inset 0px -1px 0px 0px rgba(#ccc, 1);
	margin-bottom: 1.5rem;
	margin-top: 0px !important;
	border: none;

	>li.active>a,
	>li.active>a:hover,
	>li.active>a:focus {
		background: transparent;
		color: var(--font-color-base);
	}

	/* TEMP FIX */
	&.justify-content-end {
		justify-content: flex-start !important;
	}
}

//--- nav pills & arrows restyling - algemeen
// .nav-pills {
// 	position: relative;
// }

#Content {
	main>.row:first-child+.row {
		position: relative;

		&+.row {
			position: relative;
		}

		.col-lg-12 {
			position: relative;
			-webkit-transform: translate(0, 0);
			transform: translate(0, 0);
		}

		.dropdown-menu {
			z-index: 9999;
			background-color: #fff;
		}
	}
}

// ul.nav.nav-pills li {
// 	transition: border-color 0.2s ease-in-out;
// 	margin-right: 20px;

// 	a {
// 		transition: background-color 0.2s ease-in-out;
// 		text-decoration: none;

// 		&::after {
// 			transition: border 0.2s ease-in-out;
// 		}
// 	}
// }

//--- /nav pills & arrows restyling - algemeen

//--- nav pills restyling
$pillsBarColor: #fcfcfc;
$pillsBarColorHover: darken($pillsBarColor, 5%);
$pillsBarColorActive: #f6a94f;
$pillsBarTextColorActive: #ffffff;

// ul.nav.nav-pills {
// 	box-shadow: none;
// 	margin-bottom: 1rem;

// 	&>li>a {
// 		background-color: $pillsBarColor; //--- hier nog even naar kijken voor components
// 		padding: 8px 10px;
// 	}

// 	.dropdown-menu {
// 		margin-top: 0;
// 		padding-top: 0;
// 		padding-bottom: 0;

// 		a {
// 			padding: 10px;
// 		}
// 	}

// 	.dropdown-toggle {
// 		&:focus {
// 			outline: 1px solid #163e59 !important;
// 		}
// 	}
// }

// .nav.nav-pills>li>a:hover,
// .nav.nav-pills>li>a:focus {
// 	background-color: #ddd;
// }

// .nav-pills>li+li {
// 	margin-left: 0;
// }

// ul.nav.nav-pills li.active>a {
// 	box-shadow: none;
// 	background-color: $pillsBarColorActive;
// }

// ul.nav.nav-pills li.active>a,
// ul.nav.nav-pills li.active>a:hover,
// ul.nav.nav-pills li>a.active:hover {
// 	color: $pillsBarTextColorActive;
// }

// ul.nav.nav-pills>li>a,
// ul.nav-tabs>li>a {
// 	margin-bottom: 0;
// }

//--- /nav pills restyling

//--- progress bar (nav-arrows)
// $progressBarHeight: 60px;
// $progressBarBordersizeAfter: ($progressBarHeight + 10);
// $progressBarBordersizeBefore: ($progressBarBordersizeAfter + 20);
// $progressBarColor: $pillsBarColor;
// $progressBarColorHover: darken($progressBarColor, 5%);
// $progressBarColorActive: $pillsBarColorActive;
// $progressBarTextColor: #484848;
// $progressBarTextColorActive: $pillsBarTextColorActive;

// @media screen and (min-width: 768px) {
// 	ul.nav-arrows li.tab-pane.active a:hover {
// 		cursor: default;
// 		background: inherit;
// 	}

// 	ul.nav.nav-pills.nav-arrows {
// 		margin-bottom: 0;
// 	}

// 	ul.nav-arrows {
// 		margin: 20px 0;
// 		width: 100%;
// 		overflow-y: hidden;
// 		box-shadow: none !important;
// 		font-size: $font-size-lg;

// 		& > li + li {
// 			margin-left: 0;
// 		}

// 		& > li:nth-child(2) {
// 			border: 0;

// 			a {
// 				padding-left: 0 !important;
// 			}
// 		}

// 		& > li.tab-pane {
// 			display: inline-block;
// 			height: $progressBarHeight;
// 			line-height: $progressBarHeight;

// 			text-indent: math.div($progressBarHeight, 3);
// 			position: relative;

// 			&::before {
// 				content: '\00a0';
// 				height: 0;
// 				width: 0;
// 				position: absolute;
// 				left: 2px; //--- nodig voor IE
// 				border-style: solid;
// 				border-width: $progressBarBordersizeBefore 0
// 					$progressBarBordersizeBefore
// 					math.div($progressBarBordersizeBefore, 90) * 40;
// 				border-color: transparent transparent transparent #fff !important;
// 				z-index: 9;
// 				top: -(math.div($progressBarBordersizeBefore, 90) * 60);
// 			}

// 			&:nth-child(2)::before,
// 			&:first-child {
// 				border-color: transparent !important;
// 				content: '\00a0';
// 				border-width: 0 !important;
// 			}

// 			a {
// 				display: block;
// 				background: $progressBarColor;
// 				padding: 0 0 0 (math.div($progressBarHeight, 3) * 2);
// 				// color: $progressBarTextColor;
// 				box-shadow: none !important;

// 				&::after {
// 					content: ' ';
// 					height: 0;
// 					width: 0;
// 					position: absolute;
// 					right: -30px;
// 					border-style: solid;
// 					border-width: $progressBarBordersizeAfter 0
// 						$progressBarBordersizeAfter
// 						math.div($progressBarHeight, 2);
// 					border-color: transparent transparent transparent
// 						$progressBarColor;
// 					z-index: 10;
// 					top: -($progressBarHeight - 20);
// 				}

// 				&:hover {
// 					background: $progressBarColorHover;

// 					&::after {
// 						border-color: transparent transparent transparent
// 							$progressBarColorHover;
// 					}
// 				}

// 				&:focus {
// 					background: $progressBarColorHover;

// 					&::after {
// 						border-color: transparent transparent transparent
// 							$progressBarColorHover;
// 					}
// 				}

// 				&:active {
// 					background: $progressBarColorHover;

// 					&::after {
// 						border-color: transparent transparent transparent
// 							$progressBarColorHover;
// 					}
// 				}
// 			}

// 			&.active {
// 				a {
// 					background: $progressBarColorActive;
// 					color: $progressBarTextColorActive;

// 					&::after {
// 						border-left-color: $progressBarColorActive;
// 					}

// 					&:hover {
// 						cursor: default;
// 						background: $progressBarColorActive;
// 						color: $progressBarTextColorActive;

// 						&::after {
// 							border-left-color: $progressBarColorActive;
// 						}
// 					}
// 				}
// 			}
// 		}
// 	}
// }

//--- /progress bar (nav-arrows)