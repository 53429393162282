.scripts_oauth2_server_portalselection {
	background-image: url('/skins/bootstrap/default/img/portal-select-bg.jpg') !important;
    background-size: cover;

	body, #page-wrapper {
		background-color: transparent;
	}

	#Logo_top {
		display: none !important;
	}

	main {
		max-width: 30rem;
		background-color: #fff;
		margin: 50vh auto auto auto;
		border-radius: 2rem;
		padding: 1rem 3.5rem 1rem;
		position: relative;
		box-shadow: rgba(22, 62, 89, 0.2) 0px 15px 40px 0px;
		transform: translateY(-50%);
		text-align: center;

		.col-12 {
			flex: 0 0 100%;
			max-width: 100%;
		}

		.list-group-item {
			border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
			border-radius: 0 !important;
			margin-bottom: 0;
			text-decoration: none;

			&:hover {
				background-color: rgb(240, 242, 245);
			}

			&:first-child {
				border-top: 1px solid rgba(0, 0, 0, 0.125) !important;
			}

			&:last-child {
				border-bottom: 0 !important;
			}
		}
	}
}
