.Trainings {

	display: grid;
	grid-template-columns: var(--content-item-grid);
	grid-gap: 30px;
	margin: 0 0 var(--spacing) 0;

	.CatalogueItem {
		padding: 0;
		// @include make-col-ready();
		// margin-bottom: 1rem;
		// margin-top: 1rem;

		// @include media-breakpoint-only(sm) {
		// 	@include make-col(6);
		// }

		// @include media-breakpoint-up(md) {
		// 	@include make-col(6);
		// }

		// @include media-breakpoint-up(lg) {
		// 	@include make-col(4);
		// }

		// @include media-breakpoint-up(xl) {
		// 	@include make-col(3);
		// }

		// @include media-breakpoint-up(xxl) {
		// 	@include make-col(2);
		// }

		.card-title {
			border-bottom: 1px solid rgba(40, 40, 40, .3);
			padding-bottom: 6px;
		}

		.NextDay,
		.Location {
			.Label {
				font-weight: bold;
				font-style: italic;
			}
		}

		.Location {
			margin-top: 6px;
		}
	}
}

.ContentSubscription {
	iframe {
		max-width: 100%;
	}
}

.scripts_user_training_details {
	#CatalogueDetailsDates .row:not(.ContentItems) {
		// display: block;
		margin-right: -15px;
		margin-left: -15px;
	}
}