/*------------------------------------*\
   Mobile menu
\*------------------------------------*/
/* mobile menu */
#MobileMenuBtn {
	display: none;
}

#MobileMenuBtn .hamburger-inner,
#MobileMenuBtn .hamburger-inner:after,
#MobileMenuBtn .hamburger-inner:before {
	background: #666;
}

nav#MobileMenu {
	#UserPicture {
		overflow: hidden;
		border-radius: 50%;
		width: 75px;
		height: 75px;
		margin: 0 auto;
		background-image: url(../img/profile_thumb_default.jpg);
		background-size: cover;
		img {
			width: 75px;
		}
	}

	.admin {
		margin-top: 30px;

		&:before {
			content: 'Beheer menu';
			font-size: 1.3rem;
			border-bottom: 1px solid #ccc;
			display: block;
			margin-right: 0px;
			font-weight: bold;
		}
	}
}
#trigger {
	display: none;
}

@media only screen and (max-width: 480px) {
	.scripts_event_notify_edit div[class*='col-lg-'],
	.scripts_event_notify_edit div[class*='col-md-'],
	.scripts_event_notify_edit div[class*='col-sm-'] {
		float: left;
		width: 100%;
	}
	.radiogroup.inline {
		.radio {
			float: left;
		}
	}
	div[class*='col-lg']:not([class*='col-xs']) {
		width: 100%;
	}
}
@media only screen and (min-width: 568px) and (max-width: 750px) and (orientation: landscape) {
	.visible-xsl {
		display: block !important;
	}
	.col-xsl-1 {
		width: 8.3333%;
	}
	.col-xsl-2 {
		width: 16.6667%;
	}
	.col-xsl-3 {
		width: 25%;
	}
	.col-xsl-4 {
		width: 33.3333%;
	}
	.col-xsl-5 {
		width: 41.6667%;
	}
	.col-xsl-6 {
		width: 50%;
	}
	.col-xsl-7 {
		width: 58.3333%;
	}
	.col-xsl-8 {
		width: 66.6667;
	}
	.col-xsl-9 {
		width: 75%;
	}
	.col-xsl-10 {
		width: 83.333%;
	}
	.col-xsl-11 {
		width: 91.6667%;
	}
	.col-xsl-12 {
		width: 100%;
	}
	.col-xsl-offset-1 {
		margin-left: 8.3333%;
	}
	.col-xsl-offset-2 {
		margin-left: 16.6667%;
	}
	.col-xsl-offset-3 {
		margin-left: 25%;
	}
	.col-xsl-offset-4 {
		margin-left: 33.3333%;
	}
	.col-xsl-offset-5 {
		margin-left: 41.6667%;
	}
	.col-xsl-offset-6 {
		margin-left: 50%;
	}
	.col-xsl-offset-7 {
		margin-left: 58.3333%;
	}
	.col-xsl-offset-8 {
		margin-left: 66.6667%;
	}
	.col-xsl-offset-9 {
		margin-left: 75%;
	}
	.col-xsl-offset-10 {
		margin-left: 83.333%;
	}
	.col-xsl-offset-11 {
		margin-left: 91.6667%;
	}
	#Content {
		.card.tile {
			.card-body {
				.value {
					// width: 100%;
					// text-align: center;
					float: left;
				}
			}
		}
	}
}
@media only screen and (max-width: 991px) {
	#page-wrapper {
		padding-left: 0;
	}

	#Header #Logo {
		background-position: right;
	}
	#Content {
		.card.tile {
			min-height: initial;
			&.userdata {
				.card-header {
					display: none;
				}
			}
			.card-header {
				min-height: 5rem;
			}
			.card-body {
				> .left {
					width: 100%;
				}
				> .right {
					display: none;
				}
				.picture {
					float: none;
					margin: 0 auto;
					max-width: calc(1rem * 8);
					max-height: calc(1rem * 8);

					img {
						width: calc(1rem * 8);
					}
				}
				.name {
					width: 100%;
					text-align: center;
					float: left;
					font-weight: bold;
					margin-top: 1rem;
				}
				.details {
					float: none;

					.row_wrapper .FilterIcon {
						margin-left: 1rem;
					}
					div {
						span {
							&.category {
								width: 83%;
							}
							&.category_compliance {
								width: 17%;
							}
						}
					}
				}
				.compliance {
					/*float:none;
					margin: 0 auto;*/
					position: absolute;
					margin-top: 0;
				}
				div[id^='chart'].chart {
					margin-left: auto;
					margin-right: auto;
					left: 0;
					right: 0;
					top: 0;
					float: left;
					position: relative;
				}
				.logo,
				.Logo {
					float: left;
					margin: 0;
				}
				.total_evidences {
					text-indent: 1rem;
				}
			}
		}
	}
	#Header {
		#Logo {
			background-position: right;
		}
		// #MenuWrapper
		// {
		// 	#MenuPersonal
		// 	{
		// 		#UserPicture
		// 		{
		// 			width: 30px;
		// 			height: 30px;
		// 			z-index: 10;
		// 		}
		// 	}
		// 	#MenuAdmin
		// 	{
		// 		display: none;
		// 	}
		// }
	}

	// mobile menu
	.mobile {
		.admin {
			background: #666;
			height: 5rem;
			margin-left: -1rem;
			margin-right: -1rem;
			ul {
				background: transparent !important;
			}
			a {
				color: white;
			}
		}
	}
	#MobileMenuAdmin {
		display: none;
		.navbar-collapse {
			padding-left: 0px;
		}
	}
	section#Content {
		padding-top: 0px;
	}
	#CategoryWrapper .CategoryItem {
		.Training {
			display: none;
		}

		.Image,
		.NoImage {
			border-bottom: 1px solid rgb(224, 222, 221);
			border-bottom-left-radius: 0.3rem;
			border-bottom-right-radius: 0.3rem;
			background-clip: padding-box; /* Prevent background 'bleed’ through the edge of the corners */
		}
	}

	#training_signup {
		.card-body {
			.card {
				.SignUpButtonWrapper {
					width: 100%;
					margin-top: 1rem;

					button {
						width: 100%;
					}
				}
				table.datelist {
					width: 100%;

					td {
						width: 100%;
						display: block;
						text-align: center;
					}
				}
				.SessionBlockStatus {
					text-align: center;
				}
			}
		}
	}

	.ExtraTile {
		color: #fff;
		padding: 20px 10px;
		text-align: center;
		margin-bottom: 20px;
	}
	.ExtraTile .icon {
		font-size: 35px;
	}
	.ExtraTile .MobileLabel {
		font-size: 1.2rem;
		display: block;
		height: 35px;
		padding-top: 7px;
	}
	.btn-group {
		height: auto;

		.btn {
			white-space: inherit;
			text-align: left;
		}
	}
}

@media only screen and (max-width: 768px) {
	.personal span.icon {
		margin-right: 10px;
	}
	#trigger {
		display: none;
	}
}
@media only screen and (min-width: 750px) and (max-width: 1023px) {
	.content {
		position: relative;
		z-index: 10;
	}
	section#Content {
		padding-top: 9rem;
	}
	#Header {
		#MenuWrapper {
			#MenuPersonal {
				nav {
					ul.nav {
						li {
							&[class*='personal'],
							&.dropdown.mega-dropdown {
								max-width: 100%;
								> a {
									position: relative;
									text-align: center;
									padding: calc(1rem * 3) 1rem calc(1rem * 2);
									span:first-child {
										position: absolute;
										top: 1rem;
										width: 100%;
										margin: 0px;
										padding: 0px;
										left: 0px;
									}
								}
							}
						}
					}
				}
			}
		}
	}
	.col-lg-6 {
		width: 50%;
	}
}
@media only screen and (max-width: 1024px) {
	.container {
		width: 100%;
	}
	.clearHeader {
		display: block;
	}

	section#Content {
		margin-top: 0px;
		padding-top: 0px;
	}

	#MobileMenu ul > li > a > i {
		min-width: 30px;
		display: inline-block;
	}

	#MobileMenuBtn {
		width: 75px;
		display: block;

		&:focus {
			border: none;
		}
		#Logo {
			float: right;
			height: 50px;
			width: 130px;
			margin-right: 30px;
		}
		&:focus .hamburger {
			border: none;
		}
		.hamburger {
			.hamburger-box {
				position: relative;
				display: inline-block;
				width: 50px;
				height: 40px;
			}

			&.is-active {
				.hamburger-inner {
					background: transparent;
				}
			}
		}
	}
	#Header {
		#MenuWrapper {
			#MenuPersonal {
				nav {
					ul.nav {
						&:nth-child(2) {
							> li > a {
								max-width: 280px;
								min-height: auto;
							}
						}
						li {
							&[class*='personal'] {
								max-width: 18rem;
								> a {
									.icon {
										font-size: 1.3rem;
										position: inherit !important;
										padding-right: 5px !important;
										top: 0px !important;
									}
									position: relative;
									text-align: center;
									/* padding: 40px 5px 5px; */
									word-wrap: break-word;
									white-space: normal;
									/* min-height: 70px; */
									padding: 10px;
									font-size: 1rem;
									span:first-child {
										position: relative;
										top: 0px;
										width: 100%;
										margin: 0px;
										padding: 0px;
										left: 0px;
									}
								}
							}
							a {
								padding: 10px;
							}
						}
					}
				}
			}
			#MenuAdmin {
				ul {
					li {
						a {
							font-size: 1rem;
						}
					}
				}
			}
		}
	}

	#sideblock {
		width: 50%;
	}
}

/** Extra settings tablet portrait **/
@media only screen and (min-width: 768px) and (max-width: 992px) {
	#Header {
		#MenuPersonal,
		#MenuAdmin {
			#Logo {
				z-index: 1;
				position: relative;
			}
			nav:first-child {
				padding-right: 0px;

				> div {
					padding-left: 0px;
					padding-right: 0px;
					> div {
						padding-left: 0px;
						padding-right: 0px;
					}
				}
			}
			nav {
				z-index: 10;
				position: relative;
				ul.nav {
					li {
						max-width: 100%;
					}
				}
				ul.nav:nth-child(2) {
					position: absolute;
					right: 60px;
					display: none;

					max-width: 100%;
					> li {
						> a {
							max-width: 100%;
							max-width: 100%;
						}
					}
				}
			}
		}
	}
}
