.alert {
	.close {
		text-decoration: none;
	}

	>.icon {
		float: left;
	}

	&-success {
		color: var(--green-normal);
		background-color: var(--green-extra-light);
		border-color: var(--green-normal);
	}

	&-danger {
		color: var(--red-normal);
		background-color: var(--red-extra-light);
		border-color: var(--red-normal);
	}

	&-warning {
		color: var(--orange-normal);
		background-color: var(--orange-extra-light);
		border-color: var(--orange-normal);
	}
}