div.rw-widget-input.rw-widget-picker.rw-widget-container {
	&:hover {
		@include input-hover-effect(true, false);
		background-color: var(--select-hover-background-color);
		cursor: pointer;
	}
}

div.rw-dropdown-list.rw-state-focus {
	&.rw-open > .rw-widget-container {
		box-shadow: none;
	}
}

div.rw-popup-container {
	.rw-filter-input:focus-within {

		& > input:focus {
			box-shadow: none !important;
			border: none !important;
		}
	}
}

li.rw-list-option {
	&:hover {
		background-color: var(--select-hover-background-color);
		border-color: var(--select-hover-background-color);
	}

	&.rw-state-focus {
		border-color: transparent;
		background: none;

		&:hover {
			background-color: var(--select-hover-background-color);
			border-color: #000;
		}
	}

	&.rw-state-selected {
		background-color: var(--select-hover-background-color);
		border-color: var(--select-hover-background-color);
		color: var(--font-color-base);
	}
}
button.rw-btn-select {
	opacity: 1;
}

div.rw-input {
	color: var(--font-color-base);

	&:focus-within {
		border: 1px solid #000 !important;
    	box-shadow: var(--shadow) !important;
	}
}

div.rw-combobox {
	& > .rw-widget-container {
		&:hover {
			background-color: var(--select-hover-background-color);
			@include input-hover-effect(true, false);
			cursor: pointer;
		}
	}

	&.rw-state-focus {
		& > .rw-widget-container {
			border-color: #000 !important;
			box-shadow: inset 0 0 0 1px #163e59,
				0 2px 4px 0 rgba(22, 62, 89, 0.15) !important;
		}

		.rw-select {
			border-color: #000 !important;
		}
	}

	.rw-input {
		box-shadow: none !important;
		border: none;
		background: transparent;
	}

	.rw-select {
		border-color: var(--input-border-color);

		&:hover {
			background-color: transparent;
		}
	}
}

@import '../forms/RW-Widgets/select-widget.scss';
@import '../forms/RW-Widgets/date-time-picker.scss';
