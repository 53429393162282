#Workflow {
    &> .row:first-child {
        background: var(--white);
        margin-left: -30px;
        margin-right: -30px;
    }

    .card-header {
        padding: var(--spacing-lg) var(--spacing-lg) 0 var(--spacing-lg);
    }

    .card-body {
        padding: var(--spacing-lg);
    }

    .form-group {
        margin-bottom: var(--spacing-lg);
    }

    label:not(.toggle-on, .toggle-off) {
        margin-bottom: 10px;
        font-weight: 700;
        line-height: 1.3;
    }

    .accordion-header {
        margin-top: 0;
        border-bottom: 1px solid var(--medium-gray);
        padding-bottom: 15px;
        margin-bottom: 15px;

        &:focus {
            outline-offset: 10px!important;

            &:not(:focus-visible) { 
                outline: none !important; 
            }
        }
    }

    .btn-toolbar {
        margin-top: 0;
    }

    .accordion:not(#objFormFilterFilters) .accordion-item .accordion-header {
        padding-left: 0;
        padding-right: 0;
        background: transparent !important;

        .icon.expander {
            right: 0;
        }
    }

    .bootstrap-touchspin-up,
    .bootstrap-touchspin-down {
        background-color: var(--primary);
        font-size: 1rem;
        height: 40px;
        padding: 10px 12px !important;
    }

    .input-group-btn-vertical {
        .bootstrap-touchspin-up,
        .bootstrap-touchspin-down {
            height: 21px;
        }
    }

    #avatar_Image {
        margin-bottom: 15px;
        border-radius: var(--radius);
    }

    #Steps {
        .Step {

            &:not(:first-child):before {
                content: '';
                display: block;
                width: calc(100% - var(--workflow-step-indicator-size) - (var(--workflow-step-divider-spacing) * 2));
                height: 3px;
                background-color: var(--dark-gray);
                top: calc(var(--workflow-step-indicator-size) / 2);
                position: absolute;
                left: calc(-50% + (var(--workflow-step-indicator-size) / 2) + var(--workflow-step-divider-spacing));
            }

            .Indicator {
                width: var(--workflow-step-indicator-size);
                aspect-ratio: 1;
                border-radius: 50%;
                border: 3px solid var(--dark-gray);
                margin: auto;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 1.1rem;
                font-weight: bold;
            }

            &.Active {
                &:before {
                    background-color: var(--green-normal);
                }

                &:not(.Current) {
                    .Indicator {
                        border: 3px solid var(--green-normal);
                        background-color: transparent;
                        color: var(--green-normal);
                    }
                }
            }

            &.Current {
                &:before {
                    background-color: var(--primary);
                }

                label {
                    color: var(--primary);
                }

                .Indicator {
                    background-color: var(--primary);
                    border: none;
                    color: #fff;
                }
            }
        }
    }
}

#Workflow #Steps {
    margin: auto;
    width: 50%;
    display: flex;
    justify-content: space-between;
    padding: 20px;
    flex: 0 0 50%;
}

#Workflow #Data {
    margin: auto;
    width: 50%;
}

#Workflow #Steps .Step {
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

#Workflow #Steps .Step.Current {
    font-weight: bold
}

#Workflow #Steps .Step .Indicator,
#Workflow #Steps .Step label {
    text-align: center;
}

#Workflow #Steps .Step label {
    margin-top: 6px;
    display: block;
    flex: 0 0 100%;
}

#Workflow span.HelpIcon {
    font-size: 16px
}

#Workflow div.IconWrapper {
    position: absolute;
    right: 0;
    top: 0
}

#Workflow div.IconWrapper .icon {
    margin-right: 6px
}