.scripts_admin_training_registration_registration_training_edit {
    th, td {
        white-space: nowrap;
    }
    
	.icon-cross {
		color: var(--red-normal);
	}

	.icon-check {
		color: var(--green-normal);
	}
}
