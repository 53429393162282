$primary: hsl(225, 100%, 59%);

:root {
	// Colors
	--primary: #{$primary};
	--primary-darken: #{darken($primary, 30%)};
	--primary-lighten: #{darken($primary, 30%)};
	--white: #fff;
	--light-gray: #f2f2f2;
	--medium-gray: #ABABAB;
	--dark-gray: #484848;
	--green-dark: hsl(120, 26%, 38%);
	--green-normal: hsl(120, 26%, 48%);
	--green-light: hsl(120, 26%, 68%);
	--green-extra-light: hsla(120, 26%, 48%, 0.25);
	--red-dark: hsl(0, 66%, 34%);
	--red-normal: hsl(0, 66%, 54%);
	--red-light: hsl(0, 66%, 74%);
	--red-extra-light: hsl(0, 66%, 94%);
	--orange-dark: hsl(24, 78%, 34%);
	--orange-normal: hsl(24, 78%, 54%);
	--orange-light: hsl(24, 78%, 74%);
	--orange-extra-light: hsl(24, 78%, 94%);

	// Customers color palette
	--pp-blue: #007bff;
	--pp-indigo: #6610f2;
	--pp-purple: #6f42c1;
	--pp-pink: #e83e8c;
	--pp-red: #dc3545;
	--pp-orange: #fd7e14;
	--pp-yellow: #ffc107;
	--pp-green: #28a745;
	--pp-teal: #20c997;
	--pp-cyan: #17a2b8;
	--pp-white: #fff;
	--pp-gray: #6c757d;
	--pp-gray-dark: #343a40;
	--pp-gray-light: #d2d2d2;
	--pp-secondary: #6c757d;
	--pp-success: var(--green-normal);
	--pp-info: #17a2b8;
	--pp-warning: var(--orange-normal);
	--pp-danger: var(--red-normal);
	--pp-light: #f8f9fa;
	--pp-dark: #343a40;

	// Bootstrap overrides
	--success: var(--green-normal);
	--warning: var(--orange-normal);
	--danger: var(--red-normal);
	--info: var(--primary);
	--light: var(--light-gray);
	--dark: var(--dark-gray);

	--body-bg-color: var(--light-gray);

	// Utility
	--spacing: 20px;
	--spacing-lg: 30px;
	--base-size: 1rem;
	--radius: 8px;
	--shadow: 0 2px 2px rgba(0, 0, 0, 0.25), 0 2px 6px rgba(0, 0, 0, 0.1);
	--shadow-inset: 0px 4px 4px 0px #00000040 inset;
	--shadow-hover: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);

	// Typography
	--font-family-base: 'Open Sans', sans-serif;
	--font-family-headings: 'Lato';
	--font-color-base: #282828;
	//--font-size-base: calc(var(--base-size) / 1.14);
	--font-size-base: calc(var(--base-size) / 1.142857142857143);
	--link-color: var(--primary);

	// Icons
	--icon-font-size: 22px;

	// Input
	--input-background-color: var(--white);
	--input-border-color: var(--medium-gray);
	--input-border-radius: var(--radius);
	--input-focus-border-color: var(--input-border-color);
	--input-height: 40px;
	--input-placeholder-color: var(--pp-gray);
	--input-has-error-text-color: var(--red-normal);
	--input-has-error-border-color: var(--red-normal);
	--input-disabled-text-color: var(--medium-gray);
	--input-disabled-border-color: var(--medium-gray);
	--input-placeholder-text-color: var(--medium-gray);
	--input-disabled-placeholder-text-color: var(--medium-gray);

	// Focus
	--focus-outline-color: var(--dark-gray);
	--focus-outline-width: 1px;

	// Radio/Checkbox
	--radio-checkbox-size: var(--base-size);
	--radio-checkbox-checked-bg-color: var(--primary);
	--radio-checkbox-border-color: var(--font-color-base);
	--radio-checkbox-border-radius: 1px;

	// Dropdown
	--dropdown-toggle-bg-color: var(--white);
	--dropdown-toggle-border-color: var(--medium-gray);
	--dropdown-toggle-text-color: var(--font-color-base);
	--dropdown-toggle-border-radius: var(--radius);

	// Select
	--select-bg-color: var(--white);
	--select-border-color: var(--medium-gray);
	--select-text-color: var(--font-color-base);
	--select-border-radius: var(--radius);
	--select-height: 40px;
	--select-disabled-bg-color: var(--white);
	--select-hover-background-color: var(--light-gray);

	// Range slider
	--range-slider-color: var(--primary);

	// Buttons
	--button-fonts-size: 14px;
	--button-primary-bg-color: var(--primary);
	--button-primary-text-color: var(--white);
	--button-border-radius: 20px;
	--button-focus-bg-color: var(--primary);
	--button-disabled-bg-color: var(--primary-lighten);

	--button-icon-only-bg-color: transparent;
	--button-icon-only-text-color: var(--font-color-base);
	--button-icon-only-hover-bg-color: var(--light-gray);
	--button-icon-only-hover-text-color: var(--font-color-base);

	--button-outline-border-color: var(--input-border-color);
	--button-outline-text-color: var(--font-color-base);

	// Toggler
	--toggle-size: 32px;
	--toggle-handle-color: var(--white);
	--toggle-active-color: var(--primary);
	--toggle-inactive-color: var(--medium-gray);
	--toggle-padding: calc(var(--toggle-size) / 8);

	// Badge
	--badge-bg-color: var(--primary);

	// Card
	--card-border-radius: 15px;

	// Megamenu
	--mega-menu-background-color: var(--white);
	--mega-menu-text-color: var(--font-color-base);
	--mega-menu-hover-background-color: var(--primary);
	--mega-menu-submenu-heading-color: var(--font-color-base);

	// Nav pills
	--nav-pills-background-color: var(--white);
	--nav-pills-hover-background-color: var(--button-primary-bg-color);
	--nav-pills-text-color: var(--font-color-base);
	--nav-pills-hover-text-color: var(--white);
	--nav-pills-disabled-color: var(--medium-gray);

	// Nav tabs
	--nav-tab-disabled-color: var(--medium-gray);

	// Pagination
	--pagination-active-bg-color: var(--primary);
	--pagination-hover-bg-color: var(--dark-gray);

	// Catalogue
	--catalogue-filter-icon-size: 40px;
	--catalogue-filter-icon-bg-color: var(--white);
	--catalogue-filter-icon-text-color: var(--font-color-base);
	--catalogue-filter-icon-active-bg-color: var(--primary);
	--catalogue-filter-icon-active-text-color: var(--white);

	--catalogue-badge-bg-color: var(--badge-bg-color);
	--catalogue-badge-text-color: var(--white);

	--catalogue-card-radius: var(--radius);

	--catalogue-item-min-width: 250px;

	--catalogue-item-obtained-bg-color: var(--green-normal);
	--catalogue-item-obtained-text-color: var(--white);
	--catalogue-item-missing-bg-color: var(--red-normal);
	--catalogue-item-missing-text-color: var(--white);
	--catalogue-item-expired-bg-color: var(--red-normal);
	--catalogue-item-expired-text-color: var(--white);
	--catalogue-item-expiring-bg-color: var(--red-normal);
	--catalogue-item-expiring-text-color: var(--white);

	--catalogue-item-state-text-color: var(--font-color-base);
	--catalogue-item-state-bg-color: var(--white);

	--catalogue-item-progress-bar-bg-color: var(--light-gray);
	--catalogue-item-progress-bar-color: var(--green-normal);

	--catalogue-image-ratio: 500 / 273;

	--accordion-header-bg-color: var(--light-gray);

	// Content item
	--content-item-grid: repeat(auto-fill, minmax(var(--catalogue-item-min-width), 1fr));

	// Competence
	--competence-level-grade-bg-color: var(--dark-gray);
	--competence-status-bg-color: var(--dark-gray);

	// Datepicker
	--datepicker-selected-date-bg-color: var(--dark-gray);

	// Gallery
	--gallery-item-bg-color: var(--white);
	--gallery-item-active-bg-color: var(--light-gray);
	--gallery-item-min-width: 270px;
	--gallery-item-border-radius: var(--radius);
	--gallery-header-border-color: var(--medium-gray);

	// Tooltip
	--tooltip-bg-color: var(--light-gray);

	// Workflow
	--workflow-step-indicator-size: 50px;
	--workflow-step-divider-spacing: 8px;
}