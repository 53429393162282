#Login {
	main {
		margin-top: 3rem;
	}

	.SAML + .External + main {
		max-height: 0px;
		overflow: hidden;
		transition: max-height 0.5s;
	}

	.btn-group {
		width: 100%;
		height: auto;
		margin-bottom: 0;
	}

	.btn {
		margin-bottom: 1rem;
		width: 100%;
		text-decoration: none;
		margin-right: 0 !important;

		.icon {
			display: none;
			/* Icoon laten verwijderen door WB? */
		}
	}

	.form-group .icon {
		// display: none;
		/* Icoon laten verwijderen door WB? */
	}

	.sidebar {
		position: fixed;
		top: 0;
		left: 0;
		bottom: 0;
		z-index: 1000;
		background: rgba(255, 255, 255, 0.8);
		width: 25%;
		overflow-y: auto;
		overflow-x: hidden;
		min-height: 100vh;
		display: flex;
		justify-content: space-between;
		flex-direction: column;

		@include media-breakpoint-down(lg) {
			width: 40%;
		}

		&-centered {
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			bottom: auto;
			min-height: 0;
			width: 500px;
		}

		.control-label {
			font-weight: bold;
		}

		input {
			&::placeholder {
				color: inherit;
				opacity: 0.85;
			}
		}

		input[type='text'],
		input[type='password'] {
			background: 0 0;
			border: none;
			border-bottom: 1px solid;
			border-radius: 0;
			margin-bottom: 1rem;
			display: inline-block;
			padding-left: 0;
			outline-width: 1px;
			outline-color: currentColor;
			outline-style: dashed;
			border-width: 0;
			background-color: rgba(0, 0, 0, 0.1) !important;
			padding-left: 12px;

			&:focus {
				outline-style: solid;
			}

			&::placeholder {
				opacity: 0.85;
			}
		}

		#loginBox {
			padding: 1.5rem;

			#Logo {
				background-size: contain;
				background-repeat: no-repeat;
				background-position: 50%;
				height: 120px;

				&:has(+ .SAML) {
						margin-bottom: 3rem;
				}
			}
		}

		#frmGridusr_id {
			.floatThead-container {
				/* Temp  fix */
				position: relative !important;
				top: 0 !important;
				left: 0 !important;
				width: auto !important;
				transform: none !important;
			}
		}

		#tableusr_id {
			background-color: #fff;

			thead {
				display: none;
			}

			button {
				margin-bottom: auto;
			}

			.icon {
				display: block;
			}
		}

		.tooltipMessage {
			color: currentColor;
		}
	}

	#slides {
		width: 100%;
		height: 100%;
		background-color: #fff;
		top: 0px;
		overflow: hidden;
		position: absolute;
		z-index: 1;

		div[id^='slide-'] {
			right: 0px;
			bottom: 0px;
			background-size: cover;
		}
	}

	/* Verberg onnodige elementen, laten verwijderen door WB */

	.card {
		background: none;
		border: none;
		box-shadow: none;

		&-header {
			display: none;
		}

		&-body {
			padding: 0;
		}
	}

	.btn-group {
		margin-right: 0 !important;
	}
}

#poweredBy {
	padding: 1.5rem;

	&.right {
		left: auto;
		right: 15px;
	}

	a {
		color: inherit;
		opacity: 0.7;
		transition: ease-in-out 0.2s opacity;
		text-decoration: none;

		&:hover,
		&:focus,
		&:active {
			opacity: 1;
		}
	}
}
