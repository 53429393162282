.form-group.containsPasswordchecker {
	.progress {
		height: 26px;
		margin-top: 5px;
		border-radius: 0;
		box-shadow: none;
	}

	.progress-bar-striped {
		background-image: none;
	}
}
