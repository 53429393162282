/* Fonts */
//@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap');

@font-face {
	font-family: 'Lato';
	font-weight: 500;
	src: url('../../default/fonts/Lato-Medium.woff2');
}

@font-face {
	font-family: 'Lato';
	font-weight: 600;
	src: url('../../default/fonts/Lato-Semibold.woff2');
}

/* Functions */
@import 'base/Functions.scss';

/* Variables */
@import 'base/Variables-Custom.scss';
@import 'base/Variables-Bootstrap.scss';

/* Mixins */
@import 'base/Mixin.scss';

/* Bootstrap */
@import 'bootstrap/Bootstrap-functions.scss';
@import 'bootstrap/Bootstrap3-classes.scss';
@import 'bootstrap/Bootstrap4-classes.scss';
@import 'bootstrap/Bootstrap-list-group.scss';

/* Base */
@import 'base/Superdefault-overrules.scss';
@import 'base/Elements.scss';
@import 'base/Typography.scss';
@import 'base/Helper-classes.scss';
@import 'base/Content.scss';
@import 'base/Grid.scss';

/* Components */
@import 'components/Accordion.scss';
@import 'components/Alert.scss';
@import 'components/Badge.scss';
@import 'components/Modal.scss';
@import 'components/Nav-pills-arrows.scss';
@import 'components/Progress-bar.scss';
@import 'components/Header.scss';
@import 'components/gallery.scss';
@import 'components/Card.scss';
@import 'components/Hamburger.scss';
@import 'components/Tooltip.scss';
@import 'components/MegaMenu.scss';
@import 'components/Matrix.scss';
@import 'components/Dropdown.scss';
@import 'components/Pagination.scss';
@import 'components/List-group.scss';
@import 'components/Popover.scss';
@import 'components/Buttons.scss';
@import 'components/Forms.scss';
@import 'components/Icon.scss';
@import 'components/Date-time-picker.scss';
@import 'components/Loader.scss';
@import 'components/Nav.scss';
@import 'components/View-toggler.scss';
@import 'components/IFramed.scss';
@import 'components/Global-alert.scss';
@import 'components/Responsive-video.scss';
@import 'components/Table.scss';
@import 'components/Workflow.scss';
@import 'components/PanelAsButton.scss';
@import 'components/Toolbar.scss';

/* Pages */
@import 'pages/Startpage.scss';
@import 'pages/extra-Catalogus.scss';
@import 'pages/Team.scss';
@import 'pages/Reportdashboard.scss';
@import 'pages/Kostenregistratie.scss';
@import 'pages/Profiel.scss';
@import 'pages/Designation';
@import 'pages/E-learning.scss';
@import 'pages/Test.scss';
@import 'pages/Login.scss';
@import 'pages/Planner.scss';
@import 'pages/Catalogus.scss';
@import 'pages/Catalogue.scss';
@import 'pages/Bekwaamheid.scss';
@import 'pages/Reports.scss';
@import 'pages/360-graden-feedback.scss';
@import 'pages/Company-startpage.scss';
@import 'pages/Mobile-login.scss';
@import 'pages/Mobile-extra-Team.scss';
@import 'pages/RMS.scss';
@import 'pages/Training.scss';
@import 'pages/Portal-selection.scss';
@import 'pages/Friends.scss';
@import 'pages/Training-registration.scss';
@import 'pages/External-public.scss';

/* Mobile */
@import 'base/Mobile.scss';

/* Temp fixes */
@import 'base/Shame.scss';

/* Print */
@import 'base/Print.scss';