// KV Bootstrap File Upload

.file-caption-main {
	border-radius: var(--input-border-radius);

	@include ie11 {
		min-width: 300px;
	}
}

.kv-fileinput-caption {
	border-radius: var(--input-border-radius);
	border: 1px solid var(--input-border-color) !important;

	&:hover {
		@include input-hover-effect(false, true);
	}
}

.file-caption-name {
	&:focus {
		box-shadow: none !important;
	}
}

// React File Upload

.plusport-file {
	.custom-file {
		input {
			cursor: pointer;
		}

		.custom-file-label {
			border-radius: $border-radius !important;
			min-height: var(--input-height);

			&:hover {
				box-shadow: 0 0 0 1px #000,
					shadow(20) rgba(0,0, 0, 0.2) !important;
			}
		}
	}

	.file-input-field {
		border: 0 !important;
	}

	.file-input-button {
		border-radius: $border-radius !important;
	}
}

input[type='file'] {
	border: none;

	&:focus {
		box-shadow: none !important;
	}
}

.custom-file {
	height: calc(
		1.5rem + 1.5em
	); //temp fix for https://plusport.atlassian.net/browse/PPDEV-5964
}

.custom-file-label {
	border: 1px solid var(--input-border-color);

	&::after {
		transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
			border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	}
}

.custom-file-input {
	cursor: pointer;
	height: calc(1.5rem + 1.5em);

	& ~ .custom-file-label {
		border: 1px solid var(--input-border-color) !important;
	}

	&:hover {
		& ~ .custom-file-label {
			@include input-hover-effect(false, true);
		}
	}
}

.fileinput-remove {
	.hidden-xs {
		top: 0 !important;
	}
}