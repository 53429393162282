@use 'sass:math';

.irs--flat {
	.irs-line {
		border: 1px solid lighten(#333, 30%);
	}

	.irs-grid-text {
		color: var(--font-color-base);
	}

	.irs-bar,
	.irs-handle,
	.irs-handle>i:first-child {
		// background-color: #a5ca39;
		// border: 1px solid lighten(#333, 30%);
	}

	.irs-single {
		background-color: transparent;
		color: var(--font-color-base);
	}

	.irs-from:before,
	.irs-to:before,
	.irs-single:before {
		border: 3px solid transparent;
		border-top-color: #a5ca39;
	}
}

.irs-disabled.irs--flat {
	margin-top: 0;
}

.containsSlider {
	margin-bottom: 0px;
}

.irs-handle,
.irs-line,
.irs-bar {
	cursor: pointer;
}

/** Styling slider per gebruiker **/

$feedback-360-user-colors: #fd007f, #fd7200, #1100fd, #fde400, #8b00fd, #1efd00,
	#00f5fd, #fd0000, #fdba00, #fd00fd, #c6fd00, #00a0fd, #00fda9, #fd99cb,
	#f8c8a1, #c37aff, #fced6e, #7e75f8, #bdfcb5, #b8fdff, #834343, #8d7b48,
	#914691, #7f8f47, #46738d, #478d76;

@for $i from 1 through length($feedback-360-user-colors) {
	$color: nth($feedback-360-user-colors, $i);

	.slider_user_#{$i} .irs-bar,
	.slider_user_#{$i} .irs-handle>i:first-child {
		background-color: $color;
		border: 1px solid $color !important;
	}

	.slider_user_#{$i} .irs-handle,
	.irs--flat.slider_user_#{$i} .irs-handle {
		border: 1px solid $color !important;
	}

	.amcharts-graph-graph_user_#{$i} path {
		stroke: $color;
	}

	.amcharts-graph-graph_user_#{$i} circle {
		fill: $color;
	}
}

.slider_total .irs-bar,
.slider_total .irs-handle>i:first-child {
	// Gemiddelde
	background-color: #28a745;
}

/** Styling per vraag **/
$feedback-360-question-colors: #c37aff, #fced6e, #7e75f8, #bdfcb5, #b8fdff,
	#834343, #8d7b48, #914691, #7f8f47, #46738d, #478d76;

@for $i from 1 through length($feedback-360-question-colors) {
	$color: nth($feedback-360-question-colors, $i);

	.irs--flat.slider_question_#{$i} .irs-bar,
	.irs--flat.slider_question_#{$i} .irs-handle>i:first-child {
		background-color: $color;
		border-color: $color;
	}

	.irs--flat.slider_question_#{$i} .irs-bar,
	.irs--flat.slider_question_#{$i} .irs-handle {
		border-color: $color;
	}

	.irs--flat.slider_question_#{$i} .irs-bar {
		background-color: $color;
	}
}

// Tooltip
.amcharts-balloon-bg {
	stroke: var(--font-color-base) !important;
}

/** Algemene styling **/
.row.detail {
	border-top: 1px solid #ccc;
	border-bottom: 1px solid #ccc;
	padding-top: 7px;
	padding-bottom: 5px;
	margin-bottom: 14px;
	background: #f8f9fa;
}

.row.competenceRow {
	.form_competence+.form_competence {
		.containsSlider {
			padding-bottom: $grid-gutter-width;
			padding-left: math.div($grid-gutter-width, 3);
			padding-right: math.div($grid-gutter-width, 3);
		}

		.average,
		.assessment {
			background-color: #ebf7ff;
			font-weight: bold;

			.irs--flat {
				&.irs-disabled {
					.irs-bar {
						opacity: 1 !important;
					}
				}

				.irs-handle>i:first-child {
					opacity: 1 !important;
				}
			}
		}
	}
}

.row.competenceRow .form_competence+.form_competence .average,
.row.competenceRow .form_competence+.form_competence .assessment,
.assessmentRow {
	background-color: #ebf7ff;
	font-weight: bold;

	.irs-grid-text {
		font-weight: normal;
	}
}

.accordion-content {

	.containsText,
	.containsSlider {
		padding-top: 15px;
	}

	>.row {
		// border-bottom: 1px solid #ccc;

		&>div>.row {
			&:first-child {
				border-bottom: 0;
			}
		}
	}
}

.row.competenceRow label {
	font-weight: bold;
}

.irs--flat {

	.irs-min,
	.irs-max {
		display: none;
	}

	&.irs-disabled:not(.slider_total):not([class*='slider_question']):not([class*='slider_user']) {
		// beetje hotfixerig tot WB met meer classes/selectors komt
		opacity: 1;

		.irs-bar {
			opacity: 0.2;
			border-style: dotted !important;
		}

		.irs-handle {
			// border-color: grayscale(#a5ca39);
			border-style: dotted !important;
		}

		.irs-handle>i:first-child {
			opacity: 0.5;
		}
	}

	.irs-single {
		&::before {
			bottom: -5px;
		}
	}
}

.row.competenceRow {

	.form-group.containsTextarea,
	.form-group.containsText {
		padding-top: 15px;
	}

	.accordion-content .containsSlider,
	.accordion-content .form-control-static {
		padding-top: 15px;
	}
}

.form_competence label {
	margin-top: 0;
}

.row.competenceRow .form-group.containsText,
.row.competenceRow .form-group.containsTextarea {
	padding-top: 20px;
}

.form-group.containsSlider {
	padding-top: 25px;
	padding-bottom: 10px;
}

.form-group.containsText {
	padding-top: 20px;
}

.form-horizontal .form-group.containsText {
	padding-top: 0px;
}

@include media-breakpoint-down(md) {
	.form_competence {
		&>div>div.col-12 {
			// dirty fix tot WB met propere HTML komt - ppdev-6288
			border-top: 1px dashed #eee;
		}
	}

	.row.competenceRow .form_competence+.form_competence .containsSlider,
	.row.competenceRow .form_competence+.form_competence .form-control-static {
		padding-left: math.div($grid-gutter-width, 3);
		padding-right: math.div($grid-gutter-width, 3);
	}
}