@media only screen and (max-width:767px){
	#Login {
		.sidebar {
			transform: none;
			position: relative;
			width: 100%;
			top: 0;
			left: 0;
		}

		#slides {
			display: none;
		}
	}
}

@media only screen and (max-height:640px){
	#Login {
		.sidebar {
			#loginBox {
				transform: none;
				top: 0;
			}
		}
	}
}


// @media only screen and (max-width:767px){
// 	#Login
// 	{
// 		// background-color: #efefef;
// 		#background
// 		{
// 			height: 240px;
// 			position: relative;
// 			z-index:0;
// 		}
// 		.sidebar
// 		{
// 			position: relative;
// 			margin-top: 150px;
// 			min-height:100vh;
// 		}

// 		.btn {
// 			margin-right: 0;
// 		}


// 		// #sideContainer
// 		// {
// 		// 	padding: 30px 0;
// 		// }

// 		#Logo
// 		{
// 			height: 45px;
// 		}
// 		#loginBox button {
// 			width: 100%;
// 			// border-radius: 0px;
// 			margin-bottom: 10px;
// 		}
// 		main .btn-toolbar
// 		{
// 			margin-left:0px;
// 		}
// 		.btn-group
// 		{
// 			margin: 0 auto;
// 			float: none;
// 			display: block;
// 		}

// 		#samlContainer
// 		{
// 			margin: 15px 0px;
// 		}
// 		#samlContainer #login_extern .btn-group
// 		{
// 			width: 100%;
// 		}
// 		footer {
// 			display:none;
// 		}
// 		#slides, .bg-overlay
// 		{
// 			height: 150px;
// 		}
// 	}
// }

// @media only screen
// 	and (max-width:374px)
// 	and (min-width:320px)
// {
// 	#Login #background
// 	{
// 		height: 180px;
// 		position: relative;
// 		z-index:0;
// 	}

// }

// @media only screen
// 	and (max-width:767px)
//   	and (orientation: landscape)
// {
// 	#Login #loginBox #Logo
// 	{
// 		height: 45px;
// 	}
// 	#Login #loginBox main
// 	{
// 		// padding: 0px;
// 		// margin-top: -30px;
// 	}
// 	#Login .sideContainer {
// 		margin: 0;
// 		padding: 15px;
// 		overflow: scroll;
// 	}
// 	#Login	#samlContainer
// 	{
// 		margin:	15px 0;
// 	}
// }

// @media only screen
// 	and (max-width:767px)
// {
// 	/*login sidebar*/
// 	#Login main
// 	{
// 		padding: 0px;
// 	}
// 	#Login .content
// 	{
// 		margin-top: 30px;
// 	}
// }
// @media only screen
// 	and (min-width:750px)
// 	and (max-width:1023px)
// {
// 	body#Login
// 	{

// 		background:none

// 	}
// }

// @media only screen
// 	and (max-width: 1600px)
// {
// 	/*login sidebar*/
// 	#Login #loginBox button, #login_extern button
// 	{
// 		width: 100%;
// 		// margin-bottom: 10px;
// 	}

// }


// @media only screen
// 	and (min-width: 1601px)
// {
// 	/*login sidebar*/
// 	#Login #loginBox button, #login_extern button
// 	{
// 		width: 100%;
//     	margin-top: 10px;
// 	}
// }
// /*--- added 12 nov 2018 */
// @media only screen and (max-width:767px)
// {
// 	#Login{
// 		// min-width:300px;
// 		width: auto;
// 		#slides,#background{
// 			display:none;
// 		}
// 		// #slides{background:red;}
// 		.sidebar{
// 			margin-top:0;
// 			// height:calc(100vh - 1px);
// 			&+div{display:none;}
// 			#loginBox{
// 				padding-bottom:0;
// 			}
// 			// .leftloginbox > .row{
// 				// padding-left:10px;padding-right:10px;//--- IVON, maar wellicht ook andere portals
// 			// }
// 		}
// 		.bg-overlay{
// 			background:transparent!important;
// 		}

// 		#loginBox{
// 			main > div > div{
// 				background:transparent;
// 				box-shadow: none;
// 			}
// 			.btn-md{
// 				padding-left:12px;
// 			}
// 		}

// 		.bootstrap-dialog{
// 			.btn-group{
// 				float:right;
// 				.btn{
// 					margin-left:10px;
// 					margin-right:0;
// 				}
// 			}
// 			.card.containsBootstrapForm{
// 				background:transparent;
// 			}
// 		}
// 	}
// }


// @media screen and (max-width:767px){

// 	#Login #slides #slide-1,
// 	#Login #slides #slide-2,
// 	#Login #slides #slide-3,
// 	#Login #slides #slide-4,
// 	#Login #slides #slide-5,
// 	#Login #slides #slide-6,
// 	#Login #slides #slide-7,
// 	#Login #slides #slide-8,
// 	#Login #slides #slide-9{
// 		background-image: none !important;
// 	}

// }

// /*--- /added 12 nov 2018 */