.card#training_details
{
	table {
		width: auto !important;
	}

	div[id^="card_"][id$="collapse"]
	{
		.card-body
		{

			form
			{
				fieldset
				{
					.form-group
					{
						& > label
						{
							float: left;
							min-width: 33%;
							/*padding-top: 7px;
							padding-bottom: 7px;*/
						}
						& > .form-control-static
						{
							float: left;
							min-width: 66%;
							padding-top: 0px;
						}
					}
				}
			}
		}
	}
}

.containsPanel#training_signup
{
	.card-body
	{
		.card
		{
			&:first-child{//--- vieees patrick
				 margin-top:0;
			}
			.SignUpButtonWrapper
			{
				float: none;

				.icon {
					margin-right: .8rem;
				}
			}
			.SlotsAvailable
			{
				float: left;
				width: 100%;

			}
			.datelist
			{
				margin-top: 1rem;
				margin-bottom: 1rem;
				width: 100%;
			}
			.SessionBlockStatus
			{

				border-top: 1px solid #ccc;
				display: none;
			}

		}

	}
}
