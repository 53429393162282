/*** DASHBOARD REPORT ***/

#scripts_report_dashboard .ContainerDefault {
	padding: 0px;
}

.scripts_report_digitaltrainingcontent .succes {
	&.TRA,
	&.EXA {
		background-color: var(--green-normal);
	}
}

#report_dashboard {
	margin-left: -10px;
	margin-right: -10px;
}

.report_dashboard_block {
	height: 100%;
	background-color: #fff;
	border: 1px solid #cccccc;
	float: left;
	position: relative;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}
.scripts_report_dashboard {
	.report_dashboard_block {
		width: -moz-calc(33.3333% - 20px);
		width: -webkit-calc(33.3333% - 20px);
		width: -o-calc(33.3333% - 20px);
		width: calc(33.3333% - 20px);
		margin: 10px;
	}
	// .report_dashboard_registration_per_department
	// {
	// 	width: -moz-calc(66.6666% - 20px);
	// 	width: -webkit-calc(66.6666% - 20px);
	// 	width: -o-calc(66.6666% - 20px);
	// 	width: calc(66.6666% - 20px);
	// }
}

.report_dashboard_block_header {
	border-bottom: 1px solid #cccccc;
	height: 30px;
	width: 100%;
	cursor: move;
}

.report_dashboard_block_header_name {
	font-size: 14px;
	padding: 10px;
	color: #666;
	float: left;
	width: 100%;
	text-align: center;
	position: relative;
	top: 50%;
	transform: translateY(-50%);
}

.report_dashboard_block_header .icon {
	font-size: 15px;
	cursor: pointer;
}
.report_dashboard_block_header a:hover {
	text-decoration: none;
}

.report_dashboard_block_header_edit {
	width: 20px;
	right: 22px;
	top: 6px;
	position: absolute;
}

.report_dashboard_block_header_delete {
	width: 20px;
	right: 0px;
	top: 6px;
	position: absolute;
}

.report_dashboard_block_content {
	height: 320px;
	width: 100%;
	float: left;
	position: relative;
}

.report_dashboard_block_content_chart {
	background-color: #ffffff;
	height: 320px;
	width: 100%;
}
#chart_wrapper {
	.report_chart {
		min-height: 460px;
	}
	.height1 {
		min-height: 360px;
		.report_chart {
			min-height: 220px;
		}
	}
	.height2 {
		min-height: 500px;
		.report_chart {
			min-height: 360px;
		}
	}
	.height3 {
		min-height: 610px;
		.report_chart {
			min-height: 470px;
		}
	}
	.dashboard_block > .card {
		border: 1px solid #ccc;
	}
	.dashboard_block > .card h4 {
		padding-left: 10px;
	}
	.btn-toolbar .btn-group {
		height: auto;
		margin-bottom: 0;
	}
	.card .card-header .btn-toolbar .btn-group .btn {
		margin-bottom: 0 !important;
	}
}
.report_dashboard_block_footer {
	border-top: 1px solid #cccccc;
	height: 30px;
	width: 100%;
	float: left;
	position: relative;
}

.report_dashboard_block_footer_link {
	font-size: 14px;
	color: #000000;
	padding-left: 2px;
	float: left;
	width: 100%;
	text-align: center;
	position: relative;
	top: 50%;
	transform: translateY(-50%);
}

.report_dashboard_block_footer_link a {
	color: #666666 !important;
}

.report_dashboard_block_footer_link a:hover {
	color: #a6c939 !important;
	text-decoration: underline;
}
