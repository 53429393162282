@use 'sass:math';

#mm {
	//color: var(--font-color-base) !important;
	//background: var(--mega-menu-background-color) !important;
	

	li.mega-menu-item, li.mega-menu-row, ul.mega-sub-menu, li.mega-menu-column {
		color: var(--font-color-base) !important;
	}
}

#mm-wrapper {
	background-color: var(--mega-menu-background-color);
	color: var(--font-color-base) !important;
}


@include media-breakpoint-up(md) {
	// @media (min-width: 992px) {
	.MenuHorizontal
		#mm
		> li.mega-menu-item.mega-current-menu-item.picture
		> a.mega-menu-link {
		background: none !important;
	}

	#mm {
		position: relative;

		& > .mega-menu-item {
			&.picture {
				& > .mega-menu-link {
					background: transparent !important;
					padding-left: 12px;
					padding-right: 12px;

					&:focus {
						outline-offset: -12px;
					}
				}
			}

			& > .mega-menu-link {
				padding-top: 12px !important;
				padding-bottom: 9px !important;
				font-size: 0.9rem;
				color: grey;

				.icon {
					display: block !important;
					font-size: 32px;
					margin: 0 auto !important;
					text-align: center;
					padding-bottom: 0.8rem;
					padding-top: 0.075rem;

					&.icon-cog3,
					&.icon-switch {
						font-size: 12px;
					}
				}
			}

			&.mega-disable-link,
			.mega-has-icon > .mega-menu-link[href='#'] {
				cursor: default !important;
			}

			&.logoff > .mega-menu-link .icon,
			&.settings > .mega-menu-link .icon {
				padding-bottom: 0;
				font-size: 15px;
			}

			&.logoff .mega-menu-link-text,
			&.settings .mega-menu-link-text {
				display: none;
			}
		}
	}

	#mm > li.mega-menu-item {
		& > a.mega-menu-link {
			height: 80px;

			&.settings {
				&:hover {
					background-color: unset;
				}
			}
		}

		&.settings {
			position: absolute;
			top: 0;
			max-width: 3rem;
			width: 15px;
			cursor: default;
			left: 0px;

			& > a.mega-menu-link {
				width: auto;

				&:hover {
					background-color: transparent !important;
				}
			}
		}

		&.logoff {
			width: 1rem;
			position: absolute;
			top: 3.5rem;

			& > a.mega-menu-link {
				height: auto;
				display: block;

				&:hover {
					background-color: transparent !important;
				}
			}
		}
	}

	#mm-wrapper,
	#mm,
	#mm ul.mega-sub-menu,
	#mm li.mega-menu-item,
	#mm li.mega-menu-row,
	#mm li.mega-menu-column,
	#mm a.mega-menu-link {
		line-height: normal;
	}

	#mm
		> li.mega-menu-megamenu
		> ul.mega-sub-menu
		> li.mega-menu-item
		li.mega-menu-item
		> a.mega-menu-link,
	#mm
		> li.mega-menu-megamenu
		> ul.mega-sub-menu
		li.mega-menu-column
		> ul.mega-sub-menu
		> li.mega-menu-item
		li.mega-menu-item
		> a.mega-menu-link,
	#mm
		> li.mega-menu-megamenu
		> ul.mega-sub-menu
		> li.mega-menu-item
		> a.mega-menu-link {
		font-size: calc(var(--font-size-base) / 1.16); 
		color: var(--font-color-base);
	}

	#mm
		> li.mega-menu-megamenu
		> ul.mega-sub-menu
		li.mega-menu-column
		> ul.mega-sub-menu
		> li.mega-menu-item
		> a.mega-menu-link,
	#mm .mega-sub-menu li.mega-has-icon > .mega-menu-link {
		font-size: 1rem;
		font-weight: normal;
		text-transform: none;
		color: var(--mega-menu-submenu-heading-color);

		&:hover {
			font-weight: normal;
		}
	}

	#mm > li.mega-menu-item > a.mega-menu-link {
		text-align: center;

		&:hover {
			background: var(--mega-menu-hover-background-color);
		}
	}

	#mm .mega-sub-menu > .mega-has-icon > a.mega-menu-link {
		font-size: 1.2rem;
		font-weight: normal;
	}

	#mm > li.mega-menu-item.picture {
		& > .mega-menu-link {
			color: transparent;
			cursor: pointer;

			.profilepicture {
				height: 56px;
				width: 56px;
				background-size: contain;
				border-radius: 50%;
				&.initials {
					background-color: white;
					color: #444;
					font-size: 1.6rem;
					line-height: 56px;
					text-align: center;
				}
			}

			&:hover {
				background: unset;
			}
		}

		.icon,
		.mega-menu-link-text {
			display: none !important;
		}
	}

	#mm > li.mega-menu-item.picture > a.mega-menu-link:hover {
		color: transparent;
	}

	#mm li.mega-menu-item-has-children > a.mega-menu-link:after,
	#mm
		li.mega-menu-item-has-children
		> a.mega-menu-link
		span.mega-indicator:after {
		display: none;
	}

	#mm
		> li.mega-menu-megamenu
		> ul.mega-sub-menu
		> li.mega-menu-item
		li.mega-menu-item
		> a.mega-menu-link,
	#mm
		> li.mega-menu-megamenu
		> ul.mega-sub-menu
		li.mega-menu-column
		> ul.mega-sub-menu
		> li.mega-menu-item
		li.mega-menu-item
		> a.mega-menu-link {
		text-decoration: none;
	}

	#mm
		> li.mega-menu-megamenu
		> ul.mega-sub-menu
		> li.mega-menu-item
		li.mega-menu-item
		> a.mega-menu-link:hover,
	#mm
		> li.mega-menu-megamenu
		> ul.mega-sub-menu
		li.mega-menu-column
		> ul.mega-sub-menu
		> li.mega-menu-item
		li.mega-menu-item
		> a.mega-menu-link:hover,
	#mm
		> li.mega-menu-megamenu
		> ul.mega-sub-menu
		> li.mega-menu-item
		li.mega-menu-item
		> a.mega-menu-link:focus,
	#mm
		> li.mega-menu-megamenu
		> ul.mega-sub-menu
		li.mega-menu-column
		> ul.mega-sub-menu
		> li.mega-menu-item
		li.mega-menu-item
		> a.mega-menu-link:focus {
		text-decoration: underline;
		color: #000;
	}

	#mm > li.mega-menu-item.mega-current-menu-item > a.mega-menu-link,
	#mm > li.mega-menu-item.mega-current-menu-item > a.mega-menu-link:hover,
	#mm > li.mega-menu-item.mega-current-menu-item > a.mega-menu-link:focus,
	#mm > li.mega-menu-item.mega-current-menu-item > a.mega-menu-link:active {
		background-color: var(--mega-menu-hover-background-color);
	}

	#mm > li.mega-menu-item.mega-current-menu-item.picture > a.mega-menu-link {
		color: var(--mega-menu-hover-background-color);
	}

	.mega-menu-toggle {
		background: unset;
	}

	//--- horizontaal menu
	.MenuHorizontal {
		#mm {
			& > li.mega-menu-item {
				&.picture {
					margin-right: 1rem;
					& > a {
						background: transparent !important;
					}
				}

				.settings {
					padding: 1rem 0.6rem 0.2rem 0.6rem !important;
					height: auto;
				}

				&.picture + .settings {
					left: 68px;
					width: auto;
					height: auto;
					padding: 0;
					margin-top: 12px !important;

					& > .mega-menu-link {
						height: auto;
						padding: 1px 4px 1px 4px !important;
						margin: 0 5px 0 5px !important;

						.icon {
							padding-top: 1px !important;
							padding-bottom: 1px !important;
							font-size: 15px;
						}
					}

					a:hover {
						background: transparent;
					}
				}
				&.logoff {
					width: auto;
					bottom: 0;
					top: auto;
					left: 68px;
					margin-bottom: 12px !important;

					.mega-menu-link {
						height: auto;
						padding: 1px 4px 1px 4px !important;
						margin: 0 5px 0 5px !important;

						.icon {
							padding-top: 1px !important;
							padding-bottom: 1px !important;
							font-size: 15px;
						}
					}
				}
				//--- geen profielrechten
				&:first-child.settings {
					position: absolute;
					z-index: 999; //--- TEMP
					left: 8px;
					width: auto;
					padding-left: 0;
					height: auto;
					& > a.mega-menu-link {
						padding-left: 0;
						height: auto;
						padding: 1rem 0.6rem 0.2rem 0.6rem !important;
					}
					& + .logoff {
						padding-left: 0;
						position: absolute;
						bottom: 0;
						left: 8px;
						& > a.mega-menu-link {
							padding-left: 0;
						}
					}
				}

				&:first-child.settings + .logoff + .mega-menu-item {
					margin-left: 40px;
				}
				//--- geen profielrechten

				& > .mega-menu-link {
					.icon {
						padding-bottom: 10px;
						padding-top: 0;
					}
				}
			}
		}
	}

	.MenuHorizontal {
		#mm > li.mega-menu-item.personal:has(+.admin) {
			border-right: 1px dotted rgba(0, 0, 0, 0.4);
		}

		#mm > li.mega-menu-item.admin {
			border-left: 1px dotted rgba(255, 255, 255, 0.6);

			& + .mega-menu-item.admin {
				border-left: none;
			}
		}

		#mm > li.mega-menu-item.manager {
			border-left: 1px dotted rgba(255, 255, 255, 0.6);
			margin-left: 0.5rem;
			padding-left: 0.5rem;

			& + .mega-menu-item.manager {
				border-left: none;
			}
		}
	}

	.MenuVertical {
		#Header {
			padding-left: 0 !important;
			background: #444;
			// position: fixed;
			top: 0;
			left: 0;
			height: 100vh;
			// max-height: 100vh;
			padding-bottom: 1rem;
		}

		#mm > li.mega-menu-item.admin {
			border-top: 1px dotted rgba(255, 255, 255, 0.6);
			& + .mega-menu-item.admin {
				border-top: none;
			}
		}

		#mm > li.mega-menu-item.manager {
			border-top: 1px dotted rgba(255, 255, 255, 0.6);
			margin-top: 0.5rem;
			padding-top: 0.5rem;

			& + .mega-menu-item.manager {
				border-top: none;
			}
		}

		#mm > li.mega-menu-item > a.mega-menu-link {
			padding-left: 0 !important;
			padding-right: 0 !important;
		}

		#mm > li.mega-menu-item.logoff > a.mega-menu-link {
			margin: 0.8rem 0.2rem 0.2rem 0.4rem !important;
			padding: 0.2rem !important;
		}

		#mm > li.mega-menu-item.settings > a.mega-menu-link {
			margin: 0.8rem 0.4rem 0.2rem 0.8rem !important;
			padding: 0.2rem !important;
		}

		#mm-wrapper {
			width: auto;
			max-width: $verticalMenuWidth;

			#mm > li:first-child > a {
				padding-top: 1rem;
			}

			#mm > li.mega-menu-item.picture {
				padding-top: 30px;
				padding-bottom: 15px;

				& > .mega-menu-link {
					padding-right: 0;
					padding-left: 0;
					height: auto;
					padding-top: 0 !important;
					padding-bottom: 0 !important;

					&:focus {
						outline: none !important;

						.profilepicture {
							outline: 1px solid white;
						}
					}

					.profilepicture {
						margin-left: auto;
						margin-right: auto;
					}
				}
			}

			#mm > li.mega-menu-item {
				&.logoff {
					width: auto;
					position: absolute;
					right: 9px;
					top: 0;

					& > .menu-menu-link {
						margin: 1rem 0.8rem 0.2rem 0.4rem !important;
						padding: 0.2rem !important;
					}
				}

				&.settings {
					height: auto;
					width: auto;
					padding: 0 !important;
					left: 0px;
					position: absolute;

					@include media-breakpoint-down(md) {
						left: 3px;
						top: 0;
					}

					& > .menu-menu-link {
						width: 15px;
						padding-left: 0 !important;
						padding-right: 0 !important;
						margin: 0.8rem 0.4rem 0.2rem 0.8rem !important;
						padding: 0.2rem !important;
					}
				}
				//--- geen profielrechten
				&:first-child.settings {
					position: absolute;
					top: -40px;
					z-index: 999; //--- TEMP
					left: 0px;
					width: auto;
					padding-left: 0;
					& > a.mega-menu-link {
						padding-left: 0;
					}
					& + .logoff {
						padding-left: 0;
						position: absolute;
						top: -40px;
						left: 80px;
						& > a.mega-menu-link {
							padding-left: 0;
						}
					}
				}

				&.picture + .settings + .logoff {
					left: 68px;
					width: 2.6rem;
				}
				&:first-child.settings + .logoff + .mega-menu-item {
					margin-top: 40px;
				}
				//--- geen profielrechten
			}
		}

		#Content {
			padding-left: $verticalMenuWidth + 20;
		}

		/*--- kan netter door _default_css te verbeteren/ op te schonen/ uit te kleden */
		#mm.mega-menu-vertical
			li.mega-menu-megamenu.mega-align-bottom-right
			> ul.mega-sub-menu {
			position: fixed;
			z-index: 9999;
			top: 0;
			width: auto;
			left: $verticalMenuWidth;
			height: 100% !important;
			max-height: 100vh;
			overflow-y: auto;

			@media (max-height: 768px) {
				// left: $verticalMenuWidth + 17px; // scrollbar width
				left: $verticalMenuWidth; // scrollbar width
			}

			.mega-menu-column {
				width: 100%;
			}
		}

		#mm
			li.mega-menu-item.mega-menu-megamenu
			ul.mega-sub-menu
			ul.mega-sub-menu {
			height: 100%;
			display: flex;
			flex-direction: column;
			flex-wrap: wrap;
			overflow: visible;
			width: auto;
		}

		#mm li.mega-menu-megamenu > ul.mega-sub-menu > li.mega-menu-row {
			height: 100%;
		}

		#mm
			li.mega-menu-megamenu
			> ul.mega-sub-menu
			> li.mega-menu-row
			> ul.mega-sub-menu
			> li.mega-menu-columns-3-of-12 {
			width: 290px;
		}

		#mm > li.mega-menu-item > a.mega-menu-link {
			max-width: $verticalMenuWidth;
		}

		/*--- /kan netter door _default_css te verbeteren/ op te schonen/ uit te kleden */
	}
	@media (max-height: 768px) {
		.MenuVertical {
			#Header {
				// overflow-y: scroll!important;
				// overflow-x: hidden!important;
				// padding-right: 17px;
				box-sizing: content-box;
				float: left;
				max-height: 100vh;
				position: relative;

				&:after {
					display: block;
					position: fixed;
					height: 100%;
					width: 110px;
					top: 0;
					left: 0;
					content: '';
					background-color: inherit;
					z-index: -1;
				}
			}
		}
	}

	//--- /verticaal menu
}

@include media-breakpoint-down(sm) {
	#mm-wrapper.wrapper-vertical {
		width: 100%;
	}

	.mega-menu-toggle.mega-menu-open + #mm {
		display: flex !important;
		flex-direction: column;
		overflow-y: scroll;
		min-height: 100%;
		max-height: calc(100vh - 60px);
		background-color: var(--mega-menu-background-color);
	}

	#mm > li.mega-menu-item > a.mega-menu-link,
	#mm > li.mega-menu-item.mega-current-menu-item > a.mega-menu-link,
	#mm > li.mega-menu-item.mega-current-menu-ancestor > a.mega-menu-link,
	#mm > li.mega-menu-item.mega-current-page-ancestor > a.mega-menu-link {
		font-size: 1.1rem;
		height: auto;
		line-height: 2rem;
		height: 2rem;
		padding-top: 1rem;
		min-height: 3rem;
	}

	#mm > li.mega-menu-item {
		position: relative !important;

		.profilepicture {
			display: none;
		}

		.icon,
		.mega-menu-link-text {
			display: inline;
		}

		.icon-pp_profiel {
			margin-right: 6px;
		}

		& > a.mega-menu-link {
			line-height: 3em;
		}

		&.home {
			order: -1;
		}

		&.settings {
			position: relative;
			z-index: 2;
		}

		&.logoff {
			position: relative;
		}

		> ul.mega-sub-menu {
			position: relative;
			box-shadow: none;
		}
	}

	#mm > li.mega-menu-item > a.mega-menu-link {
		line-height: 3rem;
		padding-top: 1rem;

		&:after,
		span:after {
			color: #fff !important;
			transform: rotate(270deg);
		}

		& > span.mega-indicator:after {
			transform: rotate(90deg);
		}
	}

	#mm
		> li.mega-menu-megamenu
		> ul.mega-sub-menu
		> li.mega-menu-item
		> a.mega-menu-link,
	#mm
		> li.mega-menu-megamenu
		> ul.mega-sub-menu
		li.mega-menu-column
		> ul.mega-sub-menu
		> li.mega-menu-item
		> a.mega-menu-link {
		font-size: 1.2rem;
		pointer-events: none;

		&:hover {
			pointer-events: none;
		}
	}

	#mm
		> li.mega-menu-megamenu
		> ul.mega-sub-menu
		> li.mega-menu-item
		li.mega-menu-item
		> a.mega-menu-link,
	#mm
		> li.mega-menu-megamenu
		> ul.mega-sub-menu
		li.mega-menu-column
		> ul.mega-sub-menu
		> li.mega-menu-item
		li.mega-menu-item
		> a.mega-menu-link {
		font-size: 1rem;
	}

	#mm > li.mega-menu-item.mega-toggle-on > a.mega-menu-link,
	#mm > li:hover > a.mega-menu-link {
		background-color: rgb(43, 43, 43);
	}

	.mega-disable-link,
	.mega-has-icon > .mega-menu-link[href='#'] {
		cursor: default !important;
	}

	.mega-menu-toggle {
		cursor: default;
		height: 60px;
		background: var(--mega-menu-background-color);

		.mega-toggle-block {
			cursor: pointer;
			min-height: 0;

			&:focus {
				outline: 1px solid #fff;
				outline-offset: -3px;
			}

			&:before {
				display: none;
			}
		}
	}
}

#mm > li.mega-menu-item.picture > .mega-menu-link .profilepicture {
	//TEMP HACK
	background-color: transparent !important;
	&.initials {
		background-color: white !important;
	}
}

#menuSearchContainer {
	float: left;
	margin-top: 4px;
	margin-left: 170px;
}

#menuSearchBox {
	height: 25px;
	width: 170px;
	font-size: $font-size-base;
	box-shadow: inset -2px 2px 5px 0px rgba(0, 0, 0, 0.1);
	border: 1px solid #666;
	padding-left: math.div(1rem, 2);
	background-color: #666;
	color: white;
}

#menuSearchResults {
	display: block;
	width: 30rem;
	background-color: white;
	border: 1px solid #ccc;
	position: absolute;

	a {
		padding-top: math.div(1rem, 2);
		padding-bottom: math.div(1rem, 2);
		padding-left: calc(1rem * 2.5);
		padding-right: math.div(1rem, 2);
		color: #666;
		width: 270px;
		float: left;

		&:hover {
			color: var(--green-normal);
		}
	}
}

.mega-toggle-label {
	display: flex;
	justify-content: center;
	align-items: center;

	&-open,
	&-closed {
		width: 25px;
		height: 2px;
		background: #fff;
		display: block;
		position: relative;
		margin: 0 15px;
		border-radius: 2px;

		&:before,
		&:after {
			width: 100%;
			height: 2px;
			display: block;
			position: absolute;
			content: '';
			background: #fff;
			border-radius: 2px;
		}

		&:before {
			top: -7px;
		}

		&:after {
			top: 7px;
		}
	}
}

// MegaMenu medium screens
@include media-breakpoint-only(md) {
	.MenuHorizontal #Logo_top {
		display: none;
	}

	.mega-menu-toggle {
		display: none;
	}

	#mm {
		display: block !important;
	}

	.MenuHorizontal {
		#mm {
			display: flex !important;
			justify-content: flex-start;
			background-color: var(--mega-menu-background-color);

			& > li.mega-menu-item {
				display: inline-block;

				&.picture {
					margin-right: 1.6rem;
				}

				& > a.mega-menu-link {
					padding: 0 9px;
				}
			}

			& > li.mega-menu-megamenu {
				& > ul.mega-sub-menu {
					position: absolute;

					li.mega-menu-columns-3-of-12 {
						width: 25% !important;
						clear: none !important;
					}
				}
			}

			.mega-hide-on-mobile {
				display: block !important;
			}
		}
	}

	.MenuVertical {
		.mega-hide-on-mobile {
			display: block !important;
		}
	}
}

#DesktopViewToggler {
	bottom: 1rem;
	transform: translateX(-50%);
	left: 50%;
	position: fixed;
}

#mm>li.mega-menu-item>a.mega-menu-link { 
	color: var(--mega-menu-text-color);
}