.irs--flat .irs-handle>i:first-child {
    background-color: var(--range-slider-color);
}

.irs--flat .irs-bar, .irs--flat .irs-handle, .irs--flat .irs-handle>i:first-child {
    background-color: var(--range-slider-color);
    border: 1px solid var(--range-slider-color);
}

.irs--flat .irs-handle {
    background-color: #fff;
}