div .ui-accordion .ui-accordion-content {
    padding: var(--spacing);
    overflow: hidden;
	.btn-group {
		margin: 10px;
	}
	font-size: var(--font-size-base);

	.error
	{
		color:red;
	}
}

.SessionInfo {
	margin-bottom: var(--spacing);

	&:last-child {
		margin-bottom: 0;
	}
}

#tableexa {
	margin-bottom: 0;
}

.SessionButtons {
    width: 40px;
}

.SessionButton {
    float: left;
	padding: 12px 5px;
}
.SessionDate {
    width: auto!important;
    text-align: right;
    padding-right: 10px;
}
img.ui-datepicker-trigger
{
	width:16px;
	height:16px;
	margin-top:3px;
}
.SessionDate span
{
	width: auto;
	float:left;
	display:inline-flex;
    padding: 0 5px;
}
.sessiontime
{
	width :200px;
	float:left;
}
.SessionTime
{
	width:400px;
}
.SessionLocation
{
	width: 100%!important;
    margin-bottom: var(--spacing);
}
.SessionTrainers {
    margin-right: -10px;
}
.SessionTrainer.Trainer1 {
    width: 50%;
    float: left;
    padding-right: 10px;
}

.SessionLocation .select2, .SessionTrainers .select2 {
	width: 100% !important;
}

.PlanningTotal.Classical
{
	width: 20%;
}
.PlanningRemaining.Classical
{
	width: 20%;
}
.PlanningRemaining.Classical.OK
{
	background-color: #80FF80;
	padding-left: 6px;
}
.PlanningRemaining.Classical.NOT_OK
{
	background-color: #FF8080;
	padding-left: 6px;
}

.PlanningTotal.Digital
{
	width: 40%;
}

.scripts_admin_training_session_list .icon-bubble-lines4 {
    color: red;
}

.TrainerCheckBox {
	margin-top: var(--spacing);
}