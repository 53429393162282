html,
body {
	background-color: var(--body-bg-color);
	min-height: 100%;
}

body {
	color: var(--font-color-base);
	font-family: var(--font-family-base);
	font-size: $font-size-base;
	font-weight: $font-weight-base;
	line-height: $line-height-base;
}

main {
	position: relative;
}

input[type='button'i],
input[type='submit'i],
input[type='reset'i],
input[type='file'i]::-webkit-file-upload-button,
button {
	cursor: pointer;
}

.matrix_header_left {
	.OnDemandSelect {
		cursor: pointer;
	}
}
