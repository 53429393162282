#AcademyComponent {
	/*--- use @include .container-fluid */
	margin-left: 0;
	margin-right: 0;
	padding-left: 15px;
	padding-right: 15px;
}

#external_content #AcademyComponent {
	/*--- don't use @include .container-fluid */
	padding-left: 0;
	padding-right: 0;
}

#AcademyComponent [class$='Container'] {
	// margin-top: 1rem !important;
	// margin-bottom: 1rem !important;
}

#AcademyComponent {
	.rw-widget {
		border-radius: 4px;
	}

	.picky {
		.picky__input {
			height: 100%;
			background: transparent;
			padding: 0;
			border: 0;
		}

		.picky__dropdown {
			//--- momenteel buiten gebruik
			top: 28px;
		}

		&.dropdown-toggle {
			&::after {
				margin-left: -1rem;
			}
		}

		&[aria-expanded='true'] {
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
		}
	}

	.ReportListContainer .active,
	.ReportListContainer .active h4,
	.List .active,
	.List .active h4 {
		pointer-events: none !important;
	}

	.DownloadsListContainer .card-body {
		max-height: 400px;
		overflow-y: auto;
	}

	.AdminContainer .QueryContainer {
		.FiltersInput .table-responsive {
			overflow: visible;
		}

		.FieldsInput {
			max-height: 400px;
			overflow-y: auto;
		}
	}

	.list-group-item.active,
	.ListItem.active {
		// border:0;
	}

	// te verwerken in theme.scss
	.ReportListContainer .active,
	.ReportListContainer .active h4,
	.List .active,
	.List .active .list-group-item-heading,
	.ListItem.active .list-group-item-heading,
	.ListItem.active h4.list-group-item-heading {
		color: white !important;
		background: #a6c939 !important;
		border-color: #a6c939 !important;
		pointer-events: none !important;
	}

	//--- /te verwerken in theme.scss

	.ReportDataContainer {
		.ReactTable {
			border: none;

			.rt-th > div {
				vertical-align: middle;
				justify-content: left;
				display: flex;
				align-items: center;
			}

			.-pagination,
			.rt-thead.-header {
				box-shadow: none;
				padding: 0px;
				border-top: none;
			}

			.pagination-bottom {
				margin-top: 12px;
			}

			.pagination {
				margin-top: 12px;
				border-top: none;
			}

			.-pagination .-btn {
				margin-left: 0;
				height: 34px;
				margin-bottom: 5px;
			}

			.rt-th {
				font-weight: bold;
				border-right: none;
				line-height: 1.4;
				text-align: left;
				white-space: initial;

				&.rt-resizable-header .rt-resizable-header-content {
					border-right: 1px solid #eaeaea;
				}

				&.rt-resizable-header:last-child .rt-resizable-header-content {
					border-right: 0;
				}
			}

			.rt-tbody .rt-td {
				border-right: none;
			}

			.rt-tbody .rt-tr-group .-odd {
				background-color: rgba(0, 0, 0, 0.05);
			}

			.rt-thead .rt-th.-sort-desc,
			.rt-thead .rt-th.-sort-asc {
				box-shadow: none;
			}
		}

		.rt-th.-sort-asc,
		.rt-th.-sort-desc {
			.rt-resizable-header-content:after {
				display: inline-block;
				color: #333;
				font-size: 0.6rem;
				position: relative;
				left: 0.4rem;
			}
		}

		.rt-th.-sort-asc .rt-resizable-header-content:after {
			content: '▲';
		}

		.rt-th.-sort-desc .rt-resizable-header-content:after {
			content: '▼';
		}

		.btn {
			padding-top: 0.5em;
			padding-bottom: 0.5em;
		}
	}

	// Give the tabs something to sit on
	.nav-tabs {
		// border-bottom: 1px solid @nav-tabs-border-color;
		> li {
			float: left;
			// Make the list-items overlay the bottom border
			margin-bottom: -1px;

			// Actual tabs (as links)
			> a {
				margin-right: 2px;
				// line-height: @line-height-base;
				// border: 1px solid transparent;
				padding: 10px 15px;

				// border-radius: @border-radius-base @border-radius-base 0 0;
				&:hover {
					//   border-color: @nav-tabs-link-hover-border-color @nav-tabs-link-hover-border-color @nav-tabs-border-color;
				}
			}

			// Active state, and its :hover to override normal :hover
			&.active > a {
				&,
				&:hover,
				&:focus {
					//   color: @nav-tabs-active-link-hover-color;
					//   background-color: @nav-tabs-active-link-hover-bg;
					// border: 1px solid @nav-tabs-active-link-hover-border-color;

					border-bottom-color: transparent;
					cursor: default;
				}
			}
		}

		// pulling this in mainly for less shorthand
		&.nav-justified {
			//   .nav-justified();
			//   .nav-tabs-justified();
		}
	}

	.table th.sortable {
		&.desc,
		&.asc {
			span.icon:before {
				display: inline-block;
				color: #333;
				font-size: 0.6rem;
				position: relative;
				left: 0.4rem;
				top: auto;
			}
		}

		&.asc span.icon:before {
			content: '▲';
		}

		&.desc span.icon:before {
			content: '▼';
		}
	}

	.AdminToggleBtn,
	.DownloadContainer > .row {
		margin-bottom: 1rem;
	}

	.ControlsContainer .btn-toolbar {
		margin-top: 0;
	}
}

.wrapper {
	flex: 1 1 auto;
	padding: 0;

	&-header {
	}

	&-body {
	}

	&-footer {
	}

	.ControlsContainer {
		.btn-toolbar {
			margin-top: 0;
		}
	}
}

// .table th.sortable.desc span.icon:before

.table th.sortable {
	&.desc,
	&.asc {
		span.icon:before {
			display: inline-block;
			color: #333;
			font-size: 0.6rem;
			position: relative;
			left: 0.4rem;
			top: auto;
		}
	}

	&.asc span.icon:before {
		content: '▲';
	}

	&.desc span.icon:before {
		content: '▼';
	}
}
