h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	font-family: var(--font-family-headings);
}

body {
	font-family: var(--font-family-base);
	font-size: var(--font-size-base);
}

h1,
.h1 {
	margin: 25px 0 15px;

	font-weight: 600;
	font-size: calc(var(--base-size) * 1.875)
}

h2,
.h2 {
	margin: 0 0 15px;

	font-weight: 500;
	font-size: 24px;
}

h3,
.h3 {
	margin-top: 1rem;

	font-size: 16px;
	font-weight: 300;
	font-size: 1.4rem;
}

h4,
.h4 {
	margin: 0 0 5px;

	font-size: 14px;
}

h5,
.h5 {
	margin: 0 0 5px;

	font-size: var(--base-size);
}

h6,
.h6 {
	margin: 0 0 5px;

	font-size: 12px;
}

a {
	cursor: pointer;

	color: var(--link-color);
	text-decoration: underline;

	&:hover {
		text-decoration: none;
	}

	&:focus-visible {
		outline: 1px solid #000 !important;
		outline-offset: -1px;
	}
}

dl {
	margin: 0 0 30px;
}

dt,
dd {
	margin-left: 0;
}

dt {
	font-weight: 700;
}

dd {
	margin-bottom: 1rem;
}