table {
    color: var(--font-color-base);
}


.table-striped tbody tr:nth-of-type(odd) {
    background-color: transparent;
}

.table {
    color: var(--font-color-base);
    
    th, td {
        padding: .5rem .5rem;
    }
}