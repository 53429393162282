ul.nav {
	&-tabs {
		box-shadow: inset 0 -1px 0 0 var(--input-border-color);

		.nav-link {
			text-decoration: none;

			&:focus {
				outline: 0 !important;
				border-color: #000 #000 var(--input-border-color);
			}

			&:hover {
				border-bottom-color: var(--input-border-color);
			}

			&.active {
				border-color: var(--input-border-color) var(--input-border-color) #fff;
			}

			&.disabled {
				color: var(--nav-tab-disabled-color);
			}
		}
	}
}

#skip-to-content-link {
	position: fixed;
	transform: translateY(-100%);
	top: -1px;
	left: 0;
	background: white;
	z-index: 2000;
	border-radius: inherit;
	color: var(--font-color-base);

	&:focus {
		transform: translateY(0%);
		margin: 1rem;
		outline-offset: -2px;
	}
}