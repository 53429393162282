.containsSplitSelect {
    legend.control-label {
        font-weight: bold;
        line-height: 24px;
    }
}

.SplitSelect {
    &__SelectAllButton {
        &:before {
            display: none;
        }
    }
}