.toggle {

    &:not(th, td){
        border-radius: calc(var(--toggle-size) + var(--toggle-padding)) calc(var(--toggle-size) + var(--toggle-padding));
        width: calc(var(--toggle-size) * 2) !important;
        height: var(--toggle-size) !important;
        min-height: 0 !important;
        background-color: var(--toggle-active-color) !important;
    }

    &.off {
        background-color: var(--toggle-inactive-color) !important;
    }

    &:before, &:after {
        display: none;
    }

    &-group {
        width: calc(200% - var(--toggle-size));
        padding: var(--toggle-padding);
        display: flex;

        label {
            font-size: 12px;
        }
    }

    &-handle {
        width: calc(var(--toggle-size) - calc(var(--toggle-padding) * 2));
        height: calc(var(--toggle-size) - calc(var(--toggle-padding) * 2));
        border-radius: 50%;
        min-height: auto;
        padding: .375rem .75rem;

        &:hover {
            background-color: var(--toggle-handle-color);
        }
    }

    .toggle-on,  .toggle-off {
        background-color: transparent !important;
        text-indent: -9999px;
        padding: 0;
        min-height: auto;

        &:before, &:after {
            display: none;
        }
    }

    &.off {
        .toggle-group {
            left: calc(-100% + var(--toggle-size));
        }

        .toggle-on,  .toggle-off {
            padding: 0;
            box-shadow: none;
        }
    } 
}