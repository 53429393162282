.bootstrap-dialog .bootstrap-dialog,
.modal {
	&-header {
		width: 100%;
		border-top-left-radius: 0 !important;
		border-top-right-radius: 0 !important;

		button.close {
			line-height: 1rem;

			&:focus-visible {
				outline: 2px solid #163e59;
				outline-offset: -10px;
			}
		}
	}

	&-title {
		line-height: 1.5rem;
		font-size: 1.25rem;
		color: inherit !important;
		margin-bottom: 0;
	}

	&-message {
		overflow-x: auto;
		overflow-y: auto;
		min-height: auto;
	}

	&-body {
		.QBPlayer {
			height: 760px !important;
		}

		.btn-toolbar {
			margin-left: -1rem;
			border-top: 1px solid #dee2e6;
			margin-right: -1rem;
			padding: 1rem 1rem 0 1rem;
		}
	}

	&-dialog .modal-body {
		min-height: 0px;
	}	

	&-ie11 {
		@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500&display=swap');

		font-family: 'Open Sans', sans-serif;
		font-size: 1.2rem;

		.modal-header {
			align-items: center;
			font-size: 1.4rem;
			font-weight: bold;

			.icon-warning2 {
				color: #FF9B4E;
				font-size: 2rem;
				margin-right: 1rem;
			}
		}

		.modal-content {
			border-radius: 5px;
			border: none;
		}

		.modal-footer {
			background-color: #f2f2f2;
		}

		button.close {
			background: #ccc;
			border-radius: 50%;
			color: #000;
			opacity: 1;
			font-size: 2rem;
			margin-right: -0.3rem;
			padding: 0.4rem;
			line-height: .6;
		}
	}

	.card {
		box-shadow: none;
	}
}

.scripts_crm_template_list,
.scripts_admin_actions_merge_merge_users,
.scripts_admin_user_edit.que {
	.modal-dialog {
		width: 80rem;
	}
}

.bootstrap-dialog-header {
	display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.bootstrap-dialog-body {
	display: flex;
	width: 100%;
}

.bootstrap-dialog-body .bootstrap-dialog-message {
	width: inherit;
}

.bootstrap-dialog-message {
	#dlgSettings,
	#DialogLanguage,
	& > div {
		@include make-container;

		& > .row > .col-12 {
			& > .card {
				border: 0;
				padding: 0;

				& > .card-header,
				.card-body {
					padding-left: 0;
					padding-right: 0;
				}

				.card-body {
					margin-left: 0;
					margin-right: 0;
					form {
						padding-left: 0;
						padding-right: 0;
					}
				}
			}
		}
	}
}

.modal {
	left: 50%;
	transform: translateX(-50%);
	&-content {
		border-radius: 15px;
		max-width: 95vw;
		margin-left: auto;
		margin-right: auto;
		box-shadow: var(--shadow);
		
	}

	&-body {
		padding: 1rem !important;
	}

	&-title {
		line-height: 1.5rem;
		font-size: 1.25rem;

		svg {
			margin-right: 4px;
		}
	}
}

#popup {
	background: #fff;

	body {
		background: inherit;

		nav {
			.pagination {
				margin: 2rem 0 1rem 0;
			}
		}

		main {
			margin-right: auto;
			margin-left: auto;
			overflow-x: hidden;

			.card {
				border: 0;
				box-shadow: none;
			}

			.label-info {
				background: #444;
				color: white;
				padding: 0.4rem 0.6rem;
				font-size: 1rem;
				border-radius: 3px;
				float: right;
			}
		}
	}

	#skip-to-content-link {
		display: none;
	}
}
// temp fix voor overbodige WB css 19 okt 2021
.bootstrap-dialog.type-primary .modal-header {
	background: transparent;
}

.ScormModalContent,
.ScormModalContent body {
	background-color: transparent;
}

.ScormModalContent {
	display: flex;
	justify-content: center;
	align-items: center;
	
	frameset {
		width: 100%;
	}

	#blocked,
	#success {
		text-align: center;
		justify-content: center;
	}
	#blocked .btn-toolbar {
		justify-content: center;
	}
}
