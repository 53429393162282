/*** Gallery ***/
#gallery {
	.top {
		background-color: transparent !important;
		border-bottom: 1px solid var(--gallery-header-border-color) !important;
		border-left: none !important;
		padding-bottom: var(--spacing);

		div.left {
			padding-left: 0 !important;
		}

		div.right {
			float: none;
			margin-right: -1rem;
			padding-right: 0 !important;

			.btn-toolbar {
				float: right;
			}

		}

		span.icon.left {
			display: inline-block;
			padding-left: 0;
			width: auto;
			font-size: var(--icon-font-size);
		}
	}

	.content {
		margin-top: var(--spacing);

		.left {
			padding-left: 0 !important;
			padding-top: 0 !important;
		}
	}

	li {
		&.active a {
			color: var(--font-color-base) !important;
			font-weight: bold;
		}

		a {
			line-height: 2;
		}
	}

	#gallery_list {
		display: grid;
		grid-template-columns: var(--content-item-grid);
		gap: 30px;

		.image_wrapper {
			background-color: var(--gallery-item-bg-color);
			box-shadow: var(--shadow);
			width: auto;
			height: auto;
			padding: var(--spacing);
			margin: 0;
			border: 0;
			transition: box-shadow .15s ease-in-out;

			&:hover {
				box-shadow: var(--shadow-hover);
			}

			&.active {
				background-color: var(--gallery-item-active-bg-color);
			}

			.name {
				font-weight: bold;
				text-align: left;
				font-size: 16px;
				font-family: var(--font-family-headings);
				margin-bottom: 0;
			}

			.image {
				margin-top: var(--spacing);
				width: auto !important;
				background-size: contain !important;
				border-radius: 0 !important;

			}

			.info {
				width: 100%;

				ul {
					white-space: normal;

					li {
						display: inline;
						font-size: 12px;

						&:after {
							display: inline;
							content:'•';
							padding: 0 0 0 4px;
						}

						&:last-child:after {
							content:'';
						}
					}
				}
			}
		}

		.info {
			margin-top: 1rem !important;
		}
	}
}

#gallery
{
	border-radius: 10px
}

#gallery .top
{
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	background-color: #A2C438;
	border-bottom: 1px solid #96B533;
	border-left: 1px solid #96B533;
	display: table;
	width: 100%
}

#gallery .top .left
{
	padding: 2px;
	font-size: 14px;
	color: #FFFFFF;
	display: table-cell;
	padding-left: 16px;
	vertical-align: middle;
	width: 160px;
}

#gallery .top .right
{
	display: table-cell;
	text-align: right;
	padding-top: 4px;
	padding-right: 12px;
	padding-bottom: 2px;
	float: right
}

#gallery .top .right .ButtonStrip_table,#gallery .top .right .ButtonStrip_td_center
{
	margin: 0px;
	padding: 0px;
	border-bottom: none;
}


#gallery .content
{
	width: 100%;
	display: table;
}

#gallery .content .left
{
	width: 160px;
	vertical-align: top;
	background-color: #FFFFFF;
	border-bottom-left-radius: 10px;
	padding-left: 16px;
	padding-top: 10px
}

#gallery .content .right
{
	border-bottom-right-radius: 10px;
	border-right: 1px solid #FFFFFF;
	border-bottom: 1px solid #FFFFFF;
}

#gallery .pane
{
	display: table-cell 
}

#gallery #mainFrame
{
	width: 100%;
	height: 700px;
	background-color: transparent 
}

#scripts_admin_gallery_browse #gallery #mainFrame
{
	height: 540px	
}

#gallery ul,#gallery li,#gallery_list ul,#gallery_list li
{
	list-style-type: none;
	margin: 0px;
	padding: 0px
}

#gallery li.active a
{
	color: #96B533
}

#scripts_admin_gallery_images,#scripts_admin_gallery_browse_images
{
	background-image: none;
	background-color: #F0F0F0
}

#gallery_list
{
	width: 100%
}

#gallery_list .image_wrapper
{
	width: 200px;
	height: 240px;
	border: 1px solid #DDDDDD;
	float: left;
	overflow: hidden;
	padding: 10px;
	margin: 5px;
	background-color: #FFFFFF;
	cursor: pointer;
	border-radius: 10px;
}

#scripts_admin_gallery_browse_images #gallery_list .image_wrapper
{
	width: 130px;
	height: 130px;
	border: 1px solid #DDDDDD;
	padding: 5px;
	margin: 2px;
	border-radius: 5px;
}

#gallery_list .image_wrapper.active
{
	background-color: #A2C438;
}


#gallery_list .image_wrapper .image
{
	width: 200px;
	height: 180px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	border-radius: 10px;
}

#scripts_admin_gallery_browse_images #gallery_list .image_wrapper .image
{
	width: 130px;
	height: 130px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	border-radius: 5px;
}

#gallery_list .image_wrapper .name,#gallery_list .image_wrapper .info
{
	width: 200px;
	overflow: hidden;
	white-space: nowrap;
	font-size: 0.8em
}

#scripts_admin_gallery_browse_images #gallery_list .image_wrapper .name,#scripts_admin_gallery_browse_images #gallery_list .image_wrapper .info
{
	display: none
}

#gallery_list .image_wrapper .name
{
	text-align: center;
	margin-bottom: 4px
}

#gallery_list .image_wrapper .info
{
	margin-top: 6px
}