@use 'sass:math';

@import 'forms/Radio-Checkbox';
@import 'forms/Input';
@import 'forms/File-input';
@import 'forms/Textarea';
@import 'forms/Select';
@import 'forms/Label';
@import 'forms/Duallistbox';
@import 'forms/Fancytree';
@import 'forms/WYSIWYG';
@import 'forms/Validation';
@import 'forms/RW-widget';
@import 'forms/PasswordChecker';
@import 'forms/Datepicker';
@import 'forms/SplitSelect';
@import 'forms/RangeSlider';
@import 'forms/Toggle';
@import 'forms/InputGroup';

.form-control {
	min-height: 31px;
	height: auto;
	border: none;

	&-feedback {
		top: 37px !important;
		padding: 0.3em;
		color: inherit;
	}

	&.matched {
		background-color: var(--green-normal);
	}
}

// Input containers

.form-group {

	.input-group .checkbox+.checkbox {
		margin-left: 1rem;
	}

	&.containsButton {
		label {
			margin-right: calc(var(--spacing) / 2);
		}

		.btn.form-control {
			display: inline-flex;
		}
	}

	&.containsText {
		// overflow-wrap: break-word;
		// word-wrap: break-word;
		// word-break: break-word;
		// hyphens: auto;
	}

	&.containsText,
	&.containsCheckbox {
		min-height: 0px;

		.form-control-static {
			min-height: 0px;
		}
	}

	&.containsRadioGroup {
		position: relative;
		border: 1px solid #ccc;
		padding: 1rem;
		border-radius: 0.5rem;

		&>label {
			border-bottom: 1px solid #ccc !important;
			margin-bottom: 10px;
			padding-bottom: 10px;
			width: 100%;
		}

		legend.control-label {
			/* autoprefixer: ignore next */
			display: contents;
			font-weight: 700;
			margin-top: 0;
			font-size: var(--font-size-base);

			&::after {
				display: block;
				content: '';
				width: 100%;
				border-bottom: 1px solid #ccc;
				height: 10px;
				margin-bottom: 10px;
			}
		}

		.radio {
			margin-bottom: 7px;

			&:last-child {
				margin-bottom: 0;
			}
		}

		.inline {
			.radio {
				margin-bottom: 0;
			}
		}

		i.form-control-feedback {
			right: 2rem;
			top: 4.5rem;
		}
	}

	&.containsCheckboxGroup {
		legend {
			display: contents !important;
			position: absolute;
			top: 0;
			font-weight: 700;
			margin-top: 0;
			font-size: 1em !important;
		}

		&.has-danger {
			border: 1px solid var(--red-normal);
			border-radius: 0.5rem;
			padding: 15px;
		}
	}

	&.containsSummernote.has-danger {
		padding: 0.5rem;
		border: 1px solid var(--red-normal);
		border-radius: 0.5rem;
		border-color: var(--red-normal) !important;
	}

	&.containsTreeList {
		&.has-danger {
			.TreeList {
				border: 1px solid var(--red-normal);
				border-radius: 0.5rem;
				border-color: var(--red-normal) !important;
			}
		}
	}

	&.containsSplitSelect {
		&.has-danger {
			.SplitSelect {
				padding: 0.5rem;
				border: 1px solid var(--red-normal);
				border-radius: 0.5rem;
				border-color: var(--red-normal) !important;
			}
		}
	}

	&.containsDateTimePicker {
		&.has-danger {
			.rw-widget-picker {
				border: 1px solid var(--red-normal);
				border-radius: 0.5rem;
				border-color: var(--red-normal) !important;
			}
		}
	}
}

.form-control-static {
	padding-top: 0px;
	min-height: 42px;
	word-break: break-word;

	img {
		max-width: 100%;
		height: auto;
	}
}

// Formulieren module
.form_competence {
	margin-bottom: 10px;

	label {
		margin-top: 10px;
	}

	.form-control-static {
		width: 100%;
	}

	&_level_description {
		margin-top: math.div(1rem, 2);
	}
}

.bootstrap-touchspin .input-group-btn-vertical>.btn {
	padding: 9.5px 11px !important;
	background-color: var(--button-primary-bg-color);
	min-height: 0;
	color: var(--button-primary-text-color);

	&:hover {
		background-color: var(--button-primary-bg-color);
	}
}

.bootstrap-touchspin .input-group-btn-vertical i {
	top: 5px;
	left: 7px;
}

.searchbox input {
	border-right: none;

	&:focus {
		border-right: none;
	}
}

.containsRadioGroup.has-danger,
.containsSelectboxSelect2.has-danger .selection span.select2-selection,
.containsInputGroup.has-danger .selection span {
	border-color: var(--red-normal) !important;
}

.containsSelectboxSelect2.has-danger .select2-container--focus .selection span.select2-selection {
	@include input-invalid-focus-effect(true);
}

/*-------rapportage ------*/
span.ValueSELECTed {
	background-color: var(--green-normal);
}

/*--- minicolors */
.minicolors input[type='hidden']+.minicolors-swatch {
	width: calc(100vh * 1);
}

.minicolors-theme-bootstrap .minicolors-swatch {
	border-radius: 0;
	top: 0;
}

.minicolors-theme-bootstrap.minicolors-position-right .minicolors-swatch {
	right: 0;
}

select.form-control {
	+.fv-plugins-icon {
		right: 30px;
	}
}

.scripts_admin_user_edit input#usr_email_personal {
	max-width: calc(100% - 20px);
	float: right;
}

// select { // bij de oplossing door input smaller te maken, (cross ernaast, niet er in) zorgt 'pointer-events: none' voor het niet werken van de hover
// 	+ i.fv-plugins-icon {
// 		pointer-events: none;
// 	}
// }

.fv-plugins-bootstrap:not(.form-inline) {
	label~.fv-plugins-icon {
		top: 30px; //--- overrule van statische top in _default.min
	}

	.containsTextbox label~.fv-plugins-icon {
		top: auto;
		bottom: -3px;
	}

	.hasValidator {
		&.containsTinyMCE label~.fv-plugins-icon {
			display: block;
			top: 25px;

			&.icon-checkmark3 {
				display: none;
			}
		}

		&.containsRadioGroup {
			label~.fv-plugins-icon {
				top: 5px;
			}
		}
	}
}

.fv-plugins-tooltip-icon {
	cursor: default;
}

.fv-plugins-tooltip--top-right {
	margin-left: 13px;
}

input[type='file']+.fv-plugins-icon {
	display: none;
}

.bootstrap-select .dropdown-toggle:focus {
	outline: none !important;
}

form .row.header {
	.col-lg-12 {
		>.btn-group {
			float: right;
			margin-left: 1rem !important;

			.btn {
				background: #fff;
				color: #000;
				border: 1px solid #ccc;
				font-size: 1.1rem;
				padding-left: 9px;
				padding-top: 6px;
				padding-bottom: 6px;
			}

			&:first-child {
				.dropdown-menu {
					left: auto;
					right: 0; //bs: .dropdown-menu-right;
					margin-top: -3px;
				}
			}
		}
	}
}