.scripts_user_trainingregistration_catalogueV2 #loader,
.scripts_admin_friends_catalogue #loader,
#root #loader {
	position: fixed;
	top: 50%;
	transform: translate(-50%, -80px);
	left: 50%;
	width: auto;
	text-align: center;
	z-index: 9999;
	content: ' ';
	margin: auto;
	padding: 1rem;
	background: rgba(255, 255, 255, 0.9);
	background-size: auto;
	box-shadow: 0 0 15px var(--dark-gray);
	border-radius: 0.4rem;
	font-size: 14px;

	&:after {
		margin: auto;
		padding: 1rem 3rem 48px 3rem;
		content: ' ';
		display: block;
		font-size: 2rem;
		background-image: url(../img/spinner_doughnut_grey_light.svg);
		background-repeat: no-repeat;
		background-position: 50% 100%;
	}

	&:before {
		display: block;
		position: relative;
		content: attr(data-content);
		margin-left: auto;
		margin-right: auto;
	}
}
