// .form-group.containsPicklist .ShowMaximum.highlighted .selectedItems, .form-group.containsPicklistAdvanced .ShowMaximum.highlighted .selectedItems ,
// .form-group.containsPicklist .ShowMaximum.highlighted .maximumItems, .form-group.containsPicklistAdvanced .ShowMaximum.highlighted .maximumItems{
// 	//--- overrule van /WBUIResources/css/components/_extra.min.css?v=272057
// 	color:inherit;
// }

.bootstrap-duallistbox-container {
	margin-bottom: 1rem;

	select.form-control {
		background-image: none !important;
		padding: 0 !important;
		border-top-left-radius: 0;
    	border-top-right-radius: 0;
		height: 250px !important;

		&:hover {
			background: none !important;
		}

		option {
			padding: 6px 12px;
			font-size: var(--font-size-base);
		}
	}

	.info-container {
		display: flex;
		flex-direction: row;
		margin-bottom: 0.4rem;

		.label {
			margin-right: 1rem;
			display: flex;
			align-items: center;
			font-size: var(--font-size-base);
			margin-right: 0.3rem;
		}

		.info {
			margin-bottom: 0;
			margin-right: 1rem;
			display: flex;
			align-items: center;
			font-size: var(--font-size-base);
			flex: 1 0 auto;
		}

		button {
			font-size: var(--font-size-base);
			padding: 10px 15px;
			height: var(--input-height);
		}
	}

	.btn-group {
		.btn {
			margin-right: 0;
			margin-bottom: 0;
			
			&:before, &:after {
				border-bottom-left-radius: 0;
				border-bottom-right-radius: 0;
				border-top-left-radius: 0;
				border-top-right-radius: 0;
			}

			&:first-child {
				border-top-left-radius: var(--input-border-radius);

				&:before, &:after {
					border-top-left-radius: var(--input-border-radius);
				}
			}
			
			&:last-child {
				border-top-right-radius: var(--input-border-radius);

				&:before, &:after {
					border-top-right-radius: var(--input-border-radius);
				}
			}
		}
	}

	.ShowMaximum {
		&:before {
			@include make-row();
		}
		padding: 5px 10px;
		margin-left: 15px;
		font-size: 1.22em;
		max-width: calc(100% - 30px);
		@include make-col-ready();
		text-align: right;
		background-color: #fafafa;
		display: block;

		&.highlighted {
			background-color: rgba(255, 0, 0, 0.3);
			color: #000;
		}
	}
}

.SplitSelect {
	&__SelectList {
		&:focus {
			outline: none;
		}
	}

	&__SelectListContainer {
		&:focus-within {
			border-color: #000;
			box-shadow: inset 0 0 0 1px #000,
				shadow(20) rgba(0,0,0, 0.15);
		}
	}

	&__SelectListOption {
		&:hover {
			background-color: var(--select-hover-background-color);
		}

		&:active {
			box-shadow: inset 0 0 0 1px #000,
				shadow(20) rgba(0,0,0, 0.15);
		}

		&.focused {
			background-color: var(--select-hover-background-color);
		}
	}
}

.PickList {
	.btn {
		.icon {
			margin-right: 0;
		}
	}
}
