.scripts_user_trainingregistration_catalogue,
.scripts_admin_user_search {
	.popover {
		min-width: 320px;
	}
}

.popover {
	z-index: 10010 !important;
	position: fixed;
	color: #000;
	font-size: 12px !important;
	max-width: 320px;
	background-color: var(--tooltip-bg-color);

	&-content,
	&-body {
		overflow: hidden;
	}

	&-body {
		border-bottom: 1px solid transparent;
	}
}

.bs-popover {
	&-right {
		.arrow::after {
			border-right-color: var(--tooltip-bg-color);
		}
	}

	&-top {
		.arrow::after {
			border-top-color: var(--tooltip-bg-color);
		}
	}

	&-left {
		.arrow::after {
			border-left-color: var(--tooltip-bg-color);
		}
	}

	&-right {
		.arrow::after {
			border-right-color: var(--tooltip-bg-color);
		}
	}
}

#popover_clm_right {
	max-width: 160px;
	white-space: normal !important;
}

.fv-plugins-tooltip {
	.fv-plugins-tooltip__content {
		background-color: var(--tooltip-bg-color);
		color: var(--font-color-base);
		border: 1px solid rgba(0, 0, 0, 0.2);
		border-radius: 0.3rem;
		padding: 8px;

		&:before {
			width: 1rem;
			height: 1rem;
			background-color: var(--tooltip-bg-color);
			padding: 0;
			border: 0;
			transform: rotate(45deg);
		}
	}

	&--top,
	&--top-right {
		.fv-plugins-tooltip__content:before {
			margin-top: calc(-0.5rem - -1px);
			border-right: 1px solid rgba(0, 0, 0, 0.2);
			border-bottom: 1px solid rgba(0, 0, 0, 0.2);
		}
	}

	&--top-right {
		.fv-plugins-tooltip__content:before {
			margin-right: 4px;
		}
	}
}
