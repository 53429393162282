textarea {
	&,
	&.form-control {
		height: initial;
		padding: 4px 12px;

		color: var(--font-color-base);
		line-height: 24px;

		border-radius: var(--input-border-radius);
		border: 1px solid var(--input-border-color);
		background-color: var(--white);
		box-sizing: border-box;

		-webkit-appearance: none;
		-moz-appearance: textfield;

		//### base textarea, hover state
		&.hover,
		&:hover {
			@include input-hover-effect(false, true);
		}

		//### base textarea, disabled state
		.disabled &,
		:disabled &,
		&.disabled,
		&:disabled {
			cursor: default;

			color: var(--input-disabled-text-color);

			border-color: var(--input-disabled-border-color);
			box-shadow: none;
		}

		//### validation state
		.has-error &,
		&.has-error {
			&,
			&.hover,
			&:hover,
			&.focus,
			&:focus {
				color: var(--input-has-error-text-color);
				box-shadow: none;

				@include input-invalid-effect;
			}

			&.focus,
			&:focus {
				@include input-invalid-focus-effect;
			}
		}
	}
}
