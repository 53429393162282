.note-toolbar-wrapper,
.note-toolbar {
	z-index: auto;
	position: relative !important;
}

.note-btn,
.btn-group .note-btn-group {
	margin-right: 0 !important;
}

.note-editor .dropdown-menu {
	max-height: 500px;
	height: auto;
	overflow-y: auto;
	overflow-x: hidden;
	// top: 26px !important;
}

.tox {
	border: 1px solid #eee !important;
	
	// &.focus {
	// 	border-width: 2px !important;
	// }

	&-tinymce {
		border-radius: $border-radius !important;
		border-color: var(--input-border-color) !important;
	}

	&:not([dir=rtl]) .tox-toolbar__group:not(:last-of-type) {
		border-right: 1px solid #ccc !important;
	}

	&:not(.tox-tinymce-inline) .tox-editor-header {
		padding: 0 !important;
		border-bottom: 1px solid #ccc !important;
		box-shadow: none !important;
	}
}
