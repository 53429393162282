//--- put default classes that you want to extend later on
//--- at this moment sowieso neccessary for https://staging.plusport.com/scripts/user/training.aspx
.row {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-right: -15px;
	margin-left: -15px;
}

// Card deck
$card-deck-margin: 1rem;
.card-deck {
	display: flex;
	flex-direction: column;

	.card {
		margin-bottom: $card-deck-margin;
	}

	@include media-breakpoint-up(sm) {
		flex-flow: row wrap;
		margin-right: -1rem;
		margin-left: -1rem;

		.card {
			display: flex;
			// Flexbugs #4: https://github.com/philipwalton/flexbugs#flexbug-4
			flex: 1 0 0%;
			flex-direction: column;
			margin-right: $card-deck-margin;
			margin-bottom: 0; // Override the default
			margin-left: $card-deck-margin;
		}
	}
}

@include media-breakpoint-up(xl) {
	.col-xl-3 {
		-ms-flex: 0 0 25%;
		flex: 0 0 25%;
		max-width: 25%;
	}
}

@include media-breakpoint-up(xxl) {
	.col-xxl-3 {
		-ms-flex: 0 0 25%;
		flex: 0 0 25%;
		max-width: 25%;
	}
	.col-xxl-6 {
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%;
	}
}
