.PanelAsButton {
    cursor: pointer;
    box-shadow: var(--shadow) !important;

    &:hover {
        box-shadow: var(--shadow-hover) !important;
        transition: .2s ease-in-out;
    }

    h2 {
        margin-bottom: 0;
    }

    &.Current,  &.current {
        color: #fff;
        background-color: var(--primary);
    }

    .icon {
        font-size: 3rem;
    }
    
    .card-body {
        padding: var(--spacing);
    }
}