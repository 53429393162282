.dynatree-container span.dynatree-checkbox {
	background-image: none;
}

ul.fancytree-container,
ul.dynatree-container {
	overflow: visible !important;
}

.fancytree,
.dynatree {
	&-container {
		font-family: inherit !important;

		li {
			margin: 10px 6px 6px 0 !important;
			display: block;
			white-space: normal;
		}
	}

	&-node {
		cursor: pointer;
	}

	&-title {
		font-family: inherit;
		font-size: 14px;
		color: var(--font-color-base) !important;
		background: none !important;
		border: none !important;
		display: inline !important;
		margin: 0 0 0 5px !important;
	}

	&-checkbox {
		background-image: none !important;
		position: relative;
		box-shadow: 0 0 0 2px var(--radio-checkbox-border-color);
		border-radius: var(--radio-checkbox-border-radius);
		width: var(--radio-checkbox-size) !important;
		height: var(--radio-checkbox-size) !important;

		&:before {
			line-height: $line-height-base;
		}

		+ a {
			font-style: normal !important;
		}
	}

	&-treefocus {
		.fancytree-active {
			outline: 1px solid #163e59;
			outline-offset: 3px;
		}
	}

	&-selected &-checkbox {
		background-color: var(--radio-checkbox-checked-bg-color);
		box-shadow: 0 0 0 2px var(--radio-checkbox-checked-bg-color);

		&:before {
			position: absolute;
			content: '';
			width: 5px;
			height: 10px;
			top: 2px;
			left: 6px;
			border: solid #fff;
			border-width: 0 2px 2px 0;
			-webkit-transform: rotate(45deg);
			-ms-transform: rotate(45deg);
			transform: rotate(45deg);
		}
	}

	&-selected &-title {
		font-weight: bold;
	}
}

.ui-fancytree li {
	&:focus {
		@include focus-outline;
	}
}