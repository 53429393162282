@media print {
	#map_wrapper {
		max-width: 100%;
	}

	#map_wrapper #mapDiv {
		display: block !important;
		width: 100% !important;
		float: none !important;
		clear: both !important;
	}

	#map_wrapper #directionsDiv {
		display: block !important;
		width: 100% !important;
		float: none !important;
		clear: both !important;
	}

	#directionsDiv .adp-directions {
		width: 100% !important;
	}
}
