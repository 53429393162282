//--- added 28nov
@media screen and (max-width:768px){
	.scripts_manager_group_list #main_wrapper a,
	.scripts_hrm_group_list #main_wrapper a	{
		width:100%;
		padding: 10px;
		.Tile{
			&::after {
				display: block;
				content: "";
				clear: both;
			}
			float:none;
			padding:10px 0;
			.ProfilePhoto{
				float:none;
				margin:10px auto;
			}
			.WrapperRight{
				&::after {
					display: block;
					content: "";
					clear: both;
				}
				width:auto;
				float:none;
				.Name{
					font-weight:bold;
					border-bottom:0;
				}
			}
		}
	}
	
}
//--- /added 28nov