.has-success input,
.has-success .help-block,
.has-success .control-label,
.has-success .radio,
.has-success .checkbox,
.has-success .radio-inline,
.has-success.radio label,
.has-success.radio-inline label,
.has-success.checkbox label,
.has-success .checkbox-inline,
.has-success.checkbox-inline label,
.form-group.hasValidator.has-success .input-group .input-group-text,
.form-control.is-valid {
	color: var(--font-color-base);
	border-color: var(--input-border-color) !important;
}

.has-feedback label~.form-control-feedback {
	right: 1rem;
	top: 2.6rem;
	color: white;
}

.has-feedback .required:before {
	content: '*';
}

.has-error .input-group-addon {
	color: white;
	background-color: var(--red-normal);
	border-color: var(--red-normal);
}

.hasValidator {
	.fv-plugins-icon {
		display: none;
	}

	&.has-danger {
		.fv-plugins-icon {
			display: block;
			height: 38px;
			line-height: 38px;
			top: 21px;

			.icon {
				color: red;
			}
		}
	}
}

.hasValidator.has-danger {

	/* 	&.containsDateTimePicker,
	&.containsTextbox,
	&.containsRadioGroup,
	&.containsTinyMCE,
	&.containsSelectboxSelect2, */
	&.fv-plugins-icon-container {
		padding-right: 20px;

		.fv-plugins-icon {
			right: 0; // TODO: tooltip heeft nog de angle in het midden, moet aan de rechterkant, hier staat nog een ticket richting WB voor open.
			width: auto; // TODO: positioning via script gaat hiermee niet goed, maar script aanpassen lijkt beter, indien mogelijk
		}
	}

	.hasAllowClearButton+i.fv-plugins-icon {
		right: 0 !important;
	}

	&.containsRadioGroup,
	&.containsCheckboxGroup {
		max-width: calc(100% - 20px);

		.fv-plugins-icon {
			margin-right: -20px;
			top: 0;
		}
	}

	&.containsTextbox {
		.fv-plugins-icon {
			top: -4px !important;
		}

		label+input+.fv-plugins-icon {
			top: 34px !important;
		}
	}

	&.containsSplitSelect {
		.fv-plugins-icon {
			top: 2px;
		}
	}

	&.containsTinyMCE {
		.fv-plugins-icon {
			top: 26px;
		}
	}

	&.containsTreeList {
		.fv-plugins-icon {
			top: 26px;
		}
	}

	&.form-group.containsSelectbox i.fv-plugins-icon,
	&.containsSelectbox i.fv-plugins-icon {
		right: 0 !important;
	}

	&.containsDateTimePicker {
		&.fv-plugins-icon-container {
			padding-right: 25px;
		}

		i.fv-plugins-icon {
			right: -4px !important;
		}

		.input-group-text {
			padding-right: .75rem;
		}
	}
}

select.form-control.is-invalid,
select.form-control.is-valid,
.fv-plugins-bootstrap select.form-control.is-valid,
.fv-plugins-bootstrap select.form-control.is-invalid {
	background-image: url('data:image/svg+xml;utf8,<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-down" class="svg-inline--fa fa-angle-down fa-w-10 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z"></path></svg>') !important;
}

.has-danger {
	.icon-cross2 {
		&:before {
			content: ''; // TODO: de html aanpassen van icon-cross2 naar icon-warning2 bij de validation-error
			color: var(--red-normal);
		}
	}

	.tox-tinymce {
		border-color: var(--red-normal) !important;
	}

	.kv-fileinput-caption {
		border-color: var(--red-normal);

		&:focus-within {
			@include input-invalid-focus-effect(true);
		}
	}

	.file-caption-main {
		&::after {
			display: inline-block;
			width: 22px;
			height: 22px;
			font-family: Material_Outlined !important;
			line-height: 38px;
			margin-left: 4px;
			content: "\e14c";
			font-size: 1.4rem;
			color: var(--red-normal);
		}
	}

	.input-group-prepend,
	.input-group-append {
		z-index: 1;
	}

	.input-group-prepend {
		.input-group-text {
			border-right-color: var(--input-border-color) !important;
		}
	}

	.input-group-append {
		.input-group-text {
			border-left-color: var(--input-border-color) !important;
			padding-right: 0;
		}
	}
}

.has-success {
	.fv-plugins-icon {
		display: none;
	}

	.input-group-addon {
		color: white;
		background-color: var(--green-normal);
		border-color: var(--green-normal);
	}

	&.containsDateTimePicker {
		&.fv-plugins-icon-container {
			padding-right: 25px;
		}

		i.fv-plugins-icon {
			display: block !important;
			right: -4px !important;
			width: auto;
			height: 38px;
			line-height: 38px;
		}

		.input-group-text {
			padding-right: .75rem;
		}
	}
}

.invalid-feedback {
	color: var(--input-has-error-text-color);
}