.accordion {
	.form-group.containsRadioGroup {
		border-radius: 0.5rem;
	}

	.accordion-item {
		margin-bottom: var(--spacing);

		&.active {
			.accordion-header {
				background: transparent;
				.icon {
					&::before {
						transform: scaleY(-1) !important;
						transition: transform 0.2s linear;
					}
				}
			}
		}

		.accordion-header {
			font-size: 1em;
			padding: 1rem;
			border-bottom: none;
			cursor: pointer;
			overflow: hidden;
			position: relative;
			background-color: var(--accordion-header-bg-color) !important;
			transition: background 0.2s linear;
			// width: calc(100% + var(--spacing) * 2);
			max-width: none;
			// margin-left: calc(-1 * var(--spacing));
			color: var(--font-color-base);
			padding: 0.7rem var(--spacing);
			// background-color: transparent!important;
			font-weight: bold;

			&:focus {
				@include focus-outline(0);
			}

			.icon.icon-warning_amber {
				float: left;
				color: var(--orange-normal);
				font-size: 16px;
				margin-right: 10px;
				position: relative;
				right: auto;
				margin-top: auto;
				padding-top: 3px;

				&:before {
					transform: none !important;
				}
			}

			.icon-arrow-down {
				&:before {
					@include arrow-down;
					content: '';
					width: 16px;
					height: 12px;
					margin-top: 5px;
				}
			}

			.badge.float-right {
				margin-right: 24px !important;
			}
		}


		.accordion-content {
			padding-top: 1rem;
			padding-bottom: 1rem;

			.form-group.containsRadioGroup {
				border: 1px solid #ccc;
				padding: 1rem;
				overflow: hidden;

				> label {
					min-height: 3rem;
					width: 100%;
					border-bottom: 1px solid #ccc !important;
					margin-bottom: 1rem;
				}

				.input-group {
					margin-top: 1rem;
				}

				i.form-control-feedback {
					right: 2rem;
					top: 4.5rem;
				}

				.note-editor.note-frame {
					margin-bottom: 0px;
				}
			}

			.form-group.containsSelectbox {
				#cou_cty_id + i {
					display: none;
				}
			}
		}
	}
}

#accordion {
	.SessionInfo {
		table {
			td.SessionButtons {
				.SessionButton {
					margin-right: 0px;
					cursor: pointer;
				}
			}
		}

		.SessionDates {
			display: flex;
			align-items: center;
			margin-bottom: var(--spacing);

			& > span,
			& > input {
				display: inline-block;
				width: 200px;
			}

			.SessionTime {
				&.Start {
					.sessiontime {
						input {
							float: left;
							width: 50%;
							z-index: 0;
						}
					}
				}
			}
		}

		.SessionTrainers > span {
			display: block;
		}
	}
}

.containsBootstrapForm {
	.form-horizontal {
		.control-label {
			text-align: left;
			padding-top: 0px;
		}

		.form-control-static {
			min-height: 25px;
			padding-bottom: 0px;
		}
	}
}
