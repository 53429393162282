.Designations,
.scripts_manager_user_details.dgn,
.scripts_admin_user_edit.dgn {

	th.cert_group {
		min-width: 120px;
	}

	th.cert_obl,
	th.cert_add1,
	th.cert_add2 {
		width: 32%;
	}

	td.passed {
		background-color: $success;
		color: white;
	}

	td.notpassed {
		background-color: $danger;
		color: white;
	}

	td.passedByOther {
		background-color: rgba($success, .3);
	}

	td.partlyPassed {
		background-color: $warning;
	}

	span.passed {
		background-color: $success !important;
		color: white;
	}

	span.notpassed {
		color: $danger !important;
	}

	span.passedByOther {
		color: rgba($success, .75) !important;
	}

	span.partlyPassed {
		color: $warning !important;
	}

	span.notpassed,
	span.passedByOther,
	span.passed,
	span.partlyPassed {
		display: inline-block;
		padding: .2rem .3rem;
		background-color: transparent;
		margin: .1rem;
		border: 1px solid;
		border-radius: 4px;
	}

	span.passed {
		border-color: $success;
	}
}