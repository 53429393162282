.pagination {
	display: block;
	margin-top: 1rem;

	.page-item {
		display: inline-block;

		&.active {
			background-color: var(--pagination-active-bg-color);
			border-radius: 4px;
		}
	}

	.page-link {
		color: var(--font-color-base);
		text-decoration: none;
		font-weight: 500;
		border: 0;
		border-radius: 4px;
		margin-left: 0;

		&:hover {
			// color: $pagination-hover-color;
			background: var(--pagination-hover-bg-color);
			color: white;
		}
	}

	&-sm {
		@include pagination-size($pagination-padding-y-sm,
		$pagination-padding-x-sm,
		$font-size-base,
		$line-height-sm,
		$border-radius-sm);

		.page-link {
			padding: 0.25rem 0.8rem;
			font-size: 1rem;
			line-height: 1.5;
		}
	}

	&-lg {
		@include pagination-size($pagination-padding-y-lg,
		$pagination-padding-x-lg,
		$font-size-lg,
		$line-height-lg,
		$border-radius-lg);
	}
}