.CarouselDots {
	.btn {
		display: inline-block;
		padding: 0 !important;
		min-height: auto;
	}
}

.react-grid-item {
	.card {
		.Overlay {
			border-radius: var(--card-border-radius);
			&:focus-visible {
				box-shadow: inset 0 0 0 2px #000,
					inset 0 0 0 3px #fff;
					outline: 0;
			}
		}
	}
}

