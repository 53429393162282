div.rw-widget-container {
	border-color: var(--input-border-color);
}
.rw-widget-input.rw-widget-picker.rw-widget-container{
	border-radius: var(--input-border-radius);
	min-height: var(--input-height);
}

/* Select widget */
.rw-dropdown-list.rw-widget.rw-open > .rw-widget-container {
	border-bottom-left-radius: 0px;
	border-bottom-right-radius: 0px;
	border-bottom: 0;
	border-color: black;
}
// .rw-dropdown-list.rw-open,
// .rw-open .rw-widget-input.rw-widget-picker.rw-widget-container{
// 	border-bottom-left-radius: 0!important;
// 	border-bottom-right-radius: 0!important;
// }

.rw-dropdown-list.rw-widget.rw-open .rw-popup {
	border-top-left-radius: 0px;
	border-top-right-radius: 0px;
	border-top: 0;
	border-color: black;
	display: table;
	width: 100%;
}
.rw-dropdown-list.rw-widget.rw-open .rw-popup {
	border-top-left-radius: 0px;
	border-top-right-radius: 0px;
	border-top: 0;
	border-color: black;
	display: table;
	width: 100%;
}

.rw-dropdown-list .rw-filter-input.rw-input.rw-widget-picker.rw-widget-container {
	margin-right: 4px;
}

.rw-input.rw-dropdown-list-input .select-close-icon {
	top: 6px;
	right: 0;
	position: absolute;
	cursor: pointer;
	line-height: inherit;
}

.recyclerListViewContainer > div > div > div {
	width: 100% !important;
}

.recyclerListViewContainer {
	max-height: 250px;
	height: 100%;
	width: 100%;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	.recyclerListViewContainer {
		height: 250px;
	}

	input.focus, input.form-control.focus, input.form-control:focus, input:focus {
		box-shadow: none !important;
		-ms-box-shadow: unset !important;
	}
}

.recyclerListViewContainer > div > div > div > div {
	width: 100% !important;
}

.tree-list-selector > div > div > div > div {
	width: 100% !important;
}

.tree-list-selector > div > div > div {
	overflow: auto !important;
}

.tree-list-selector > div > div > div > div > div {
	width: 100% !important;
}
.tree-list-selector > div > div > div > div > div > div {
	width: 100% !important;
}

.select-list.tree-list-selector .TreeItem > div {
	cursor: pointer;
}