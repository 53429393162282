.btn-toolbar>.btn,
.btn-toolbar>.input-group {
	margin-bottom: 5px;
}

.btn {
	font-size: var(--button-fonts-size);
	display: inline-flex;
	align-items: center;
	white-space: nowrap;
	justify-content: center;
	border: 0;
	color: var(--button-primary-text-color);
	text-decoration: none;
	box-shadow: var(--shadow);
	padding: 8px 15px;
	border-radius: var(--button-border-radius);
	min-height: 40px;

	&:hover,
	&:focus,
	&.focus,
	&:active,
	&:active:focus,
	&.active:focus,
	.show>.btn-outline-primary.dropdown-toggle:focus {
		&:enabled {
			border: 0;
		}
	}

	&:focus {
		outline: none;
	}

	&.disabled,
	&:disabled {
		cursor: not-allowed;
	}

	.icon {
		font-size: var(--icon-font-size);
	}

	.icon.left,
	svg {
		margin-right: 8px !important;

		&.icon-floppy-disk,
		&.icon-plus2 {
			font-size: 1rem;
		}
	}

	.icon+.icon {
		margin-left: -6px;
	}

	.toggle-off {
		background-color: var(--red-normal) !important;
		color: #fff !important;
	}

	&-link {
		text-decoration: underline;
		color: var(--primary);
		box-shadow: none;

		&:hover {
			text-decoration: underline !important;
		}
	}

	&-outline-primary,
	&-outline-secondary {
		border: 1px solid var(--button-primary-bg-color);
		color: var(--button-primary-bg-color);

		&:hover {
			border: 1px solid var(--button-primary-bg-color) !important;
			background: var(--button-primary-bg-color);
			color: white;
		}
	}

	&-outline-success {
		border: 1px solid var(--success);
		color: var(--success);

		&:hover {
			border: 1px solid var(--success) !important;
			background: var(--success);
			color: white;
		}
	}

	&-outline-danger {
		border: 1px solid var(--danger);
		color: var(--danger);

		&:hover {
			border: 1px solid var(--danger) !important;
			background: var(--danger);
			color: white;
		}
	}

	&-outline-warning {
		border: 1px solid var(--warning);
		color: var(--warning);

		&:hover {
			border: 1px solid var(--warning) !important;
			background: var(--warning);
			color: white;
		}
	}

	&-outline-info {
		border: 1px solid var(--button-primary-bg-color);
		color: var(--button-primary-bg-color);

		&:hover {
			border: 1px solid var(--button-primary-bg-color) !important;
			background: var(--button-primary-bg-color);
			color: white;
		}
	}

	&-outline-light {
		border: 1px solid var(--light);
		color: var(--light);

		&:hover {
			border: 1px solid var(--light) !important;
			background: var(--light);
		}
	}

	&-outline-dark {
		border: 1px solid var(--dark);
		color: var(--dark);

		&:hover {
			border: 1px solid var(--dark) !important;
			background: var(--dark);
			color: white;
		}
	}

	&-primary,
	&-secondary,
	&-file,
	&-info,
	&-success,
	&-danger,
	&-warning,
	&-dark {
		background-color: transparent;
		position: relative;
		z-index: 1;
		min-height: 40px;
		box-shadow: none;
		background-color: var(--button-primary-bg-color);
		border-radius: var(--button-border-radius);

		&::before {
			background-color: inherit;
			border-radius: inherit;
			transition: all .2s ease-in-out;
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			z-index: -1;
			box-shadow: var(--shadow);
		}

		&:hover:not(.disabled, :disabled) {
			background-color: var(--button-primary-bg-color);
			color: var(--button-primary-text-color);

			&::before {
				transform: scale(1.05, 1.1);
			}
		}

		&:focus {
			box-shadow: none !important;

			&::before {
				background-color: var(--button-focus-bg-color) !important;
				outline: 0 !important;
				box-shadow: var(--shadow) !important;
				transform: scale(1) !important;
			}
		}

		&:disabled,
		&.disabled {
			background-color: var(--button-primary-bg-color);
		}

		&:active {
			box-shadow: none !important;
		}
	}

	&-success {
		&::before {
			background-color: var(--success);
		}
	}

	&-danger {
		&::before {
			background-color: var(--danger);
		}
	}

	&-warning {
		&::before {
			background-color: var(--warning);
		}
	}

	&-dark {
		&::before {
			background-color: var(--dark);
		}
	}

	&-light {
		color: var(--font-color-base);

		&.disabled,
		&:disabled {
			opacity: 0.7;
		}
	}

	&-file {
		color: var(--button-primary-text-color) !important;

		.hidden-xs {
			top: 0 !important;
			margin-left: calc(var(--spacing) / 2);
		}
	}

	&-sm {
		padding: 0.425rem 0.5rem;
		font-size: 0.925rem;
		line-height: 1.5;
	}

	&-icononly,
	&-icononly.disabled,
	&-icononly:disabled {
		background: var(--button-icon-only-bg-color);
		border: none;
		color: var(--button-icon-only-text-color);
		min-height: auto;
		transition: background-color .2s ease-in-out;
		border-radius: var(--button-border-radius);
		position: relative;
		z-index: 1;
		box-shadow: none;
		padding: 5px;

		.icon,
		svg {
			margin-right: 0;
		}
	}

	&-icononly {

		&:hover,
		&:focus:active {
			background: var(--button-icon-only-hover-bg-color);
			color: var(--button-icon-only-hover-text-color);
			border: none;
		}
	}

	&-group {
		min-height: 26px;
		height: auto;

		.btn+.btn {
			border-left: 1px solid rgba(0, 0, 0, .15) !important;
		}
	}

	&-toolbar {
		margin-top: 1rem;
		margin-left: 0;

		.btn-group {
			margin-left: 0;
			margin-bottom: 5px;

			.btn {
				margin-left: 0;
			}

			+.btn-group {
				margin-left: 0;
			}

			.btn:not(:last-child):not(.dropdown-toggle) {
				margin-right: 0;
			}
		}

		&>.btn,
		&>.btn-group {
			margin-right: 1rem !important;

			&:last-child {
				margin-right: 0 !important;
			}
		}
	}

	&-file {
		cursor: pointer;

		input[type='button'] {
			cursor: pointer !important;
		}
	}

	&.kv-hidden {
		display: none;
	}

	&.btn-noclick.btn-icononly {
		color: var(--font-color-base);
		cursor: not-allowed !important;
		opacity: 0.65 !important;

		&:focus,
		.focus,
		:hover {
			color: inherit;
		}
	}

	&.dropdown-toggle {
		// @include button-size(
		// 	$btn-padding-y,
		// 	$btn-padding-x,
		// 	$font-size-base,
		// 	$btn-line-height,
		// 	$btn-border-radius
		// );

		justify-content: space-between;
		align-items: center;

		&::after {
			float: none;
			margin-top: 0;
			border: none;
		}

		&.btn-primary {
			padding: 0 14px 0 5px;

			&::before {
				// @include arrow-down-white;
				// background-position: center right;
				// background-size: 14px 12px;
				// background-position: calc(100% - 10px) center;
			}

			&::after {
				@include arrow-down-white;
				background-position: center right !important;
				background-size: 14px 12px;
				background-position: calc(100% - 10px) center;
				background-color: transparent !important;
				z-index: 10;
				width: 20px;
				height: 40px;
			}

			.buttonlabel {
				color: var(--button-primary-text-color);
			}
		}



		.filter-option {
			position: relative;
			padding-top: 0;
			padding-bottom: 0;
			padding-left: 0;

			.filter-option-inner-inner {
				min-height: 1.5em;
			}
		}
	}

	&.clear1,
	&.clear2 {
		background-color: #666;
		color: white;
	}
}

td.icononly .btn,
td.icononly .btn.btn-primary,
.btn.btn-primary:not(.btn-icononly).btn-noclick {
	background-color: var(--button-icon-only-bg-color);
	border: none;
	color: var(--button-icon-only-text-color);
	min-height: auto;
	transition: background-color .2s ease-in-out;
	border-radius: var(--button-border-radius);
	position: relative;
	z-index: 1;
	box-shadow: none;
	padding: 5px;

	&:before,
	&:after {
		display: none;
	}

	&.dropdown-toggle {
		&:after {
			display: block;
			@include arrow-down;
			background-color: transparent;
		}
	}

	&:hover {
		background: var(--button-icon-only-hover-bg-color);
		color: var(--button-icon-only-hover-text-color);
		border: none;
	}

	&:focus,
	&:active {
		background-color: var(--button-primary-bg-color);
		color: var(--button-primary-text-color);
	}

	.icon,
	svg {
		margin-right: 0;
	}

	&.dependancy {
		background-color: var(--button-icon-only-text-color);
		color: white;
	}
}

.btn.btn-primary:not(.btn-icononly).btn-noclick {

	&:hover,
	&:focus {
		background: transparent;
		box-shadow: none;
		color: var(--button-icon-only-text-color);
		cursor: default;

		&:active {
			background: transparent;
		}
	}
}

.btn-group>.btn:not(:last-child):not(.dropdown-toggle),
.btn-group>.btn-group:not(:last-child)>.btn {

	&:before,
	&:after {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}
}

.btn-group>.btn:not(:first-child),
.btn-group>.btn-group:not(:first-child)>.btn {

	&:before,
	&:after {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}
}

.bootstrap-duallistbox-container .btn {
	background-color: #fff;
	color: var(--font-color-base);
	border: 1px solid var(--input-border-color);
	border-bottom: 0;
	transition: all .2s ease-in-out;

	.icon {
		color: var(--white);
	}

	&:before {
		display: none;
	}

	&:hover,
	&:focus:enabled {
		background-color: var(--button-primary-bg-color);
		color: var(--button-primary-text-color);

		.icon {
			color: var(--button-primary-text-color);
		}
	}
}