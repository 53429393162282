#Content {
	margin-left: auto;
	margin-right: auto;
	padding-bottom: 15px;
	scroll-margin-top: 120px;

	@include media-breakpoint-between(md, xl) {
		padding-bottom: 45px; //--- buffer van ruimte voor kleinere schermen die niet compleet kunnen doorscrollen op sommige pagina's, bijvoorbeeld als  https://proeftuin.plusport.com/scripts/admin/mastertable/forms/form.aspx?tab=fca&subtab=fca_edit&fca_id=4639&frm_id=877
	}

	@include media-breakpoint-up(lg) {
		padding-left: 15px;
		padding-right: 15px;
	}
}

html.scripts_user_general_text,
html.scripts_External_dashboard_v2 {
	#Content {
		padding-top: 24px;
	}
}

.MenuVertical #Content {
	@include media-breakpoint-down(md) {
		padding-top: 60px;
	}

	#Dashboard {
		margin-top: 35px;
	}
}
